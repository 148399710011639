/* eslint-disable no-console */
import React, {FC, useState, useRef, useEffect} from "react";
import {Droppable} from "react-beautiful-dnd";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {mapToIQuizBuilderQuestion, moveQuestion} from "./questionListUtils/questionListUtils";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IQuestionEntitySlim, IQuizBuilderQuestion, IQuizEntity} from "@plumeuk/shapeshift-types";
import QuizBuilderControls from "./quizBuilderControls/quizBuilderControls";
import QuizBuilderQuestion from "./quizBuilderQuestion";
import NewQuestionModal from "./newQuestionModal";
import {makeStyles} from "tss-react/mui";
import {Box, LinearProgress, Typography} from "@mui/material";
import {QuestionMap} from "../../../../../types/admin";

//indent size per level in px
export const indentStepSize = 30;

export type IProps = {
	onChange: (e: IQuizBuilderQuestion[]) => void
} & {initialData?: IQuizEntity};

const useStyles = makeStyles()((theme) => ({
	container: {
		height: "1000px"
	},
	label: {
		fontWeight: 600,
		fontSize: "0.75rem"
	},
	questionContainer: {
		padding: "15px 25px 25px 25px"
	},
	actionControl: {},
	compositionMisMatchBanner: {
		width: "100%",
		background: theme.palette.error.light,
		border: "1px solid " + theme.palette.error.main,
		color: theme.palette.error.main,
		fontWeight: 600,
		textAlign: "center",
		padding: "20px"
	}
})
);


const AdminQuizBuilder: FC<IProps> = ({onChange, initialData: quizData}) => {
	const {classes} = useStyles();
	const [newQuestionModalOpen, setNewQuestionModalOpen] = useState(false)
	const [questionList, setQuestionList] = useState<IQuizBuilderQuestion[]>([]);
	const [undoRedoI, setUndoRedoI] = useState<number>();
	const [questionHistoryStack, setQuestionHistoryStack] = useState<IQuizBuilderQuestion[][]>([]);
	//Initial Value = state of value in db, props.value instead updates with live changes
	const refList = useRef<Record<string, HTMLDivElement | null>>({});
	const [quiz, getQuiz] = useApi<IQuizEntity>(quizData?.slug ? `/api/quiz/slug/${quizData.slug}` : null);
	const [questionMap, setQuestionMap] = useState<QuestionMap | undefined>(quizData?.slug ? undefined : {});


	const initList = (): void => {
		const composition = quizData?.composition ?? [];
		setQuestionList(composition.map(e => mapToIQuizBuilderQuestion(e)))
		setQuestionHistoryStack([]);
		setUndoRedoI(undefined);
	}

	useEffect(() => {
		if(quizData?.slug)
			getQuiz(`/api/quiz/slug/${quizData.slug}`)
		initList()
	}, [quizData])

	//this is required for migration
	//a state where curriculum is wiped but questions exist on the poly array
	useEffect(() => {
		if(!quiz.data)
			return;

		setQuestionList(quiz.data.questions?.map(e => mapToIQuizBuilderQuestion(e)) ?? [])

	}, [quiz])


	useEffect(() => {
		const isUpdate = (): boolean => {
			if (questionHistoryStack.length > 0) {
				return true;
			}
			return false;
		}

		if (!isUpdate()) return;


		onChange(questionList);
	}, [questionList])

	// useEffect(() => {
	// 	if (attribute.type !== "json")
	// 		console.warn("Please use string list with json attribute type");
	// }, [attribute.type])

	useEffect(() => {
		if (quiz.data && quiz.data.questions) {
			const map: QuestionMap = {};
			quiz.data.questions.forEach(e => {
				if(!e.__type)
					return;

				if(!map[e.__type])
					map[e.__type] = {}

				map[e.__type][e.id] = e
			});
			setQuestionMap(map);
		}
	}, [quiz]);

	const getDraggingXPos = (draggableId: string): number | null => {
		const draggingElement = refList.current[draggableId];
		if (!draggingElement) return null;
		const startX = draggingElement.getBoundingClientRect().x;
		return startX;
	}

	const draggingStartX = useRef<number | null>(null);
	const handleDragStart = (e: any): void => {
		if (draggingStartX.current !== null) {
			draggingStartX.current = getDraggingXPos(e.draggableId);
		}
	};
	const draggingEndX = useRef<number | null>(null);
	const handleDragUpdate = (e: any): void => {
		draggingEndX.current = getDraggingXPos(e.draggableId);
	}

	const handleDragEnd = (result: DropResult): void => {
		const {source, destination} = result;
		const deepClonedFlatList = JSON.parse(JSON.stringify(questionList));

		if (source && source.droppableId !== "quizBuilder")
			console.warn("Source droppable not configured:", source.droppableId);

		if (destination && destination.droppableId !== "quizBuilder")
			console.warn("Destination droppable not configured:", destination.droppableId);


		else if (!destination) {
			console.error("Destination not found");
			return;
		}

		//Moving - set to sibling of above element
		else {
			setQuestionList(prev =>
				[...moveQuestion(prev, source.index, destination.index)]
			);
		}

		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);
		draggingEndX.current = null;
		draggingStartX.current = null;
	}

	const handleUndo = (): void => {
		if (undoRedoI === undefined) {
			addToStack(questionList);
		}
		setUndoRedoI(prev => prev ? (prev - 1) : (questionHistoryStack.length - 1))
	}

	const handleRedo = (): void => {
		setUndoRedoI(prev => prev !== undefined ? ++prev : undefined)
	}

	useEffect(() => {
		if (undoRedoI !== undefined && questionHistoryStack.length > undoRedoI)
			setQuestionList(questionHistoryStack[undoRedoI])
	}, [undoRedoI])

	const addToStack = (state: IQuizBuilderQuestion[]): void => {
		setQuestionHistoryStack(prev => {
			//if undoRedo position, delete above as user change made
			if (undoRedoI !== undefined) {
				prev = prev.slice(0, -(questionHistoryStack.length - undoRedoI));
			}

			return [...prev, JSON.parse(JSON.stringify(state))]
		});
	}

	const handleNewQuestionsModalClose = (newQuestions?: IQuestionEntitySlim[]): void => {
		setNewQuestionModalOpen(false);
		if (!newQuestions) return;
		const deepClonedList = JSON.parse(JSON.stringify(questionList));

		const newItems = newQuestions?.map(e => mapToIQuizBuilderQuestion(e)) ?? [];

		setQuestionList(prev => ([...prev, ...newItems]))
		addToStack(deepClonedList);
		setUndoRedoI(undefined);

		//update map with new additions
		setQuestionMap(prev => {
			if (!prev || newQuestions.length === 0)
				prev = {};

			const newMap = {...prev};
			newQuestions.forEach(e => {
				if(!e.__type)
					return;

				if(!newMap[e.__type])
					newMap[e.__type] = {}

				newMap[e.__type][e.id] = e;
			})
			return newMap;
		})
	}

	const handleDelete = (index: number): void => {
		const deepClonedFlatList = JSON.parse(JSON.stringify(questionList));

		setQuestionList(prev => {
			prev.splice(index, 1);
			return [...prev];
		})

		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);
	}

	return (
		<>
			<QuizBuilderControls
				undoRedoI={undoRedoI}
				questionHistoryStack={questionHistoryStack}
				onRedo={() => handleRedo()}
				onReset={() => initList()}
				onUndo={() => handleUndo()}
				onNewQuestionAction={() => setNewQuestionModalOpen(true)}
			/>
			{questionMap ? <DragDropContext onDragStart={e => handleDragStart(e)} onDragUpdate={e => handleDragUpdate(e)} onDragEnd={(e) => handleDragEnd(e)}>
				<Droppable droppableId={"quizBuilder"} type="base" >
					{(provided, _snapshot) => (
						<div {...provided.droppableProps} ref={provided.innerRef} >
							<Box className={classes.questionContainer}>
								{questionList.map((question, i) => {
									const key = `question-builder-question_${question.id}_${question.__type}`
									return <QuizBuilderQuestion
										data-test-id={key}
										key={key}
										index={i}
										question={question}
										onDelete={() => handleDelete(i)}
										ref={e => refList.current[question.dragId] = e}
										questionMap={questionMap}
									/>
								})}
								{provided.placeholder}
							</Box>
						</div>
					)
					}
				</Droppable>
			</DragDropContext> : <LinearProgress />}
			{newQuestionModalOpen && <NewQuestionModal currentQuestions={questionList} onClose={(questions) => handleNewQuestionsModalClose(questions)} />}
		</>
	)
}

export default AdminQuizBuilder;