import {ValidationDoc} from "@plumeuk/use-validate";
import {ICourseEntityPayload} from "@plumeuk/shapeshift-types";

export const cohortCourseValidationDoc: ValidationDoc<ICourseEntityPayload & {contentType?: string}> = {
	title: [{
		errorMessage: "required",
		validate: e => !!e.title
	}],
	featuredImage: [{
		errorMessage: "required",
		validate: e => !!e.featuredImage
	}],
	contentType: [{
		errorMessage: "required",
		validate: e => !!e.contentType
	}]
}

