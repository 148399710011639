import React, {FC, useState, useEffect, useCallback} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, BoxProps, Button, Grid, TextField, Typography} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {palette} from "../../../constants";

export type IProps = Omit<BoxProps, "onChange"> & {
	allowDuplicates: boolean;
	value: string[],
	defaultMessage: string,
	onChange:(e: string[]) => void
};

const useStyles = makeStyles()((_theme) => ({
	container: {
		padding: "20px"
	},
	listItem: {
		background: palette.grey15,
		borderRadius: "15px",
		display: "flex",
		padding: "10px 25px",
		justifyContent: "space-between"
	},
	itemValue: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},
	label: {
		fontWeight: 600,
		fontSize: "16px"
	}
}));

export function utilLettersOnly(string: string): string{
	string = string.replace(/[^a-z]+/gi, "")
	return string.replace(/ /gi, "-")
}

const AdminStringList: FC<IProps> = ({onChange, value, allowDuplicates, defaultMessage, ...boxProps}) => {
	const {classes} = useStyles();
	const [list, setList] = useState<string[]>(value);

	const [newItem, setNewItem] = useState<string>("");

	const handleAddItem = useCallback(() => {
		setList(prev => {
			if (!prev) return [newItem]
			if (prev.includes(newItem) && allowDuplicates) return prev;

			return [...prev, newItem];
		});
		setNewItem("");
	}, [newItem]);

	const handleDeleteItem = useCallback((index: number) => {

		setList(prev => {

			if(!prev?.length) return [];
			const newArr = [...prev];
			newArr.splice(index, 1);
			if (newArr.length !== prev.length)
				return newArr;

			return prev;
		})
	}, []);

	useEffect(() => {
		onChange(list)
	}, [list])

	return (
		<Box {...boxProps}>
			<Box sx={{display: "flex", gap: "10px"}}>
				<TextField
					label={"enter text here"}
					value={newItem}
					data-test-id={`${utilLettersOnly(defaultMessage)}-input`}
					onChange={e => setNewItem(e.target.value)}
				/>
				<Button onClick={() => handleAddItem()} data-test-id={`${utilLettersOnly(defaultMessage)}-add-button`}><Add /></Button>
			</Box>
			<Box padding={2}/>
			{value?.length > 0 && <>
				<Typography className={classes.label}>Items</Typography>
				<Box style={{marginTop: "4px", display: "flex", flexDirection: "column", gap: "15px"}}>
					{value?.map((e, i) => (
						<Box className={classes.listItem} key={`box-${i}`} >
							<Typography className={classes.itemValue}>{e}</Typography>
							<Button onClick={() => handleDeleteItem(i)}><Delete /></Button>
						</Box>
					))}
				</Box>
			</>}
		</Box>
	)
}

export default AdminStringList;