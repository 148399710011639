import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useMatch, useNavigate, useParams} from "react-router-dom";
import {Box, Button, LinearProgress, Tab, Tabs, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleCompleteButton, ModuleNotAvailable, ModuleTab, ModuleUnCompleteButton} from "@plumeuk/shapeshift-common/module";
import {NotesSection} from "@plumeuk/shapeshift-common/v2";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {Files} from "@plumeuk/shapeshift-common/files";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {Links} from "@plumeuk/shapeshift-common/links";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {ICourseCurriculumModule, ILesson} from "@plumeuk/shapeshift-types";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {getAllFontStyles} from "../../../templates/defaultTheme";
import {TrainingfeedContext} from "../../../contexts/trainingFeedContext";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {IconDocSearch} from "../../../icons/IconDocSearch";
import {IconComments} from "../../../icons/IconComments";
import {palette, toolbarHeight} from "../../../constants";
import {Player, PlayerAPI, PlayerConfig, PlayerType, StreamType} from "bitmovin-player/modules/bitmovinplayer-core";
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin";
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer";
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls";
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash";
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr";
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml";
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts";
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4";
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles";
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608";
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill";
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style";
import "bitmovin-player/bitmovinplayer-ui.css";
import {UIFactory} from "bitmovin-player-ui";
import {SocketContext} from "../../../contexts/socketContext";
import {MiniQuizzer} from "./MiniQuizzer";
import {LocaleContext} from "../../../contexts/localeContext";
import {IconNotes} from "../../../icons/IconNotes";

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
	},
	miniQuizzer: {
		marginBottom: "80px",
		minHeight: "550px"
	},
	resumeVideoContainer: {
		position: "fixed",
		top: toolbarHeight,
		margin: "20px 30px",
		right: "0px",
		zIndex: 30
	},
	// resumeVideoButton: {
	// 	background: palette.orange,
	// 	"&:hover": {
	// 		opacity: 0.8
	// 	}
	// },
	title:{
		color: theme.palette.common.white,
		marginTop: "70px",
		marginBottom: "50px",
		"h2": {
			fontSize: "42px"
		},
		"h5": {
			fontSize: "20px",
			lineHeight: "1.5rem"
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	centeredContainer: {
		maxWidth: "900px",
		padding: "0 20px",
		paddingBottom: "100px"
	},
	lessonCompleteBtn: {
		float: "right"
	},
	content: {
		...getAllFontStyles(theme),
		color: theme.palette.text.primary,
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "17px"
		},
		"& a": {
			textDecoration: "underline",
			color: theme.palette.primary.main
		}
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		color: theme.palette.common.white,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px",
		"& > div": {
			background: palette.grey15}
	},
	tabs: {
		width: "100%",
		margin: 0,
		marginBottom: "50px",
		"[class*='indicator']": {
			display: "none"
		},
		[theme.breakpoints.up("sm")]: {
			"[class*='MuiTabs-scroller']": {
				marginLeft: "25px"
			}
		},
		[theme.breakpoints.down("sm")]: {
			"[class*='MuiTabs-scroller']": {
				marginLeft: "15px"
			},
			gap: 0
		}
	},
	activeTab: {
		color: theme.palette.common.white + "!important",
		background: "transparent"
	},
	links: {
		marginBottom: "40px"
	},
	tab: {
		display: "flex",
		fontSize: "14px",
		gap: "5px",
		justifyContent: "space-around",
		color: "#A8A8A8",
		padding: "20px 12px",
		letterSpacing: "0.5px",
		"& svg": {
			marginTop: "4px"
		},
		"& svg path": {
			fill: palette.midGrey
		},
		[theme.breakpoints.down("sm")]: {
			minHeight: "44px",
			fontSize: "13px",
			padding: "0 5px"
		}
	},
	goToNextLesson: {
		marginTop: "25px"
	},
	notAvailableContainer: {
		width: "100%",
		textAlign: "center"
	},
	comments: {
		"& > div": {
			background: palette.drawer
		},
		"[class*='MuiAvatar']": {
			background: palette.grey10
		},
		"& *": {
			color: theme.palette.common.white + "!important"
		}
	},
	notes: {
		"& *": {
			color: theme.palette.common.white + "!important"
		},
		"& [class*='inputContainer'] > [class*='MuiTextField']": {
			background: palette.drawer
		},
		"[class*='filtersContainer'] > div" : {
			background: palette.grey10
		}
	},
	notesSection: {
		"& [class*='timeStampInputAdornment']": {
			alignSelf: "start",
			marginTop: "5px"
		}
	}
}));

const MODULE_TABS: {label: JSX.Element, value: ModuleTab}[] = [
	{
		label: <><IconDocSearch /> OVERVIEW</>,
		value: "overview"
	},
	{
		label: <><IconComments /> COMMENTS</>,
		value: "comments"
	},
	{
		label: <><IconNotes /> NOTES</>,
		value: "notes"
	}
]


export const LessonPage: FC = () => {
	const {cx, classes} = useStyles();
	const pageIsFeed = useMatch("/feed/:courseSlug/*");
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const {trainingfeedDispatch} = useContext(TrainingfeedContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const [firstAvailableModule, setFirstAvailableModule] = useState<ICourseCurriculumModule | false>();
	const videoRef = useRef<VideoPlayerRef | null>(null);
	const [player, setPlayer] = useState<PlayerAPI | null>(null);
	(global as any).playerx = player;
	const [videoFinished, setVideoFinished] = useState(false);
	const [videoStarted, setVideoStarted] = useState(false);
	const socket = useContext(SocketContext)
	const [{locale}] = useContext(LocaleContext);
	const location = useLocation();

	useEffect(() => {
		videoRef.current = null;
	}, [lessonSlug])

	const handleVideoReady = useCallback((lesson: ILesson) => {
		if(!process.env.REACT_APP_BITMOVIN_PLAYER_KEY)
			throw new Error("REACT_APP_BITMOVIN_PLAYER_KEY required")

		const playerConfig: PlayerConfig = {
			key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY,
			analytics: {
				key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY
			},
			playback: {
				preferredTech: [{player: PlayerType.Html5, streaming: StreamType.Hls}]
			}
		};

		if(!videoRef.current?.bitmovin?.container)
			return;

		const playerSource = {
			hls: lesson.videoUrl,
			poster: lesson.videoThumbnailUrl
		};

		Player.addModule(EngineBitmovinModule);
		Player.addModule(MseRendererModule);
		Player.addModule(HlsModule);
		Player.addModule(XmlModule);
		Player.addModule(DashModule);
		Player.addModule(AbrModule);
		Player.addModule(ContainerTSModule);
		Player.addModule(ContainerMp4Module);
		Player.addModule(SubtitlesModule);
		Player.addModule(SubtitlesCEA608Module);
		Player.addModule(PolyfillModule);
		Player.addModule(StyleModule);

		// Delay player setup for Safari
		setTimeout(() => {
			if(!videoRef.current?.bitmovin?.container)
				return;

			const playerInstance = new Player(videoRef.current.bitmovin?.container, playerConfig);
			UIFactory.buildDefaultUI(playerInstance);
			playerInstance.load(playerSource).then(() => {
				setPlayer(playerInstance);
				console.log("Successfully loaded source");
			}, (e) => {
				console.log("Error while loading source", e);
			});
		}, 100); // Delay added
	}, [])

	useEffect(() => {
		if(courseSlug && lessonSlug && curriculum)
			setFirstAvailableModule(getModule(curriculum) ?? false)
	}, [courseSlug, lessonSlug, curriculum])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if(!courseSlug)
			return

		if(e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if(!e.isLoading && !e.isError && e.statusCode === 200){
			debugger;
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status})

			if(pageIsFeed){
				trainingfeedDispatch({
					type: "setModuleComplete", module: {courseSlug, type: "lesson", slug: lessonSlug as string}, status})
			}

			if(e?.data?.complete){
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				if(pageIsFeed)
					navigate("/feed/" + courseSlug)
				else
					navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	const handleCompleteCheck = (): boolean => {
		if(!player)
			return true


		const valid = (player.getDuration() - player.getCurrentTime()) < 5;
		if(!valid)
			notify("Please finish the video before progressing", "Hold up!", INotificationSeverity.warning, 5000)
		return valid;
	}

	const prevProgress = useRef<number>()
	useEffect(() => {
		if(!player){
			setVideoFinished(true)
			return;
		}

		//run in loop to save video progress
		const interval = setInterval(() => {
			const started = (player?.getCurrentTime() ?? 0) > 0;
			const finished = (player.getDuration() - player.getCurrentTime()) < 5;
			setVideoStarted(started);
			setVideoFinished(finished);
			if(started && prevProgress.current !== player.getCurrentTime()){
				prevProgress.current = player.getCurrentTime();
				socket?.emit("lessonVideoProgress", {progress: player.getCurrentTime(), courseSlug, lessonSlug})
			}
		}, 2000);

		return () => clearInterval(interval);
	}, [player]);

	return (
		<Box className={classes.lessonPage}>
			<LessonDataProvider locale={locale} courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum} populate={["files", "quiz", "enrollment.videoTime"]}>
				{({lesson, apiResult}) => <>
					{/* {(lesson?.videoTime && !videoStarted) &&
						<Box onClick={() => {
							player?.seek(lesson.videoTime ?? 0) && player.play();
							setVideoStarted(true)
						}} className={classes.resumeVideoContainer}>
							<Button className={classes.resumeVideoButton}>Resume where you left off?</Button>
						</Box>
					} */}
					{apiResult.statusCode === 200 && <>
						<LessonVideoPlayer onReady={() => lesson ? handleVideoReady(lesson) : null} ref={videoRef} lesson={lesson} currentTime={location?.state?.timestamp ?? lesson?.videoTime} bitmovinPlayer={player} />
						<Box className={classes.tabs}>
							<Tabs
								variant="standard"
								value={tabSelected}
								onChange={(_e, tab) => setTab(tab)}
							>
								{MODULE_TABS.map(({label, value}) => (
									<Tab
										data-test-id={`module-tabs-${value?.toLocaleLowerCase()?.replace(" ","")}`}
										key={value}
										value={value}
										label={label}
										className={cx(classes.tab, tabSelected === value ? classes.activeTab : "")}
									/>
								))}
							</Tabs>
						</Box>
						<CenteredContainer className={classes.centeredContainer}>
							{tabSelected === "overview" && <>
								<PageTitle className={classes.title} title={lesson?.title} subtitle={lesson?.subtitle} />
								<WYSIWYG className={classes.content}>
									{lesson?.content ?? ""}
								</WYSIWYG>

								{(lesson as any)?.quiz &&
									<MiniQuizzer
										autoStart
										className={classes.miniQuizzer}
										onComplete={result => {}}
										quiz={(lesson as any).quiz}
									/>
								}

								{lesson?.files && <>
									<Typography className={classes.fileTitle} variant="h5">Files</Typography>
									<Files className={classes.files} files={lesson.files}/>
								</>}

								{lesson?.links && lesson.links.length > 0 && <>
									<Typography className={classes.fileTitle} variant="h5">Links</Typography>
									<Links className={classes.links} links={lesson.links}/>
								</>}
								{courseSlug && lesson?.slug && !lesson?.complete &&
									<ModuleCompleteButton
										style={{opacity: videoFinished ? 1 : 0.5}}
										validate={handleCompleteCheck}
										courseSlug={courseSlug}
										moduleSlug={lesson.slug}
										type="lesson"
										className={classes.lessonCompleteBtn}
										onApiUpdate={handleCompletion}
									/>
								}
								{courseSlug && lesson?.slug && lesson?.complete && firstAvailableModule &&
									<Button
										onClick={() => navigate(pageIsFeed
											? `/feed/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`
											: `/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`
										)}
										className={classes.lessonCompleteBtn}
									>
										Next {firstAvailableModule ? firstAvailableModule?.type : ""}
									</Button>
								}
							</>}
							{tabSelected === "comments" && <CommentsSection className={classes.comments} courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug}/>}
							{tabSelected === "notes" && <NotesSection className={classes.notesSection} disableTimeStamp={!lesson?.videoUrl} courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} videoRef={videoRef} onGoToModuleAction={(courseId, moduleId, moduleType, timestamp) => {navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}})}} />}
						</CenteredContainer>
					</>}
					{apiResult.statusCode === 403 && <Box className={classes.notAvailableContainer}>
						<ModuleNotAvailable>
							Lessons must be completed in sequence.
						</ModuleNotAvailable>
						{firstAvailableModule && <Button
							className={classes.goToNextLesson}
							onClick={() => navigate(`/course/${courseSlug}/${firstAvailableModule?.type}/${firstAvailableModule?.slug}`)}
						>
							Go to next available lesson
						</Button>}
						{firstAvailableModule === false && <Button
							className={classes.goToNextLesson}
							onClick={() => navigate("/training-feed")}
						>
							Go back to My Learning
						</Button>}
					</Box>}
				</>}
			</LessonDataProvider>
		</Box>
	);
}