/* eslint-disable no-console */
import {FC, useState, useRef, useEffect} from "react";
import {Droppable} from "react-beautiful-dnd";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import CourseCurriculumModule from "./courseCurriculumModule";
import {deleteModule, demoteModule, initFlatList, moveElement, moveModule} from "./flatArrayUtils/flatArrayUtils";
import NewModuleModal from "./newModuleModal";
import {useApi} from "@plumeuk/shapeshift-identity";
import CurriculumControls from "./curriculumControls/curriculumControls";
import {IModuleBase} from "@plumeuk/shapeshift-types/ICourse";
import {ICourseCurriculumItemEntity, ICourseEntity, IFlatList} from "@plumeuk/shapeshift-types";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {ICurriculumItemImport, ModulesMap} from "../../../../../types/curriculum";

//indent size per level in px
export const indentStepSize = 30;

export type IProps = {
	progressionControl?: boolean,
	deepLinks?: boolean,
	onChange: (e: IFlatList[]) => void,
} & {initialData?: ICourseEntity};

const useStyles = makeStyles()((_theme) => ({
	container: {
		height: "1000px"
	},
	label: {
		fontWeight: 600,
		fontSize: "0.75rem"
	},
	moduleContainer: {
		padding: "15px 25px 25px 0px"
	},
	actionControl: {}
})
);

declare global {
	interface Array<T> {
		move(from: number, to: number): Array<T>;
	}
}


const AdminCourseCurriculum: FC<IProps> = ({onChange, initialData: courseData}) => {
	const {classes} = useStyles();
	const [newModuleModalOpen, setNewModuleModalOpen] = useState(false)
	const [flatList, setFlatList] = useState<IFlatList[]>([]);
	const [undoRedoI, setUndoRedoI] = useState<number>();
	const [flatListHistoryStack, setFlatListHistoryStack] = useState<IFlatList[][]>([]);
	//Initial Value = state of value in db, props.value instead updates with live changes
	const refList = useRef<Record<string, HTMLDivElement | null>>({});
	const [courseModules, getCourseModulesApi] = useApi<IModuleBase[]>();
	const [courseCurriculumItems, getCourseCurriculumItemsApi] = useApi<ICourseCurriculumItemEntity[]>();
	const [modulesMap, setModulesMap] = useState<ModulesMap | undefined>(courseData?.id ? undefined : {});

	useEffect(() => {
		if(courseData?.id){
			getCourseModulesApi("/api/course/" + courseData?.id + "/modules");
			getCourseCurriculumItemsApi("/api/course/" + courseData?.id + "/curriculum/items")
		}
		else
			setFlatList([])
	}, [courseData?.id])


	const firstRun = useRef(true);
	useEffect(() => {
		if(!courseCurriculumItems.data || firstRun.current == false)
			return;

		initList()
		firstRun.current = false;
	}, [courseCurriculumItems.data]);


	const initList = (): void => {
		if(!courseCurriculumItems.data)
			return;

		setFlatList(initFlatList(courseCurriculumItems.data))
		setFlatListHistoryStack([]);
		setUndoRedoI(undefined);
	}

	useEffect(() => {
		const isUpdate = (): boolean => {
			if (flatListHistoryStack.length > 0) {
				return true;
			}
			return false;
		}

		if (!isUpdate()) return;

		onChange(flatList);
	}, [flatList, flatListHistoryStack.length])

	useEffect(() => {
		if (courseModules.data) {
			const map: ModulesMap = {};
			courseModules.data.forEach(e => {
				if(!e.__type)
					return;

				if(!map[e.__type])
					map[e.__type] = {}

				map[e.__type][e.id] = e;
			});
			setModulesMap(map);
		}
	}, [courseModules]);

	const getDraggingXPos = (draggableId: string): number | null => {
		const draggingElement = refList.current[draggableId];
		if (!draggingElement) return null;
		const startX = draggingElement.getBoundingClientRect().x;
		return startX;
	}

	const draggingStartX = useRef<number | null>(null);
	const handleDragStart = (e: any): void => {
		if (draggingStartX.current !== null) {
			draggingStartX.current = getDraggingXPos(e.draggableId);
		}
	};
	const draggingEndX = useRef<number | null>(null);
	const handleDragUpdate = (e: any): void => {
		draggingEndX.current = getDraggingXPos(e.draggableId);
	}

	const handleDragEnd = (result: DropResult): void => {
		const {source, destination, combine} = result;
		const deepClonedFlatList = JSON.parse(JSON.stringify(flatList));

		if (source && source.droppableId !== "curriculum")
			console.warn("Source droppable not configured:", source.droppableId);

		if (destination && destination.droppableId !== "curriculum")
			console.warn("Destination droppable not configured:", destination.droppableId);

		if (combine && combine.droppableId !== "curriculum")
			console.warn("Combine droppable not configured:", combine.droppableId);


		//Setting to child of target element
		if (combine) {
			//get index of combine element
			const combineIndex = flatList.map(e => e.dragId).indexOf(combine.draggableId);
			setFlatList(prev => demoteModule(prev, source.index, combineIndex));
		}

		else if (!destination) {
			console.error("Destination not found");
			return;
		}

		//Moving - set to sibling of above element
		else {
			setFlatList(prev =>
				moveModule(prev, source.index, destination.index)
			);
		}

		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);
		draggingEndX.current = null;
		draggingStartX.current = null;
	}

	useEffect(() => {
		Array.prototype.move = function (from: number, to: number) {
			return moveElement(this, from, to);
		};
	}, [])

	const handleAccordianChange = (i: number, open: boolean): void => {
		setFlatList(prev => {
			if (!prev[i] || prev[i].collapsedChildren === !open)
				return prev;

			prev[i].collapsedChildren = !open;
			return [...prev];
		})
	}

	const handleProgressionChange = (i: number, value: boolean): void => {
		const deepClonedFlatList = JSON.parse(JSON.stringify(flatList));
		setFlatList(prev => {
			if (!prev[i] || prev[i].progression === value)
				return prev;

			prev[i].progression = value;
			return [...prev];
		})
		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);
	}

	let parentPath: IFlatList[] = [];
	let prevModule: IFlatList | undefined = undefined;

	const handleUndo = (): void => {
		if (undoRedoI === undefined) {
			addToStack(flatList);
		}
		setUndoRedoI(prev => prev ? (prev - 1) : (flatListHistoryStack.length - 1))
	}

	const handleRedo = (): void => {
		setUndoRedoI(prev => prev !== undefined ? ++prev : undefined)
	}

	useEffect(() => {
		if (undoRedoI !== undefined && flatListHistoryStack.length > undoRedoI){
			setFlatList(flatListHistoryStack[undoRedoI])
		}
	}, [undoRedoI])

	const addToStack = (state: IFlatList[]): void => {
		setFlatListHistoryStack(prev => {
			//if undoRedo position, delete above as user change made
			if (undoRedoI !== undefined) {
				prev = prev.slice(0, -(flatListHistoryStack.length - undoRedoI));
			}

			return [...prev, JSON.parse(JSON.stringify(state))]
		});
	}

	const handleNewModulesModalClose = (newModules?: ICurriculumItemImport[]): void => {
		setNewModuleModalOpen(false);
		if (!newModules || !modulesMap) return;
		const deepClonedFlatList = JSON.parse(JSON.stringify(flatList));

		const newMap = {...modulesMap};
		newModules.forEach(e => {
			if(!e.type)
				return;

			if(!newMap[e.type])
				newMap[e.type] = {}

			newMap[e.type][e.id] = {
				__type: e.type,
				complete: false,
				id: e.id,
				slug: e.slug,
				content: "",
				subtitle: "",
				title: e.title,
				publishedAt: e.publishedAt
			};
		})

		const newItems: IFlatList[] = newModules.map(e => ({
			type: e.type,
			slug: e.slug,
			title: e.title,
			level: 0,
			dragId: e.type + "-" + e.id,
			moduleId: e.id,
			progression: true
		}));

		setFlatList(prev => ([...prev, ...newItems]))
		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);

		//update map with new additions
		setModulesMap(newMap)
	}

	const handleDelete = (index: number): void => {
		const deepClonedFlatList = JSON.parse(JSON.stringify(flatList));

		setFlatList(prev => deleteModule(prev, index))
		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);
	}

	const handleLevelChange = (i: number, newLevel: number): void => {
		const deepClonedFlatList = JSON.parse(JSON.stringify(flatList));
		setFlatList(prev => {
			if (prev[i].level === newLevel) return prev;

			prev[i].level = newLevel;
			return [...prev];
		});
		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);
	}

	const handleFlatListChange = (newFlatList: IFlatList[]): void => {
		const deepClonedFlatList = JSON.parse(JSON.stringify(flatList));
		setFlatList(newFlatList);
		addToStack(deepClonedFlatList);
		setUndoRedoI(undefined);
	}

	return (
		<>
			<CurriculumControls
				undoRedoI={undoRedoI}
				flatListHistoryStack={flatListHistoryStack}
				onRedo={() => handleRedo()}
				onReset={() => initList()}
				onUndo={() => handleUndo()}
				onNewModuleAction={() => setNewModuleModalOpen(true)}
			/>
			<DragDropContext onDragStart={e => handleDragStart(e)} onDragUpdate={e => handleDragUpdate(e)} onDragEnd={(e) => handleDragEnd(e)}>
				<Droppable isCombineEnabled={false} droppableId={"curriculum"} type="base" >
					{(provided, _snapshot) => (
						<div {...provided.droppableProps} ref={provided.innerRef} >
							<Box className={classes.moduleContainer}>
								{flatList.map((module, i) => {
									if (i === 0) {
										parentPath = [];
										prevModule = undefined;
									}

									if (prevModule && prevModule.level < module.level) {
										parentPath.push(prevModule)
									}
									else if (prevModule && prevModule.level > module.level) {
										parentPath.splice(parentPath.length - Math.abs(prevModule.level - module.level));
									}

									prevModule = module;
									const key = `curriculum-module-${module.dragId}`
									return <CourseCurriculumModule
										data-test-id={key}
										courseSlug={courseData?.slug ?? ""}
										indentStepSize={0}
										previous={flatList?.[i - 1]}
										key={key}
										index={i}
										module={module}
										hasChildren={flatList.length <= (i + 1) ? false : (flatList[i + 1].level > module.level)}
										onAccordianChange={(open: boolean) => handleAccordianChange(i, open)}
										isCollapsed={parentPath?.filter(e => e.collapsedChildren).length > 0}
										onDelete={() => handleDelete(i)}
										onProgressionChange={(e) => handleProgressionChange(i, e)}
										ref={e => refList.current[module.dragId] = e}
										isMissing={!!(module.moduleId && !modulesMap?.[module.type]?.[module.moduleId])}
										isDraft={() => !!(module.moduleId && !modulesMap?.[module.type]?.[module.moduleId]?.publishedAt)}
										unsavedChanges={flatListHistoryStack.length > 0}
									/>
								})}
								{provided.placeholder}
							</Box>
						</div>
					)
					}
				</Droppable>
			</DragDropContext>
			{newModuleModalOpen && <NewModuleModal currentModules={flatList} onClose={(newModules) => handleNewModulesModalClose(newModules)} />}
		</>
	)
}

export default AdminCourseCurriculum;