import {FC, useState} from "react";
import {ICurriculumItemEntity, ILessonEntity, corePaths} from "@plumeuk/shapeshift-types";
import {Box, BoxProps, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import ModuleTypeSelect from "../../../../../components/admin/select/ModuleTypeSelect";
import {AdminLessonPage} from "../../Lessons/AdminLessonPage";
import {AdminEntityPageContainer, AdminFieldContainer, AdminInputText} from "@plumeuk/shapeshift-common/admin";
import Editor from "../../../../../components/admin/WYSIWYG/Editor";
import AdminVideoUpload from "../../Lessons/AdminBitmovinUpload/adminVideoUpload";
import {AdminLessonPageContent} from "../../Lessons/AdminLessonPageContent";
import {AdminQuizPageContent} from "../../Quizzes/AdminQuizPageContent";

export interface ICustomProps {
	onSubmit: (e: ICurriculumItemEntity & {id: number, title: string, slug: string}) => void,
	typeFilter?: string
}

export type IProps = ICustomProps & Omit<BoxProps, "onSubmit">

const useStyles = makeStyles()(
	(_theme) => ({
		courseCurriculumNewModuleForm: {
			borderRadius: "10px",
			position: "relative"
		},
		loading: {
			position: "absolute",
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			textAlign: "center"
		},
		createBtn: {
			position: "absolute",
			right: "50px",
			top: "100px"
		}
	})
);

const CourseCurriculumNewModuleForm: FC<IProps> = ({onSubmit, typeFilter, ...boxProps}) => {
	const {classes} = useStyles();
	const [moduleType, setModuleType] = useState<string | undefined>(typeFilter);

	return (
		<Box className={classes.courseCurriculumNewModuleForm} {...boxProps}>
			{!typeFilter && <ModuleTypeSelect
				autoSelectFirst
				value={moduleType}
				onChange={e => setModuleType(e)}
			/>}
			<Box padding={2}></Box>
			{moduleType === corePaths.lesson && <AdminLessonPageContent
				onSaveResponse={e => (!e.isLoading && e.data) && onSubmit({...e.data, type: corePaths["lesson"]})}
			/>}

			{moduleType === corePaths.quiz && <AdminQuizPageContent
				onSaveResponse={e => (!e.isLoading && e.data) && onSubmit({...e.data, type: corePaths["quiz"]})}
			/>}
		</Box>
	)
}

export default CourseCurriculumNewModuleForm;