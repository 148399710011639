import {FC} from "react";
import {Box, BoxProps, Tooltip} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Drafts} from "@mui/icons-material";

interface ICustomProps {
	isDraft?: boolean,
	description?: string
}

export type IProps = ICustomProps & BoxProps;

const useStyles = makeStyles()((theme) => ({
	icon: {
		marginRight: "15px",
		display: "inline-block",
		verticalAlign: "middle",
		fontSize: "20px",
		marginTop: "-4px", // Makes it look more centrally aligned
		"& path": {
			fill: theme.palette.error.main
		}
	},
	draftIconContainer: {
		width: "35px",
		marginLeft: "4px",
		display: "inline-block"
	}
}));

const DraftIndicator: FC<IProps> = ({isDraft, className, description, ...boxProps}) => {
	const {classes, cx} = useStyles();

	return (
		<Box className={cx(classes.draftIconContainer, className)} {...boxProps}>
			<div style={{display: isDraft ? "block" : "none"}}>
				<Tooltip title={description ?? "This module is currently a draft."} className="tooltip">
					<Drafts className={classes.icon} />
				</Tooltip>
			</div>
		</Box>
	)
};

export default DraftIndicator;
