import React, {FC, useEffect} from "react";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IQuestionTypeMap} from "@plumeuk/shapeshift-types";
import {FormControl, InputLabel, MenuItem, Select, SelectProps, Tooltip} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {palette} from "../../../../../constants";

export interface ICustomProps {
	value?: string,
	onChange: (e: string) => void,
	data?: IQuestionTypeMap,
	filter?: (e: string) => boolean,
	autoSelectFirst?: boolean
}

type IProps = ICustomProps & Omit<SelectProps<string>, "value" | "onChange">;

const useStyles = makeStyles()((_theme) => ({
	questionTypeSelect: {
	},
	select: {
		minWidth: "200px",
		fieldset: {
			borderColor: palette.grey15
		},
		"& svg path": {
			fill:  palette.grey15
		}
	}
})
);

export const QuestionTypeSelect: FC<IProps> = ({data, filter, autoSelectFirst, value, onChange, ...props}) => {
	const {classes} = useStyles();
	const [questionTypeMap] = useApi<IQuestionTypeMap>(data ? null : "/api/settings/config/questionTypeMap", data ?? {});

	useEffect(() => {
		if(!autoSelectFirst) {
			return;
		}

		const first = Object.keys(data ?? {})?.[0] ?? Object.keys(questionTypeMap?.data ?? {})?.[0];

		if(first) {
			onChange(first);
		}

	}, [data, questionTypeMap.data])

	return (
		<FormControl>
			<InputLabel id="question-type-select-label">Question Type</InputLabel>
			<Select<string>
				id="question-type-select"
				placeholder="Please select"
				label={"Question Type"}
				className={classes.select}
				value={value ?? ""}
				onChange={(e) => onChange(e.target.value)}
				{...props}
			>
				{Object.keys(questionTypeMap.data ?? {}).filter(e => !filter || filter(e)).map((e, i) => {
					const nameSlim = e.split(".").pop()?.replaceAll("-", " ").replace("question", "");
					return (
						<MenuItem
							data-test-id={"question-type-select-option-" + nameSlim}
							key={`option-${i}`}
							value={e}
						>
							{nameSlim}
						</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
};

export default QuestionTypeSelect;
