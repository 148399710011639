import {Box, BoxProps} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";

type IProps = BoxProps;

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		minHeight: "100%",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: "1120px",
		margin: "auto",
		paddingBottom: "40px"
	}
}));

export const CenteredContainer: FC<IProps> = ({children, className}) => {
	const {classes, cx} = useStyles();

	return (
		<Box className={cx(classes.centeredContainer, className)}>
			{children}
		</Box>
	);
};
