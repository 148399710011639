import React, {useEffect, FC, useState} from "react";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IQuestionEntitySlim, corePaths} from "@plumeuk/shapeshift-types";
import {IQuizGetAllQuestionsResponse} from "@plumeuk/shapeshift-types/IQuiz";
import {Box, BoxProps, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import QuestionTypeSelect from "./QuestionTypeSelect";
import {palette} from "../../../../../constants";

export interface ICustomProps {
	selected: IQuestionEntitySlim[],
	additionalfilter?: (question: IQuestionEntitySlim) => boolean,
	onChange: (newSelected: IQuestionEntitySlim[]) => void
}

export type IProps = ICustomProps & BoxProps

const useStyles = makeStyles()(
	(theme) => ({
		questionSearch: {
			border: "1px solid " + theme.palette.grey,
			padding: "20px",
			borderRadius: "10px"
		},
		header: {
			display: "flex",
			gap: "20px",
			"& > div:first-of-type": {
				flexBasis: "200px",
				flexGrow: 0
			},
			"& > div": {
				flexBasis: 1,
				flexGrow: 2
			}
		},
		searchResults: {
			marginTop: "20px",
			"& tr: hover": {
				cursor: "pointer"
			}
		}
	})
);

const QuestionSearch: FC<IProps> = ({selected: propsSelected, additionalfilter, onChange, className, ...boxProps}) => {

	const {classes, cx} = useStyles();

	const [searchQuestionsApi, searchQuestions] = useApi<IQuizGetAllQuestionsResponse>(null);
	const [questionType, setQuestionType] = useState<string>("");
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selected, setSelected] = useState<IQuestionEntitySlim[]>([]);
	const [questions, setQuestions] = useState<IQuestionEntitySlim[]>([]);

	useEffect(() => {
		if (searchQuestionsApi?.data) {
			//flatten
			let arr: IQuestionEntitySlim[] = [];
			Object.keys(searchQuestionsApi.data).forEach(e => {
				arr = arr.concat(searchQuestionsApi?.data?.[e] ?? []);
			})
			setQuestions(arr.filter(e => additionalfilter?.(e) ?? true));
		}
	}, [searchQuestionsApi?.data, additionalfilter])

	useEffect(() => {
		searchQuestions({
			url: "/api/quiz/questions",
			data: {
				searchTerm,
				questionType
			}
		});
	}, [searchTerm, questionType])

	useEffect(() => {
		onChange(selected);
	}, [selected])

	useEffect(() => {
		if(propsSelected.length !== selected.length && propsSelected.filter(e => !selected.includes(e)).length > 0) {
			setSelected(propsSelected)
		}
	}, [propsSelected]);

	return (
		<Box className={cx(className, classes.questionSearch)} {...boxProps}>
			<Box className={classes.header}>
				<Box>
					<QuestionTypeSelect
						value={questionType}
						filter={e => [corePaths.questionSingleChoice, corePaths.questionMultipleChoice, corePaths.questionBoolean].includes(e as any)}
						onChange={e => setQuestionType(e)}
					/>
				</Box>
				<Box>
					<TextField
						placeholder="Search questions..."
						label="Search"
						key="question-search"
						name="question-search"
						onChange={e => setSearchTerm(e.target.value)}
						value={searchTerm}
					/>
				</Box>
			</Box>
			<Box className={classes.searchResults}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Checkbox checked={questions && selected?.length === questions?.length} onChange={e => setSelected(e.target.checked ? questions : [])} aria-label="Select all entries" />
							</TableCell>
							<TableCell>
								<Typography>Type</Typography>
							</TableCell>
							<TableCell>
								<Typography>Title</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{questions.map((e, i) => {
							const typeSlim = (e.__type ?? "").split(".").pop()?.replaceAll("-", " ").replace("question", "");
							// const isSelected = selected.filter(e => e.__type).map(s => s.id + (s.__type ?? "")).includes(e.id + (e.__type ?? ""));
							const isSelected = !!selected.find(x => x.__type === e.__type && e.id === x.id)

							return (
								<TableRow key={`question-result-${i}`} onClick={() => setSelected(prev => {
									const isSelected = prev.find(x => x.__type === e.__type && e.id === x.id)

									if(isSelected){
										prev = prev.filter(x => e !== x);
										return [...prev];
									}
									return [...prev, e];
								})}>
									<TableCell>
										<Checkbox checked={isSelected} aria-label={`Select ${e.title}`} />
									</TableCell>
									<TableCell>
										{typeSlim}
									</TableCell>
									<TableCell>
										{e.title}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Box>
		</Box>
	)
}

export default QuestionSearch;