import React, {useState, useEffect} from "react";
import QuizBuilderNewQuestionForm from "./quizBuilderNewQuestionForm";
import {IQuestionEntitySlim, IQuizBuilderQuestion} from "@plumeuk/shapeshift-types";
import QuestionSearch from "./questionSearch";
import {Modal} from "@plumeuk/shapeshift-common/modal";
import {makeStyles} from "tss-react/mui";
import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import {palette, toolbarHeight} from "../../../../../constants";

export interface IProps {
	currentQuestions?: IQuizBuilderQuestion[],
	onClose: (modules?: IQuestionEntitySlim[]) => void,
}

const useStyles = makeStyles()((_theme) => ({
	newModuleModal: {
		zIndex: 50
	},
	body: {},
	selectedCountFooter: {
		color: "grey",
		paddingRight: "15px",
		fontSize: "12px"
	},
	header: {
		marginTop: toolbarHeight,
		display: "flex",
		justifyContent: "space-between !important"
	},
	title: {
		flexGrow: 2
	},
	fullScreen: {
		paddingRight: "20px",
		cursor: "pointer",
		"& span":{
			marginLeft: "10px"
		}
	},
	tabs: {
		height: "70px",
		minHeight: "70px",
		borderBottom: palette.grey10 + " 1px solid",
		width: "100%",
		"& button": {
			width: "50%"
		}
	}
})
);

interface IFilterMap {
	[type: string]: {[id: number]: boolean}
}

const NewQuestionModal: React.FunctionComponent<IProps> = (props) => {
	const {classes} = useStyles();
	const [tab, setTab] = useState<"existing" | "new">("existing");
	const [selected, setSelected] = useState<IQuestionEntitySlim[]>([]);
	const [filterMap, setFilterMap] = useState<IFilterMap>();

	const handleNewQuestionSubmission = (module: IQuestionEntitySlim): void => {
		setSelected(prev => ([...prev, module]));
	}

	//Create filter map for rapid lookup
	useEffect(() => {
		if (!props.currentQuestions)
			return;

		const map: IFilterMap = {};
		props.currentQuestions.forEach(e => {
			if(e.__type.includes("survey"))
				return;

			if (!map[e.__type])
				map[e.__type] = {}

			map[e.__type][e.id] = true;
		});

		setFilterMap(map)
	}, [props.currentQuestions])

	return (
		<Modal defaultFullscreen open={true} className={classes.newModuleModal} onClose={() => props.onClose()} footerButtons={[
			<Button key={1} onClick={() => props.onClose()}>
				Cancel
			</Button>,
			<>
				{selected.length > 0 && <Typography className={classes.selectedCountFooter}>Selected {selected.length} questions</Typography>}
				<Button disabled={selected.length === 0} onClick={() => props.onClose(selected?.length > 0 ? selected : undefined)}>Add</Button>
			</>
		]}>
			<>
				<Box className={classes.header}>
					<Typography className={classes.title} variant="h3">
						Add Questions
					</Typography>
				</Box>
				<Box className={classes.body}>
					{/* <AdminInputSwitch label="Source" checked={useExisting} onChange={e => setUseExisting(e.target.checked)} /> */}
					<Tabs className={classes.tabs} value={tab} onChange={(_e, v) => setTab(v)}>
						<Tab label="Create New" value={"new"}/>
						<Tab label="Import" value={"existing"}/>
					</Tabs>

					<Box padding={3}></Box>

					<QuestionSearch
						style={{display: tab === "existing" ? "block" : "none"}}
						selected={selected}
						additionalfilter={(e: any) => !filterMap || filterMap[e.__type ?? ""]?.[e.id] === undefined}
						onChange={(e: any) => setSelected(e)}
					/>

					<QuizBuilderNewQuestionForm
						style={{display: tab === "new" ? "block" : "none"}}
						onSubmit={e => handleNewQuestionSubmission(e)}
					/>
				</Box>
			</>
		</Modal>
	);
};

export default NewQuestionModal;
