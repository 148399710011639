import React, {FC} from "react";
import {Box, BoxProps, Tooltip} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Error} from "@mui/icons-material";

interface ICustomProps {
	isError?: boolean,
	tooltipDescription?: string
}

export type IProps = ICustomProps & BoxProps;

const useStyles = makeStyles()((theme) => ({
	icon: {
		marginRight: "15px",
		display: "inline-block",
		verticalAlign: "middle",
		fontSize: "20px",
		marginTop: "-4px", // Makes it look more centrally aligned
		"& path": {
			fill: theme.palette.error.main
		}
	},
	errorIconContainer: {
		width: "35px",
		marginLeft: "4px",
		display: "inline-block"
	}
}));

const ErrorIndicator: FC<IProps> = ({isError, className, tooltipDescription,...boxProps}) => {
	const {classes, cx} = useStyles();

	return (
		<Box className={cx(classes.errorIconContainer, className)} {...boxProps}>
			{isError && <Tooltip title={tooltipDescription} className="tooltip">
				<Error className={classes.icon} />
			</Tooltip>}
		</Box>
	)
};

export default ErrorIndicator;
