import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import {palette} from "../../../../../constants";
import {BITMOVIN_VIDEO_DATA} from "../../../../../types/admin";

export interface IProps {
	onUploadVideo: () => void,
	value?: BITMOVIN_VIDEO_DATA | null
}

const useStyles = makeStyles()(
	(theme) => ({
		videoUploadControls: {
			display: "flex",
			zIndex: 1,
			top: 0,
			background: palette.grey15,
			gap: "10px",
			padding: "20px 32px",
			position: "sticky",
			justifyContent: "space-between"
		},
		controls: {
			justifyContent: "right",
			display: "flex",
			gap: "10px",
			"& > div": {
				fontWeight: 600,
				cursor: "pointer",
				background: "white",
				border: "1px solid " + theme.palette.grey[400],
				padding: "7px 10px",
				display: "flex",
				gap: "20px",
				borderRadius: "5px",
				fontSize: "0.75rem",
				"& svg": {
					opacity: 0.5
				},
				"&:hover": {
					background: "transparent"
				}
			},
			"& > button": {
				height: "28px",
				"& svg": {
					marginRight: "10px"
				}
			}
		}
	})
);

const VideoUploadControls: FC<IProps> = ({onUploadVideo, value}) => {
	const {classes} = useStyles();

	return (
		<Box className={classes.videoUploadControls}>
			<Box className={classes.controls}>
				<Button data-test-id="video-upload-controls-add-video-btn" onClick={() => onUploadVideo()}><Add />Upload New Video</Button>
			</Box>
		</Box>
	)
}

export default VideoUploadControls;