import React, {useEffect, FC, useState} from "react";
import {useApi} from "@plumeuk/shapeshift-identity";
import {Box, BoxProps, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ICurriculumItemImport} from "../../../../../types/curriculum";
import ModuleTypeSelect from "../../../../../components/admin/select/ModuleTypeSelect";
import {DraftIndicator} from "../../../../../components/admin/indicator";
import {palette} from "../../../../../constants";

export interface ICustomProps {
	typeFilter?: string,
	selected: ICurriculumItemImport[],
	additionalfilter?: (module: ICurriculumItemImport) => boolean,
	onChange: (newSelected: ICurriculumItemImport[]) => void
}

export type IProps = ICustomProps & BoxProps

const useStyles = makeStyles()(
	(theme) => ({
		moduleSearch: {
			border: "1px solid " + palette.grey15,
			padding: "20px",
			borderRadius: "10px"
		},
		typeSelectContainer:{
			flexBasis: "200px",
			flexGrow: 0
		},
		searchContainer:{
			flexBasis: 1,
			flexGrow: 2
		},
		header: {
			display: "flex",
			gap: "20px"
		},
		searchResults: {
			marginTop: "20px",
			"& tr: hover": {
				cursor: "pointer",
				background: palette.grey15
			}
		}
	})
);

const ModuleSearch: FC<IProps> = ({selected: propsSelected, additionalfilter, onChange, className, typeFilter, ...boxProps}) => {

	const {classes, cx} = useStyles();

	const [searchModulesApi, searchModules] = useApi(null, []);
	const [moduleType, setModuleType] = useState<string | undefined>(typeFilter);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selected, setSelected] = useState<ICurriculumItemImport[]>([]);
	const [modules, setModules] = useState<ICurriculumItemImport[]>([]);

	useEffect(() => {
		if (searchModulesApi?.data) {
			setModules(searchModulesApi.data.filter(e => additionalfilter?.(e) ?? true));
		}
	}, [searchModulesApi?.data, additionalfilter])

	useEffect(() => {
		searchModules({url: "/api/modules", data: {searchTerm, moduleType}});
	}, [searchTerm, moduleType])

	useEffect(() => {
		onChange(selected);
	}, [selected])

	useEffect(() => {
		if(propsSelected.length !== selected.length && propsSelected.filter(e => !selected.includes(e)).length > 0) {
			searchModules();
			setSelected(propsSelected)
		}
	}, [propsSelected]);

	return (
		<Box className={cx(className, classes.moduleSearch)} {...boxProps}>
			<Box className={classes.header}>
				{!typeFilter && <Box className={classes.typeSelectContainer}>
					<ModuleTypeSelect
						value={moduleType}
						onChange={e => setModuleType(e)}
					/>
				</Box>}
				<Box className={classes.searchContainer}>
					<TextField
						placeholder="Search modules..."
						label="Search"
						name="module-search-input"
						onChange={e => setSearchTerm(e.target.value)}
						value={searchTerm}

						// labelAction={
						// 	<Tooltip title="Search by module name">
						// 		<button aria-label="Search-tooltip" >
						// 			<Info aria-hidden />
						// 		</button>
						// 	</Tooltip>
						// }
					/>
				</Box>
			</Box>
			<Box className={classes.searchResults}>
				<Table> {/* <Table>   colCount={3} rowCount={modules?.length ?? 0} */}
					<TableHead>
						<TableRow>
							<TableCell>
								<Checkbox checked={modules && selected?.length === modules?.length} onChange={e => setSelected(e.target.checked ? modules : [])} aria-label="Select all entries" />
							</TableCell>
							<TableCell></TableCell>
							<TableCell>
								<Typography>Type</Typography>
							</TableCell>
							<TableCell>
								<Typography>Title</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{modules.map((e, i) => {
							const typeSplit = e.type?.split(".") ?? [];
							const isSelected = selected.map(s => s.id + s.type).includes(e.id + e.type);

							return (
								<TableRow key={`module-result-${i}`} onClick={() => setSelected(prev => {
									if(selected.includes(e)){
										prev = prev.filter(x => e !== x);
										return [...prev];
									}
									return [...prev, e];
								})}>
									<TableCell>
										<Checkbox checked={isSelected} aria-label={`Select ${e.title}`} />
									</TableCell>
									<TableCell>
										<DraftIndicator isDraft={!e.publishedAt}/>
									</TableCell>
									<TableCell>
										{typeSplit[typeSplit.length - 1]}
									</TableCell>
									<TableCell>
										{e.title}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Box>
		</Box>
	)
}

export default ModuleSearch;