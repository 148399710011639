import {FC, useEffect} from "react";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IModulesSetting} from "@plumeuk/shapeshift-types";
import {FormControl, InputLabel, MenuItem, Select, SelectProps} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {palette} from "../../../constants";

export interface ICustomProps {
	value?: string,
	onChange: (e: string) => void,
	data?: IModulesSetting[],
	autoSelectFirst?: boolean
}

type IProps = ICustomProps & Omit<SelectProps<string>, "value" | "onChange">;


const useStyles = makeStyles()((theme) => ({
	select: {
		"& fieldset": {
			borderColor: palette.grey15
		},
		"& svg path": {
			fill: theme.palette.common.white
		}
	}
}));

export const ModuleTypeSelect: FC<IProps> = ({data, autoSelectFirst, value, onChange, ...props}) => {
	const {classes} = useStyles();
	const [moduleTypes] = useApi<IModulesSetting[]>(data ? null : "/api/settings/config/moduleCollections", data ?? []);

	useEffect(() => {
		if (!autoSelectFirst) {
			return;
		}

		const first = data?.[0]?.base ?? moduleTypes?.data?.[0]?.base;

		if (first && !value) {
			onChange(first);
		}

	}, [autoSelectFirst, data, moduleTypes.data, value, onChange]);

	return (
		<FormControl fullWidth>
			<InputLabel id="module-select-label">Module Type</InputLabel>
			<Select
				variant="outlined"
				labelId="module-select-label"
				id="module-select"
				label="Module Type"
				value={value ?? ""}
				className={classes.select}
				onChange={e => onChange(e.target.value)}
				{...props}
			>
				{moduleTypes.data?.map((e, i) => {
					const nameSplit = e.base.split(".");
					const name = nameSplit[nameSplit.length - 1];
					return (
						<MenuItem
							data-test-id={"module-select-option-" + name}
							key={`option-${i}`}
							value={e.base}
						>
							{name}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default ModuleTypeSelect;
