import {Button, Typography} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {IconCourseCertificate} from "../../icons/IconCourseCertificate";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		marginTop: "60px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "60px"

		},
		[theme.breakpoints.up("sm")]: {
			"& button": {
				width: "190px",
				height: "52px"
			}
		},
		textAlign: "center"
	},
	tagLine: {
		color: "rgba(255, 255, 255, 0.7)",
		"& strong": {
			color: theme.palette.common.white
		},
		marginBottom: "50px"
	},
	trainingFeedBtn: {},
	icon: {
		width: "100px",
		height: "100px",
		marginBottom: "20px"
	}
}));

export const CourseCertificatePage: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();

	const goToTrainingFeedBtn = <Button onClick={() => navigate("/training-feed")} className={classes.trainingFeedBtn}>Continue</Button>;
	// const downloadCertBtn = <Button variant="contained">
	// 	<CourseCertificateDataProvider courseSlug={courseSlug}>
	// 		{({certificate}) => (
	// 			certificate
	// 				? <PDFDownloadLink
	// 					fileName={generateTitle(certificate.printedTitle)}

	// 					document={<CourseCertificateReactPDFDocument certificate={certificate}/>
	// 					}
	// 				>
	// 					<div style={{textDecoration: "none"}}>Open Certificate</div>
	// 				</PDFDownloadLink>
	// 				: <></>
	// 		)}
	// 	</CourseCertificateDataProvider>
	// </Button>

	const generateTitle = (courseTitle: string): string => {
		const title = courseTitle.trim().split(" ").map(e => `${e[0].toLocaleUpperCase()}${e.substring(1)}`).join(" ")
		return `${title} Certificate`;
	}

	return (
		<>
			<CenterTile
				className={classes.centeredContainer}
				title="Congratulations"
				buttons={[goToTrainingFeedBtn]}
				header={<>
					<IconCourseCertificate className={classes.icon} />
				</>}
			>
				<Typography variant="h5" className={classes.tagLine}>
					You finished the course. <strong>Great job!</strong>
				</Typography>

			</CenterTile>
			{/* <Confetti width={w} height={h - Number(toolbarHeight.replace("px", ""))} /> */}
		</>
	);
}