import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {AdminHeader} from "../AdminHeader";

const useStyles = makeStyles()((theme) => ({
	adminReportsPage: {

	}

}));

export const AdminReportsPage: FC = () => {
	const {classes} = useStyles();

	return (
		<Box className={classes.adminReportsPage}>
			<AdminHeader title="Reports" subtitle="Generate reports"/>
		</Box>
	);
}