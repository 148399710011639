import {Box, BoxProps, Tab, Tabs} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {mobileNavBarHeight, palette} from "../../constants";
import {IconUpArrow} from "../../icons/IconUpArrow";
import {TrainingFeedSideMenuContent} from "../trainingFeedSideMenuContent/TrainingFeedSideMenuContent";
import {useLocation, useMatch, useNavigate, useParams} from "react-router-dom";
import {CourseSideMenuContent as CommonCourseSideMenuContent, CourseProgressionSummary} from "@plumeuk/shapeshift-common/course";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {moduleSlim} from "@plumeuk/shapeshift-types/ICourse";
import {CourseCurriculumModule} from "../module/CourseCurriculumModule";
import {LocaleContext} from "../../contexts/localeContext";

interface ICustomProps {
}

const useStyles = makeStyles<{open: boolean}>()((theme, {open}) => ({
	mobileTrainingFeedDrawer: {
		position: "fixed",
		top: open ? "20dvh" : "calc(100dvh - 100px)",
		transition: "top ease-out .3s",
		minHeight: "calc(100% - 20dvh)",
		width: "100%",
		background: palette.drawer,
		[theme.breakpoints.up("sm")]: {
			display: "none"
		}
	},
	openDrawerIconContainer: {
		background: "linear-gradient(180deg, rgba(44,44,44,0) 0%, rgba(44,44,44,0) 50%, rgba(44,44,44,1) 51%, rgba(44,44,44,1) 100%)",
		maxHeight: "35px",
		overflow: "hidden",
		zIndex: 10,
		width: "100%",
		position: "absolute",
		height: "50px",
		top: "-25px",
		display: "flex",
		justifyContent: "center"
	},

	openDrawerIcon: {
		zIndex: 10,
		justifyContent: "center",
		display: "flex",
		transition: "transform .5s ease-out",
		transform: open ? "rotate(180deg)" : "rotate(0)",
		width: "22px",
		height: "22px",
		borderRadius: "100px",
		background: palette.drawer,
		padding: "18px",
		"& svg": {
			transition: "top .5s ease-out",
			position: "relative",
			top: open ? "15px" : "0px"
		},
		"& path": {
			fill: palette.drawer
		}
	},
	content: {
		maxHeight: `calc(80vh - ${mobileNavBarHeight})`,
		overflowY: open ? "auto" : "hidden",
		paddingBottom: "60px",
		boxSizing: "border-box"
	},
	tabs: {
		height: "70px",
		minHeight: "70px",
		borderBottom: palette.grey10 + " 1px solid",
		width: "100%",
		"& button": {
			width: "50%"
		}
	},
	sideMenu: {
		"[class*='moduleTitle']": {
			color: "white"
		},
		borderRadius: "16px",
		margin: "15px",
		padding: "15px 25px",
		background: "#282828"
	},
	moduleItem: {
		margin: "10px 0"
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;

export const MobileTrainingFeedDrawer: FC<IProps> = ({className, ...props}) => {
	const [{locale}] = useContext(LocaleContext);
	const [open, setOpen] = useState(false);
	const {classes, cx} = useStyles({open});
	const contentRef = useRef<HTMLDivElement>(null);
	const pageIsFeed = useMatch("/feed/:courseSlug/*");
	const [tab, setTab] = useState<"training-feed" | "course">(pageIsFeed ? "training-feed" : "course");
	const {curriculumState, curriculumDispatch} = useContext(CurriculumContext);
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const mq = useMq();
	const {notify} = useContext(NotificationContext);
	const [activeModule, setActiveModule] = useState<moduleSlim>();

	useEffect(() => {
		//scroll drawer to top on toggle
		if(contentRef.current?.scrollTop)
			contentRef.current.scrollTop = 0;
	}, [open])

	const location = useLocation();

	useEffect(() => {
		setOpen(false)
	}, [location]);

	const handleActiveModuleCallback = useCallback((module: ICourseCurriculumModule): void => {
		navigate(`/course/${courseSlug}/${module.type}/${module.slug}`);//, {replace: !pageIsModule}


	}, [navigate]);

	const handleSetModuleComplete = useCallback((module: moduleSlim, status: boolean): void => {
		curriculumDispatch({type: "setModuleComplete", module, status})
	}, [curriculumDispatch]);

	const handleModuleForbidden = useCallback((moduleRequest: ICourseCurriculumModule, nextModule?: ICourseCurriculumModule): void => {
		notify(`Lessons must be completed in sequence. Next up is ${nextModule?.title}`, "Hey now, slow down!",INotificationSeverity.warning, 500000);
	}, [curriculumDispatch]);


	return (
		<Box className={cx(classes.mobileTrainingFeedDrawer, className)} {...props}>
			<Box className={classes.openDrawerIconContainer}>
				<Box className={classes.openDrawerIcon}>
					<IconUpArrow onClick={() => setOpen(prev => !prev)}/>
				</Box>
			</Box>
			{/* <Tabs className={classes.tabs} value={tab} onChange={(_e, v) => setTab(v)} aria-label="basic tabs example">
				<Tab label="Training Feed" value="training-feed"/>
				<Tab label="Course Content" value="course"/>
			</Tabs> */}
			{tab === "course" &&
				<CommonCourseSideMenuContent
					locale={locale}
					ref={contentRef}
					className={cx(classes.content, classes.sideMenu)}
					data={curriculumState}
					courseSlug={courseSlug}
					onActiveChange={handleActiveModuleCallback}
					onModuleComplete={handleSetModuleComplete}
					onModuleForbidden={handleModuleForbidden}
					levelIndentSize={28}
					activeModule={activeModule}
					componentOverrides={{
						courseProgressionSummary: (p) => <CourseProgressionSummary {...p}/>,
						module: (p) => {
							if(p.module.type === "section")
								return <></>

							return <CourseCurriculumModule courseSlug={courseSlug??""} onClick={() => p.setActive(p.module)} className={cx(classes.moduleItem, p.activeModuleSlug === p.module.slug ? "active" : "")} module={p.module}/>
						}
					}}
				/>
			}
			{tab === "training-feed" &&
				<TrainingFeedSideMenuContent ref={contentRef} className={classes.content} />
			}
		</Box>
	);
};
