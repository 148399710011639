import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconCourseCertificate = createSvgIcon(
	<svg width="20" height="20" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="50" cy="50" r="49" stroke="#FF9E00" strokeWidth="2"/>
		<g clipPath="url(#clip0_1487_2799)">
			<path d="M64 34.25L51.2367 47.0133C51.0743 47.1759 50.8816 47.3048 50.6694 47.3928C50.4572 47.4808 50.2297 47.5261 50 47.5261C49.7703 47.5261 49.5428 47.4808 49.3306 47.3928C49.1184 47.3048 48.9257 47.1759 48.7633 47.0133L44.75 43" stroke="#FF9E00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M33.0063 52.688L23.75 64L32.5 65.75L36 76.25L45.31 61.4753" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M66.9931 52.688L76.2495 64L67.4995 65.75L63.9995 76.25L54.6895 61.4753" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M67.5001 44.253L69.4694 49.4236C69.6122 49.7966 69.623 50.2073 69.4999 50.5873C69.3768 50.9673 69.1272 51.2936 68.7927 51.512L64.1587 54.5453L62.7121 59.8886C62.6077 60.274 62.3747 60.6121 62.0517 60.8468C61.7287 61.0815 61.3352 61.1987 60.9364 61.179L55.4087 60.9036L51.0991 64.3756C50.7875 64.626 50.3998 64.7624 50.0001 64.7624C49.6004 64.7624 49.2127 64.626 48.9011 64.3756L44.5914 60.8966L39.0637 61.172C38.665 61.1917 38.2714 61.0745 37.9485 60.8398C37.6255 60.6051 37.3925 60.267 37.2881 59.8816L35.8414 54.5383L31.2074 51.505C30.873 51.2866 30.6234 50.9603 30.5003 50.5803C30.3772 50.2003 30.3879 49.7896 30.5307 49.4166L32.5001 44.253L30.5307 39.08C30.3853 38.7054 30.3732 38.2923 30.4964 37.9099C30.6197 37.5275 30.8707 37.1991 31.2074 36.98L35.8414 33.9466L37.2881 28.6033C37.3925 28.2179 37.6255 27.8798 37.9485 27.6451C38.2714 27.4104 38.665 27.2932 39.0637 27.313L44.5914 27.6L48.9011 24.1373C49.2127 23.8869 49.6004 23.7505 50.0001 23.7505C50.3998 23.7505 50.7875 23.8869 51.0991 24.1373L55.4087 27.6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_1487_2799">
				<rect width="56" height="56" fill="white" transform="translate(22 22)"/>
			</clipPath>
		</defs>
	</svg>,
	"CourseCertificate",
	"0 0 20 20"
);