import {ValidationDoc} from "@plumeuk/use-validate";
import {ILessonEntityPayload} from "@plumeuk/shapeshift-types";

export const cohortLessonValidationDoc: ValidationDoc<ILessonEntityPayload> = {
	title: [{
		errorMessage: "required",
		validate: e => !!e.title
	}]
}

