import {FC, useEffect, useState} from "react";
import {Box, BoxProps, Button, LinearProgress} from "@mui/material";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IQuizEntity, corePaths} from "@plumeuk/shapeshift-types";
import NewModuleModal from "../Courses/AdminCourseCurriculum/newModuleModal";
import {Add, Delete} from "@mui/icons-material";
import {makeStyles} from "tss-react/mui";

interface IProps extends Omit<BoxProps, "onChange"> {
	quizId?: number | null,
	onChange: (quizId: number) => void,
	onSelectAction?: () => void,
	onDeleteAction?: () => void
}

const cohortBaseUrl = "/api/strapi-plugin-shapeshift-lms/cohort";

const useStyles = makeStyles()((theme) => ({
	adminLessonInlineQuiz: {},
	quizCard: {
		background: theme.palette.grey[100],
		width: "fit-content",
		display: "flex",
		justifyContent: "space-between",
		"& svg path": {
			fill: theme.palette.primary.main,
			cursor: "pointer"
		},
		"& > *": {
			padding: "20px"
		}
	},
	quizTitle: {
		cursor: "pointer"
	}
})
);

export const AdminLessonInlineQuiz: FC<IProps> = ({quizId, onChange, onSelectAction, onDeleteAction, className, ...props}) => {
	const [quiz, getQuiz] = useApi<IQuizEntity>(quizId ? `${cohortBaseUrl}/quiz/${quizId}` : undefined) //> to do update to hook
	const [attachInlineQuizModalOpen, setAttachInlineQuizModalOpen] = useState(false)
	const {cx, classes} = useStyles();

	useEffect(() => {
		if(quizId)
			getQuiz(`${cohortBaseUrl}/quiz/${quizId}`)
	}, [quizId])

	return (
		<Box className={cx(classes.adminLessonInlineQuiz, className)} {...props}>
			{(quizId && (!quiz.data || quiz.isLoading) )&& <LinearProgress />}

			{quiz.data && <Box className={classes.quizCard}>
				<Box className={classes.quizTitle} onClick={() => onSelectAction?.()}>
					{quiz?.data?.title}
				</Box>
				<Box>
					<Delete onClick={() => onDeleteAction?.()}/>
				</Box>
			</Box> }


			{!quizId && <Button sx={{display: "flex", gap: "10px", height: "28px"}} onClick={() => setAttachInlineQuizModalOpen(true)}><Add />Attach</Button>}

			{attachInlineQuizModalOpen && <NewModuleModal typeFilter={corePaths.quiz} onClose={(newModules) => {
				if(newModules?.[0]?.id)
					onChange(newModules?.[0]?.id)
				setAttachInlineQuizModalOpen(false);
			}} />}
		</Box>
	);
}