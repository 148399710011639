import {useNavigate} from "react-router-dom";
import {DynamicContainer, NotesLibrary} from "@plumeuk/shapeshift-common/v2";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {Box, Typography} from "@mui/material";
import {useCourseContentType} from "./useCourseContentType";
import {NotesFilter} from "./NotesFilter";
import {PageTitle} from "../../components/pageBase/pageTitle";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		[theme.breakpoints.up("lg")]:{
			"& [class*='menuIcon']": {
				display: "none"
			}
		}
	},
	container:{
		paddingBottom: "70px",
		maxWidth: "1420px",
		width: "calc(100% - 20px)",
		margin: "auto"
	},
	topContainer: {
		margin: "60px 0 0"
	},
	pageTitle: {
		borderBottom: "0px solid",
		marginTop: "60px",
		marginBottom: 0
	}
}));


export const MyNotesPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();
	const {items: contentTypes} = useCourseContentType()

	return (
		<PageBase className={classes.pageBase} contentWidth="100%" disableSideBar>
			<Box className={classes.container}>
				<PageTitle className={classes.pageTitle} title="Notes library" />
				<NotesLibrary onGoToModuleAction={(courseId, moduleId, moduleType, timestamp) => {navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}})}} componentOverrides={{notesFilter: (props) => {
					return <NotesFilter {...props} contentTypes={contentTypes} />
				}}} />
			</Box>
		</PageBase>
	);
}