import {IQuestionEntity, IQuestionEntitySlim} from "@plumeuk/shapeshift-types";
import {IQuizBuilderQuestion, IQuizCompositionQuestion} from "@plumeuk/shapeshift-types/IQuiz";

export const mapToIQuizBuilderQuestion = (e: IQuizCompositionQuestion | IQuestionEntitySlim): IQuizBuilderQuestion => {
	const {id, __type} = e;

	if(!__type)
		throw new Error("Only questions(IQuizCompositionQuestion) with defined __type can be mapped to a IQuizBuilderQuestion");

	return {__type, dragId: __type + "-" + id, id}
}

export const mapToIQuizCompositionQuestion = (e: IQuestionEntity | IQuestionEntitySlim): IQuizCompositionQuestion => {
	if(!e.__type)
		throw new Error("Only questions(IQuizCompositionQuestion) with defined __type can be mapped to a IQuizBuilderQuestion");

	return {__type: e.__type, id: e.id}
}

export const moveQuestion = (questions: IQuizBuilderQuestion[], sourceIndex: number, destinationIndex: number): IQuizBuilderQuestion[] => {
	const removedElement = questions.splice(sourceIndex, 1)[0];
	questions.splice(destinationIndex, 0, removedElement);
	return questions;
}
