import {makeStyles} from "tss-react/mui";
import {Box, BoxProps, Typography} from "@mui/material";
import {palette} from "../../constants";

const useStyles = makeStyles()((theme) => ({
	myProgressPageContainer: {
		flex: 1,
		padding: "10px",
		minWidth: "350px",
		justifyContent: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "5px",
		overflow: "auto"
	},
	containerTitle: {
		fontSize: "21px",
		fontWeight: 600,
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	containerSubtitle: {
		fontSize: "17px",
		fontWeight: 400,
		color: palette.grey70

	},
	dataContainer: {
		minHeight: "300px",
		marginTop: "25px"

	}
}));

interface IProps extends BoxProps {
	title: string,
	subtitle: string,
}

export const MyProgressPageContainer: React.FC<IProps> = ({title, subtitle, children}) => {
	const {classes} = useStyles();

	return (
		<Box className={classes.myProgressPageContainer}>
			<Typography className={classes.containerTitle}>{title}</Typography>
			<Typography className={classes.containerSubtitle}>{subtitle}</Typography>
			<Box className={classes.dataContainer}>
				{children}
			</Box>
		</Box>
	);
}