import {FC} from "react";
import {Box} from "@mui/material";
import {IQuizEntityPayload, IQuizEntity} from "@plumeuk/shapeshift-types";
import Editor from "../../../../components/admin/WYSIWYG/Editor";
import AdminQuizBuilder from "./AdminQuizBuilder/AdminQuizBuilder";
import {AdminEntityPageContainer, AdminInputText, AdminFieldContainer, AdminInputSwitch, AdminEntityPageContainerProps} from "@plumeuk/shapeshift-common/admin";
import {cohortQuizValidationDoc} from "../../../../validationDocs/user/cohortQuizValidationDoc";

interface IProps extends Partial<AdminEntityPageContainerProps<IQuizEntity, "quiz">> {
	quizId?: number
}

const template: IQuizEntityPayload = {
	title: "", slug: "", content: ""
}

export const AdminQuizPageContent: FC<IProps> = ({quizId, ...props}) => {

	const IQuizEntityToIQuizEntityPayload = (e: IQuizEntity): IQuizEntityPayload => {
		const {title, slug, content, id, updatedBy, createdBy, createdAt, updatedAt, subtitle, scoreRequired, randomiseOrder, composition} = e
		const videoData = (e as any).videoData;
		const payload = {
			title, slug, content, id, updatedBy, createdBy, createdAt, updatedAt, subtitle, videoData, scoreRequired, randomiseOrder, composition
		}
		return payload;
	}

	return (
		<AdminEntityPageContainer<IQuizEntity, "quiz">
			entityId={quizId}
			entityToPayload={IQuizEntityToIQuizEntityPayload}
			template={template}
			validationDoc={cohortQuizValidationDoc}
			type="quiz"
			{...props}
		>
			{({formData, setFormData, entity, locale, validation}) => <>
				{/* <AdminInputText onChange={e => setFormData(prev => ({...prev, slug: e.target.value}))} value={formData.slug} label={"Slug"}/> */}
				<AdminInputText error={validation?.[0]?.title} required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Quiz title"}/>
				<AdminInputText error={validation?.[0]?.subtitle} onChange={e => setFormData(prev => ({...prev, subtitle: e.target.value}))} value={formData.subtitle} label={"Quiz subtitle"}/>
				<AdminFieldContainer label="Content" error={validation?.[0]?.content}><Editor onChange={e => setFormData(prev => ({...prev, content: e.target.value}))} value={formData.content ?? ""} /></AdminFieldContainer>
				{locale === "en" && <AdminInputText error={validation?.[0]?.scoreRequired} required type="number" onChange={e => setFormData(prev => ({...prev, scoreRequired: e.target.value as any as number}))} value={formData.scoreRequired} label={"Quiz Pass Threshold"}/>}
				{locale === "en" && <AdminInputSwitch error={validation?.[0]?.randomiseOrder} onChange={e => setFormData(prev => ({...prev, randomiseOrder: e.target.checked}))} checked={formData.randomiseOrder} label={"Randomize Question Order"}/>}
				{locale === "en" && <AdminFieldContainer error={validation?.[0]?.questions} required label="Questions"><AdminQuizBuilder onChange={e => setFormData(prev => ({...prev, composition: e}))} initialData={entity} /></AdminFieldContainer>}
			</>}
		</AdminEntityPageContainer>
	);
}