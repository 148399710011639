import {FC} from "react";
import {IQuizBuilderQuestion} from "@plumeuk/shapeshift-types";
import {makeStyles} from "tss-react/mui";
import {Box, Button} from "@mui/material";
import {Add, ArrowLeft, ArrowRight, Refresh} from "@mui/icons-material";
import {palette} from "../../../../../../constants";

export interface IProps {
	questionHistoryStack: IQuizBuilderQuestion[][],
	undoRedoI?: number,
	onNewQuestionAction: () => void,
	onUndo: () => void,
	onRedo: () => void,
	onReset: () => void
}

const useStyles = makeStyles()(
	(theme) => ({
		quizControls: {
			display: "flex",
			zIndex: 1,
			top: 0,
			background: palette.grey15,
			gap: "10px",
			padding: "20px 32px",
			position: "sticky",
			justifyContent: "space-between"
		},
		controls: {
			justifyContent: "right",
			display: "flex",
			gap: "10px",
			"& > div": {
				fontWeight: 600,
				cursor: "pointer",
				background: palette.grey15,
				border: "1px solid " + theme.palette.grey[300],
				padding: "7px 10px",
				display: "flex",
				gap: "20px",
				borderRadius: "5px",
				fontSize: "0.75rem",
				"& svg": {
					opacity: 0.5
				},
				"&:hover": {
					background: "transparent"
				}
			},
			"& > button": {
				height: "28px",
				"& svg": {
					marginRight: "10px"
				}
			}
		}
	})
);

const QuizBuilderControls: FC<IProps> = (
	{questionHistoryStack, undoRedoI, onNewQuestionAction, onUndo, onRedo, onReset}
) => {
	const enableUndo = questionHistoryStack.length > 0 && (undoRedoI === undefined || undoRedoI > 0);
	const enableRedo = undoRedoI !== undefined && undoRedoI < (questionHistoryStack.length - 1);
	const enableReset = questionHistoryStack.length > 0;
	const {classes} = useStyles();

	return (
		<Box className={classes.quizControls}>
			<Box className={classes.controls}>
				<Button
					data-test-id="quiz-builder-controls-add-question-btn"
					onClick={() => onNewQuestionAction()}
				>
					<Add />Questions
				</Button>
			</Box>
			<Box className={classes.controls}>
				<Button data-test-id="quiz-builder-controls-undo-btn" disabled={!enableUndo} onClick={() => onUndo()}>
					<ArrowLeft /> Undo
				</Button>
				<Button data-test-id="quiz-builder-controls-redo-btn" disabled={!enableRedo} onClick={() => onRedo()}>
					<ArrowRight /> Redo
				</Button>
				<Button data-test-id="quiz-builder-controls-reset-btn" disabled={!enableReset} onClick={() => onReset()}>
					<Refresh /> Reset
				</Button>
			</Box>
		</Box>
	)
}

export default QuizBuilderControls;