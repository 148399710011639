import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {IQuestionEntity, corePaths, IMultipleChoiceQuestionEntityPayload, IMultipleChoiceQuestionEntity} from "@plumeuk/shapeshift-types";
import {useParams} from "react-router-dom";
import AdminStringList from "../../../AdminStringList/AdminStringList";
import {AdminEntityPageContainer, AdminInputText, AdminFieldContainer} from "@plumeuk/shapeshift-common/admin";

interface IProps {
	onSave?: (e: Pick<IQuestionEntity, "id" | "__type" | "title">) => void
}

const useStyles = makeStyles()((theme) => ({
	adminQuizQuestionMultipleChoicePage: {

	}
}));

const template: IMultipleChoiceQuestionEntityPayload = {
	title: "", answers: [], hint: "", options: []
}

export const AdminQuizMultipleChoiceQuestionPage: FC<IProps> = ({onSave}) => {
	const {classes} = useStyles();
	const {questionId} = useParams();

	const IQuestionEntityToIQuestionEntityPayload = (e: IMultipleChoiceQuestionEntity): IMultipleChoiceQuestionEntityPayload => {
		const {title, answers, options, content, id, updatedBy, createdBy, createdAt, updatedAt, hint} = e
		const payload = {
			title, answers, content, id, options, updatedBy, createdBy, createdAt, updatedAt, hint
		}
		return payload;
	}
	return (
		<Box className={classes.adminQuizQuestionMultipleChoicePage}>
			<AdminEntityPageContainer<IMultipleChoiceQuestionEntity, "questionMultipleChoice">
				entityToPayload={IQuestionEntityToIQuestionEntityPayload}
				template={template}
				entityId={questionId ? parseInt(questionId) : undefined}
				type="questionMultipleChoice"
				onSaveResponse={e => (!e.isLoading && e.data) && onSave?.({...e.data, __type: corePaths["questionMultipleChoice"]})}
			>
				{({formData, setFormData}) => <>
					<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Title"}/>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, hint: e.target.value}))} value={formData.hint} label={"Hint"}/>
					<AdminFieldContainer required label="All Options"><AdminStringList defaultMessage="" allowDuplicates={false} value={formData.options ?? []} onChange={e => setFormData(prev => ({...prev, options: e}))}/></AdminFieldContainer>
					<AdminFieldContainer required label="Corrrect Answers"><AdminStringList defaultMessage="" allowDuplicates={false} value={formData.answers ?? []} onChange={e => setFormData(prev => ({...prev, answers: e}))}/></AdminFieldContainer>
				</>}
			</AdminEntityPageContainer>
		</Box>
	);
}