import {BoxProps} from "@mui/material"
import {FC} from "react"
import {makeStyles} from "tss-react/mui";
import type {IModuleScheduled} from "@plumeuk/shapeshift-types";
import {Link, useMatch} from "react-router-dom";
import {palette} from "../../constants";
import {ModuleSummaryCard} from "../module/ModuleSummaryCard";

interface IProps extends BoxProps {
	module: IModuleScheduled
}

const useStyles = makeStyles<{pageIsActive: boolean}>()((theme, {pageIsActive}) => ({
	trainingFeedModule: {
		outline: "1px solid " + (pageIsActive ? theme.palette.primary.main : palette.grey05)
	}
}));


export const TrainingFeedModuleSlim:FC<IProps> = ({className, module, ...boxProps}) => {
	const url = "/feed/" + module.course.slug + "/" + module.type + "/" + module.slug;
	const pageIsActive = useMatch(url);
	const {classes, cx} = useStyles({pageIsActive: !!pageIsActive});

	return (
		<Link to={url} style={{width: "100%"}}>
			<ModuleSummaryCard module={module} className={cx(classes.trainingFeedModule, className)} {...boxProps}/>
		</Link>
	)
}