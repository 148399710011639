
import {makeStyles} from "tss-react/mui";
import {Box, CircularProgress, Typography} from "@mui/material";
import {API, useApi} from "@plumeuk/shapeshift-identity";
import {IModuleCompleted, IPaginatedResponse} from "@plumeuk/shapeshift-types";
import {ModuleSummaryCard} from "../../components/module/ModuleSummaryCard";
import {palette} from "../../constants";
import {useEffect, useState} from "react";
import {ExpandMore} from "@mui/icons-material";
import {Link} from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
	recentCompletedModules: {
		display: "flex",
		flexDirection: "column",
		gap: "10px",
		padding: "15px",
		border: "1px solid " + palette.grey08,
		borderRadius: "16px",
		background: "#282828"
	},
	centerWrapper: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		flexDirection: "column"
	},
	expandIcon: {
		fill: theme.palette.primary.main,
		fontSize: "32px",
		[theme.breakpoints.down("sm")]: {
			display: "none"
		},
		margin: "0",
		color: theme.palette.secondary.main
	},
	viewAllContainer: {
		textAlign: "center",
		width: "100%",
		display: "flex",
		gap: "5px",
		cursor: "pointer",
		justifyContent: "center",
		"& svg": {
			margin: "-4px"
		},
		"& [class*='MuiCircularProgress-root']": {
			width: "10px !important",
			height: "10px !important",
			margin: "7px"
		},
		"& p": {
			fontWeight: 600,
			fontSize: "16px"
		}
	}
}));

const dataTestId = `my-recent-completed-modules-${module.id ?? 0}`
export const MyRecentCompletedModules: React.FC = () => {
	const {classes} = useStyles();
	const pageSize = 10;
	const [{data: modulesApiResult, isLoading}, getModules] = useApi<IPaginatedResponse<IModuleCompleted[]>>();
	const [modules, setModules] = useState<{[page: number]: IModuleCompleted[]}>({});
	const [page, setPage] = useState<number>(1);

	const modulePageLengths = Object.keys(modules).map(e => modules[parseInt(e)].length);
	const moreToGather = modulesApiResult && (modulePageLengths.length === 0 ? 0 : modulePageLengths.reduce((sum, e) => sum + e) < modulesApiResult?.totalCount)

	useEffect(() => {
		if(modulesApiResult?.data){
			setModules(prev => {
				if(prev[modulesApiResult.page])
					return prev;
				return {...prev, [modulesApiResult.page]: modulesApiResult.data}
			})
		}
	}, [modulesApiResult])

	useEffect(() => {
		setModules({})
	}, [])

	useEffect(() => {
		getModules({url: API.getRecentCompletedModules, data: {pageSize, page}})
	}, [page])

	const handleNextPage = (): void => {
		if(isLoading)
			return;
		setPage(prev => ++prev);
	}

	return (
		<Box className={classes.recentCompletedModules}>
			{(isLoading && Object.keys(modules).length === 0) && <div className={classes.centerWrapper}><CircularProgress color="secondary" /></div>}
			{!isLoading && modulesApiResult?.totalCount === 0 && <Typography>No recent activity</Typography>}
			{Object.keys(modules).map(e => modules[parseInt(e)].map(e =>
				<Link to={`/course/${e.courseSlug}/${e.type}/${e.slug}`} key={e.id + (e.__type ?? "")}>
					<ModuleSummaryCard module={{...e, complete: false}} />
				</Link>
			))}
			{moreToGather && (
				<Box className={classes.viewAllContainer} onClick={handleNextPage}>
					<Typography>View all</Typography>
					{isLoading && <CircularProgress/>}
					{!isLoading && <ExpandMore
						data-test-id={`${dataTestId}-expand-more-btn`}
						className={classes.expandIcon}
					/>}
				</Box>
			)}
		</Box>
	);
}