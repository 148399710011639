import {APIRequestConfig} from "@plumeuk/shapeshift-common/types/ICommon";
import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {useEffect} from "react";

type S = string[];
type R = string;
type G = (config?: APIRequestConfig<R>) => void;

export type IUseContentTypeReturnProp = {
	items: S,
	apiResult: APIState<S>,
	getContentTypes: G,
}

const API_URL = "/api/course/contentTypes"

export const useCourseContentType = (): IUseContentTypeReturnProp => {
	const [apiResult, getData] = useApi<S>({url: API_URL});

	const getContentTypes: G = (config?: APIRequestConfig<R>) => {
		getData({...config, url: API_URL});
	}

	useEffect(()=>{
		getContentTypes()
	}, [])

	return {items: apiResult.data ?? [], apiResult, getContentTypes};
}