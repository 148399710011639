import {Box, BoxProps} from "@mui/material";
import React, {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {AuthContext} from "@plumeuk/shapeshift-identity";

const useStyles = makeStyles()(() => ({
	profileImage: {
		overflow: "hidden",
		"img": {
			height: "100%",
			maxHeight: "100%",
			maxWidth: 2000,
			width: "100%",
			objectFit: "cover"
		}
	}
}));

type IProps = Omit<BoxProps, "children">;


export const ProfileImage: FC<IProps> = ({className, ...props}) => {
	const {user} = useContext(AuthContext);
	const {classes, cx} = useStyles();
	const profileImage = (user as any)?.profileImage;
	return (
		<Box className={cx(classes.profileImage, className)} {...props}>
			{profileImage && <img width="200" height="200" alt="profile-image" src={profileImage}/> }
		</Box>
	);
};
