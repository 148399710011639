import {ICourseCurriculumItemEntity, ICurriculumItemEntity, IFlatList} from "@plumeuk/shapeshift-types";

export const mapItem = (e: ICourseCurriculumItemEntity, sectionCount: number): IFlatList => {
	if(!e.module){
		const i = ++sectionCount
		return {type: "section", title: e.title ?? "", level: e.level, dragId: "section-" + i, slug: "section-" + i}
	}
	else {
		const {module, level, id, progression, moduleType} = e;

		if(!moduleType)
			throw new Error("invalid ICourseCurriculumItemEntity");

		return {
			type: moduleType,
			slug: module.slug,
			title: module.title,
			level,
			dragId: moduleType + "-" + id,
			moduleId: module.id,
			progression
		}
	}
}

// export const mapItem = (e: ICurriculumItemEntity, level: number, modulesMap: ModulesMap, sectionCount: number): IFlatList => {
// 	if(isCurriculumSectionEntity(e)){
// 		const {type, title} = e;
// 		const i = ++sectionCount
// 		return {type, title, level, dragId: type + "-" + i, slug: "section-" + i}
// 	}
// 	else {
// 		const {type, id, progression} = e;
// 		return {
// 			type,
// 			slug: modulesMap?.[e.type]?.[e.id]?.slug,
// 			title: modulesMap?.[e.type]?.[e.id]?.title,
// 			level,
// 			dragId: type + "-" + id,
// 			moduleId: id,
// 			progression
// 		}
// 	}
// }

export const initFlatList = (items: ICourseCurriculumItemEntity[]): IFlatList[] => {
	const exportList: IFlatList[] = [];
	let sectionCount = 0;

	items.forEach(e => {
		try{
			const newItem = mapItem(e, sectionCount);
			if(newItem.type === "section")
				sectionCount ++;

			exportList.push(newItem);
		}
		catch(ex){
			// eslint-disable-next-line no-console
			console.error("initFlatList", ex)
		}
	})

	return exportList;
}

// export const flatten = (module: ICurriculumItemEntity[], modulesMap: ModulesMap, level = 0, _sectionCount = 0): IFlatList[] => {
// 	let exportList: IFlatList[] = [];
// 	let sectionCount = _sectionCount;
// 	module.forEach(e => {
// 		const newItem = mapItem(e, level, modulesMap, sectionCount);
// 		if(newItem.type === "section")
// 			sectionCount ++;

// 		exportList.push(newItem);
// 		if (e.modules) {
// 			const newChildrenItems = flatten(e.modules, modulesMap, level + 1, sectionCount);
// 			sectionCount += newChildrenItems.filter(ec => ec.type === "section").length;
// 			exportList = exportList.concat(newChildrenItems)
// 		}
// 	})

// 	return exportList;
// }

//Work out if element at index is collapsed
export const isCollapsed = (flatList: IFlatList[], index: number): boolean => {
	const module = flatList[index];
	let level = module.level;
	for (let i = (index + 1); i >= 0; i--) {
		const e = flatList[i];
		if (e.level < level) {
			if (e.collapsedChildren)
				return true;
			level = e.level;
		}
		else if (e.level > level) {
			return false;
		}
	}
	return false;
}

export const moveElement = (flatList: IFlatList[], sourceIndex: number, destinationIndex: number):IFlatList[] => {
	const removedElement = flatList.splice(sourceIndex, 1)[0];
	flatList.splice(destinationIndex, 0, removedElement);
	return flatList;
}

export const demoteModule = (flatList: IFlatList[], sourceIndex: number, destinationIndex: number):IFlatList[] => {
	const destinationTargetLevel = flatList[destinationIndex].level;
	const originalLevel = flatList[sourceIndex].level;
	const sourceLevelChange = (destinationTargetLevel + 1) - originalLevel;
	const direction = sourceIndex > destinationIndex ? 1 : 0;

	//adjust level of parent
	flatList[sourceIndex].level += sourceLevelChange;

	//adjust level of children if present and parent is collapsed
	let i = 1;
	if (flatList[sourceIndex].collapsedChildren) {
		while (flatList[sourceIndex + i]?.level > originalLevel) {
			flatList[sourceIndex + (i++)].level += sourceLevelChange;
		}
	}

	return moveModule(flatList, sourceIndex, destinationIndex + direction)
}


export const moveModule = (flatList: IFlatList[], sourceIndex: number, destinationIndex: number): IFlatList[] => {
	const aboveindex = destinationIndex === 0 ? 0 : destinationIndex - 1;
	const belowindex = destinationIndex;
	const movingItem = flatList[sourceIndex];
	const originalLevel = movingItem.level;

	//Change level if required
	//Root element must be level 0 as it has no parent
	let levelAdjustment = 0;
	if (destinationIndex == 0) {
		levelAdjustment = -movingItem.level;
		movingItem.level = 0;
	}
	//Each child should aways be a maximum of 1 level step away from a parent
	else if ((originalLevel - flatList[aboveindex].level) > 1) {
		levelAdjustment = flatList[aboveindex].level + 1 - movingItem.level;
		movingItem.level = flatList[aboveindex].level + 1;
	}
	else if ((flatList[belowindex].level - originalLevel) > 1) {
		levelAdjustment = flatList[belowindex].level - movingItem.level - 1;
		movingItem.level = flatList[belowindex].level - 1;
	}
	//check after too
	//should not break after. eg. level 0 stuck where a level 2 proceeds

	flatList.move(sourceIndex, destinationIndex)

	if (movingItem.collapsedChildren) {
		if (destinationIndex > sourceIndex) {
			while (flatList[sourceIndex].level > originalLevel) {
				flatList[sourceIndex].level += levelAdjustment;
				flatList.move(sourceIndex, destinationIndex);
			}
		}
		else {
			let i = 1;
			while ((sourceIndex + i < flatList.length) && flatList[sourceIndex + i].level > flatList[destinationIndex + i].level) {
				flatList[sourceIndex + i].level += levelAdjustment;
				flatList.move(sourceIndex + i, destinationIndex + i);
				i++
			}
		}

	}

	return validateList(flatList);
}

export const deleteModule = (flatList: IFlatList[], index: number): IFlatList[] => {
	const _flatList = [...flatList];
	let deleteCount = 1;

	if(flatList[index].collapsedChildren){
		let prev = flatList[index];
		for(let i = (index + 1); i < (flatList.length + 1); i++){
			if(deleteCount === 1 && (i === flatList.length || prev.level > flatList[i].level)){
				deleteCount = (i - index);
				break;
			}
			prev = flatList[i]
		}
	}

	_flatList.splice(index, deleteCount);
	const result = [...validateList(_flatList)]
	return result;
}

//ensure levels are only + 1 in sequence 
//if not, adjust
export const validateList = (flatList: IFlatList[]): IFlatList[] => {
	if(flatList.length > 0 && flatList[0].level > 0){
		flatList[0].level = 0;
	}

	let prevLevel = 0;

	flatList.forEach((e) => {
		if((e.level - prevLevel) > 1){
			e.level = prevLevel + 1;
		}
		prevLevel = e.level;
	})
	return flatList;
}

//Used for testing
//Input from db which is converted to flatArray
export const curriculumMockData: ICurriculumItemEntity[] = [
	{id: 1, type: "lesson"},
	{id: 2, type: "lesson"},
	{id: 3, type: "lesson"},
	{
		id: 4, type: "section", modules: [
			{id: 5, type: "lesson"},
			{id: 6, type: "lesson"},
			{id: 7, type: "lesson"}
		]
	},
	{id: 8, type: "lesson"},
	{
		id: 9, type: "section", modules: [
			{id: 10, type: "lesson"},
			{id: 11, type: "lesson"},
			{
				id: 12, type: "lesson", modules: [
					{id: 13, type: "lesson"},
					{id: 14, type: "lesson"},
					{id: 15, type: "lesson"}]
			}
		]
	}
]