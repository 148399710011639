import {Box, Typography} from "@mui/material";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {CourseProductSummaryTile} from "@plumeuk/shapeshift-common/product";
import {LibraryItemTags} from "@plumeuk/shapeshift-common/library";
import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {LibraryCourseDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC, useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {PageBase} from "../../components/pageBase/pageBase";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {getAllFontStyles} from "../../templates/defaultTheme";
import {LocaleContext} from "../../contexts/localeContext";

const useStyles = makeStyles()((theme) => ({
	courseLibraryProductPage: {
		marginTop: "40px",
		paddingTop: "80px",
		padding: "20px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	topContainer: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column"
		}
	},
	summaryTile: {
		marginRight: "40px",
		flexShrink: 0,
		marginBottom: "30px",
		width: "320px",
		"p": {
			color: theme.palette.text.primary
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	title:{
		marginBottom: "25px"
	},
	container: {
		width: "calc(100% - 360px)",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	content: {
		...getAllFontStyles(theme),
		margin: "80px 0"
	},
	tagContainer: {
		display: "flex",
		flexFlow: "row",
		flexWrap: "wrap",
		"& [class*='tagContainer']": {
			flexFlow: "row",
			flexWrap: "wrap",
			"& [class*='tag']": {
				marginBottom: "12px"
			}
		}
	},
	videoPlayer: {
		margin: "40px 0"
	},
	recommended: {
		marginTop: "30px"
	}
}));

export const CourseLibraryProductPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const mq = useMq();
	const [{locale}] = useContext(LocaleContext);

	return (
		<PageBase contentWidth="1300px" disableSideBar>
			<LibraryCourseDataProvider locale={locale} slug={courseSlug} >
				{({course}) => (
					<Box className={classes.courseLibraryProductPage}>
						<Box className={classes.topContainer}>
							{course && <CourseProductSummaryTile
								libraryItem={course}
								className={classes.summaryTile}
								onEnrollAction={() => navigate("/course/" + courseSlug)}
							/>}
							<Box className={classes.container}>
								<PageTitle className={classes.title} title={course?.title} subtitle={course?.description} />
								<Box className={classes.tagContainer}>
									<LibraryItemTags item={course ? {...course, __type: "plugin::strapi-plugin-shapeshift-cms.course"} : undefined}/>
								</Box>
								{course?.videoUrl && <VideoPlayer
									className={classes.videoPlayer}
									url={course.videoUrl}
									controls={true}
									height={mq.mobile ? "250px" : "550px"}
								/>}
								<WYSIWYG className={classes.content}>{course?.content ?? ""}</WYSIWYG>
							</Box>
						</Box>
						{/* <ProductRecommended
							className={classes.recommended}
							onGoToItemAction={slug => navigate(`/library/${slug}`)}
						/> */}
					</Box>
				)}
			</LibraryCourseDataProvider>
		</PageBase>
	);
}