import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {IQuizEntityPayload, IQuizEntity} from "@plumeuk/shapeshift-types";
import {useNavigate, useParams} from "react-router-dom";
import {APIState} from "@plumeuk/shapeshift-identity";
import {AdminQuizPageContent} from "./AdminQuizPageContent";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
	adminQuizPage: {}
}));

const template: IQuizEntityPayload = {
	title: "", slug: "", content: ""
}

export const AdminQuizPage: FC<IProps> = () => {
	const {quizId} = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const entityId = quizId ? parseInt(quizId) : undefined;
	const [locale, setLocale] = useState<string>();

	const handleOnSaveResponse = (e: APIState<IQuizEntity>): void => {
		if(!e.data)
			return;

		navigate("../" + e.data.id.toString())
	}

	const handleOnGetResponse = (entityApiResponse: APIState<IQuizEntity>): void => {
		if(!entityApiResponse.data || entityApiResponse.isLoading)
			return;

		if(entityApiResponse.statusCode === 404){
			navigate("../")
		}

		if(!entityApiResponse.data)
			return;

		if(entityId && entityApiResponse.data.id !== entityId){
			navigate("../" + entityApiResponse.data.id.toString() + "?locale=" + entityApiResponse.data.locale)
		}
	}

	const handleOnDeleteResponse = (deleteApiResponse: APIState<IQuizEntity>): void => {
		if(!deleteApiResponse.data || deleteApiResponse.isLoading)
			return;

		if(deleteApiResponse.statusCode === 200){
			navigate("../")
		}
	}

	return (
		<Box className={classes.adminQuizPage}>
			<AdminQuizPageContent
				entityId={quizId ? parseInt(quizId) : undefined}
				onSaveResponse={handleOnSaveResponse}
				onDeleteResponse={handleOnDeleteResponse}
				onGetResponse={handleOnGetResponse}
				onLocaleChange={e => setLocale(e)}
				locale={locale}
			/>
		</Box>
	);
}