import {Box, BoxProps, Typography} from "@mui/material"
import {FC} from "react"
import {API} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import type {ILesson} from "@plumeuk/shapeshift-types";
import type {ICourse, ICourseModule} from "@plumeuk/shapeshift-types/ICourse";
import {Check, CheckBox, Quiz} from "@mui/icons-material";
import VideoPreviewSlim from "../../pages/TrainingFeedPage/VideoPreviewSlim";
import {palette} from "../../constants";
import {IconAdminCourses} from "../../icons/IconAdminCourses";

interface IProps extends BoxProps {
	course: ICourse
}

const useStyles = makeStyles<{complete: boolean}>()((theme, {complete}) => ({
	courseSummaryCard: {
		width:  "100%",
		display: "flex",
		flexDirection: "row",
		gap: "10px",
		textAlign: "left",
		boxSizing: "border-box",
		borderRadius: "16px",
		padding: "10px",
		background: palette.grey05,
		backgroundClip:  "padding-box",
		flexGrow: 2,
		position: "relative",
		border: "1px solid " + (complete ? theme.palette.primary.main : palette.grey05),

		"[class*='previewImageSvg']": {
			width: "32px!important",
			height: "32px",
			svg: {
				width: "44px",
				height: "44px",
				transform: "translate(-6px, -6px)",
				marginRight: "auto",
				marginLeft: "auto"

			}
		}
	},
	videoImage: {},
	title: {
		fontWeight: 600,
		fontSize: "15px",
		overflow: "hidden",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		"-webkit-line-clamp": "4"
	},
	blankPreview: {
		borderRadius: "5px",
		overflow: "hidden",
		width: "90px",
		height: "70px",
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		svg: {
			fill: theme.palette.common.white,
			width: "38px",
			height: "38px"
		}
	},
	blankButton: {
		backgroundColor: "transparent",
		padding: "0px",
		border: "none",
		lineHeight: "1em",
		":hover": {
			backgroundColor: "transparent",
			border: "none"
		}
	},
	completedCheck: {
		opacity: 1,
		position: "absolute",
		width: "20px",
		height: "20px",
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		flexDirection: "column",
		borderRadius: "100%",
		background: theme.palette.primary.main,
		top: -8,
		right: -8,
		zIndex: 10,
		"& svg": {
			width: "15px",
			height: "15px",
			margin: "auto",
			"& path": {
				fill: theme.palette.common.black
			}
		}
	},
	itemDetailsContainer: {
		border: "none",
		"h3": {
			textDecoration: "none"
		}
	}
}));


export const CourseSummaryCard:FC<IProps> = ({className, course}) => {
	const {classes, cx} = useStyles({complete: course.percentCompleted === 100});
	const thumbnailUrl = course.featuredImage?.formats?.small?.url ?? course.featuredImage?.url;

	return (
		<Box className={cx(classes.courseSummaryCard, className)}>
			{(course.percentCompleted === 100) && <Box className={classes.completedCheck}>
				<Check/>
			</Box>}
			<Box className={classes.blankButton}>
				{(thumbnailUrl)
					? <VideoPreviewSlim disablePlayIcon className={classes.videoImage} videoUrl={thumbnailUrl} />
					: <Box className={classes.blankPreview}>
						<IconAdminCourses />
					</Box>
				}
			</Box>
			<Box>
				<Box className={classes.itemDetailsContainer}>
					<Typography className={classes.title} variant="h4">{course.title}</Typography>
				</Box>
			</Box>
		</Box>
	)
}