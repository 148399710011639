import {MarkQuizActionProvider, QuizQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../../components/pageBase/centeredContainer";
import {QuizQuestionContainer} from "./QuizQuestion/QuizQuestionContainer";
import {getAllFontStyles} from "../../../../templates/defaultTheme";
import {Box, LinearProgress, Typography} from "@mui/material";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {IFile, IQuiz} from "@plumeuk/shapeshift-types";

interface IProps {
	quiz?: IQuiz
}

const useStyles = makeStyles<{bannerImageUrl?: string}>()((theme, {bannerImageUrl}) => ({
	centeredContainer: {
		padding: "0 20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center"
	},
	centerTileContainer: {
		width: "100%",
		maxWidth: "740px",
		margin: "auto",
		boxSizing: "border-box"
	},
	centerTile: {
		position: "relative",
		borderRadius: "10px",
		boxSizing: "border-box",
		background: "#fff",
		width: "100%",
		padding: "70px",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 25px 40px 25px"
		}
	},
	title: {
		fontSize: "60px",
		fontWeight: 600,
		marginBottom: "20px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "20px",
			"& h2": {
				fontSize: "30px"
			},
			"& h5": {
				fontSize: "20px"
			}
		}
	},
	continueBtn: {
		marginTop: "30px",
		width: "127px",
		height: "52px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	content: {
		...getAllFontStyles(theme)
	},
	contentImage: {
		maxWidth: "100%"
	},
	flashIcon: {
		marginLeft: "-30px",
		backgroundColor: theme.palette.secondary.main,
		color:  theme.palette.secondary.main,
		strokeWidth: "2px",
		stroke: "#fff",
		fontSize: "30px",
		padding: "15px",
		borderRadius: "18px",
		position: "absolute",
		top: "-30px"
	},
	wrapper: {
		background: theme.palette.background.paper,
		...(bannerImageUrl ? {backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${bannerImageUrl})`} : {}),
		position: "relative",
		padding: "100px 60px",
		[theme.breakpoints.down("md")]: {
			padding: "100px 20px"
		},
		[theme.breakpoints.down("sm")]: {
			margin: "0 -60px 0 -60px",
			padding: "100px 81px 47px 81px"
		},
		textAlign: "left"
	},
	subtitle: {
		fontSize: "15px",
		fontWeight: 600,
		lineHeight: "1.2",
		padding: "0 7px",
		color: theme.palette.common.white,
		textTransform: "uppercase",
		marginBottom: "10px",
		height: "16px",
		borderLeft: "5px solid " + theme.palette.primary.main
	}
}));

export const QuizQuestions: FC<IProps> = ({quiz}) => {
	const {courseSlug, moduleSlug: quizSlug} = useParams();
	const bannerImage: IFile = (quiz as any)?.bannerImage;
	const bannerImageUrl = bannerImage?.formats?.large?.url ?? bannerImage?.url;
	const {classes} = useStyles({bannerImageUrl});

	if(!quiz)
		return <LinearProgress />



	return (
		<>
			<Box className={classes.wrapper}>
				{quiz?.subtitle && <Typography className={classes.subtitle}>{quiz?.subtitle}</Typography>}
				<Typography variant="h1" className={classes.title}>{quiz?.title}</Typography>
				<WYSIWYG className={classes.content}>
					{quiz?.content ?? ""}
				</WYSIWYG>
			</Box>

			<CenteredContainer className={classes.centeredContainer}>
				<MarkQuizActionProvider>
					{({markAnswers, response}) =>
						<QuizQuestionsDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
							{({quizQuestions, apiResult}) =>
								apiResult.isLoading
									? <LinearProgress />
									: <QuizQuestionContainer
										questions={quizQuestions}
										onSubmit={(answers) => markAnswers(courseSlug as string, quizSlug as string, answers)}
										submitResponseResultId={response}
									/>
							}
						</QuizQuestionsDataProvider>
					}
				</MarkQuizActionProvider>
			</CenteredContainer>
		</>

	);
}