import {makeStyles} from "tss-react/mui";
import {FC} from "react";
import {LinearProgress, Box, BoxProps} from "@mui/material";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import LockRounded from "@mui/icons-material/LockRounded";
import {palette} from "../../constants";

interface IVideoPreviewProps extends BoxProps{
	videoUrl: string;
	progress?: number;
	isLocked?: boolean;
	duration?: string;
	disablePlayIcon?: boolean
}

const useStyles = makeStyles()((theme) => ({
	videoPreview: {
		borderRadius: "5px",
		overflow: "hidden",
		"& path": {
			fill: palette.orange
		},
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		flexGrow: 1,
		justifyContent: "center"
	},
	previewImage: {
		position: "relative",
		overflow: "hidden",
		width: "90px",
		height: "70px"
	},
	progressBar: {
		marginTop: "2px",
		height: "5px",
		width: "100%",
		backgroundColor: `${theme.palette.common.white}`
	},
	previewOverlay: {
		position: "relative",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		svg: {
			position: "absolute",
			left: 8,
			bottom: 8,
			fill: `${theme.palette.primary}`,
			width: "40px",
			height: "40px"
		}
	},
	duration: {
		position: "absolute",
		bottom: "4px",
		right: "4px",
		backgroundColor: `${theme.palette.common.white}`,
		padding: "2px 3px",
		fontSize: "8px",
		lineHeight: "8px"
	},
	videoImage: {
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		width: "90px",
		height: "70px"
	}
}));

export const VideoPreviewSlim: FC<IVideoPreviewProps> = ({videoUrl, progress, disablePlayIcon, isLocked, duration, className, ...props}) => {

	const {classes, cx} = useStyles();

	return (
		<Box className={cx(classes.videoPreview, className)} {...props}>
			<Box className={classes.previewImage}>
				{videoUrl && <Box className={classes.videoImage} sx={{backgroundImage:`url(${videoUrl})`}}></Box>}
				<Box className={classes.previewOverlay}>
					{isLocked ? <LockRounded /> : (!disablePlayIcon ? <PlayCircleFilled /> : <></>)}
				</Box>
				{duration && <Box className={classes.duration}>{duration}</Box>}
			</Box>
			{progress !== undefined && <LinearProgress value={progress ?? 0} variant="determinate" className={classes.progressBar} />}
		</Box>
	);
};

export default VideoPreviewSlim