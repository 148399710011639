import {Box, Button, Typography} from "@mui/material";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MarkedQuestion, MarkedQuestionsSummary} from "@plumeuk/shapeshift-common/quiz";
import {FC, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {useNavigate, useParams} from "react-router-dom";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";
import {palette} from "../../../../../constants";
import {CurriculumContext} from "../../../../../contexts/curriculumContext";

const useStyles = makeStyles()((theme) => ({
	quizResult: {
		maxWidth: "1000px",
		margin: "40px auto 60px auto",
		textAlign: "left",
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px"
		},
		"[class*='answerCounter']": {
			height: "36px",
			background: palette.grey10,
			boxSizing: "border-box",
			justifyContent: "center",
			display: "flex",
			flexDirection: "column",
			fontWeight: 600,
			fontSize: "14px"
		},
		"[class*='questionCounter']": {
			fontFamily: "Titillium Web,sans-serif",
			color: theme.palette.common.white

		},
		"[class*='questionOption']": {
			boxSizing: "border-box",
			// background: "inherit",
			border: palette.grey20 + " 1px solid",
			"[class*='childrenContainer']": {
				padding: "5px 13px"
			},
			"[class*='optionId']": {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				textAlign: "center",
				height: "34px",
				background: palette.grey10,
				fontSize: "14px",
				fontWeight: 600,
				boxSizing: "border-box",
				width: "34px",
				borderRadius: "5px",
				border: palette.grey20 + " 1px solid"
			}
		}

	},
	markedAnswer: {
		marginBottom: "60px"

	},
	label: {
		lineHeight: 0,
		fontSize: "16px",
		marginBottom: "40px",
		marginTop: "100px",
		color: theme.palette.common.white,
		"& span": {
			color: theme.palette.primary.main
		}
	},
	title: {
		margin: "60px 0 20px 0"
	},
	subTitle: {
		marginBottom: "40px",
		color: theme.palette.common.white

	},
	nextBtn: {
		height: "52px"
	},
	markedQuestionsSummary: {
		"[class*='container']": {
			background: "#373737"
		}
	}
}));

interface IProps {
	quiz?: IQuiz,
	markedAnswers: IMarkedQuizAnswers
}

export const QuizResultBreakdown: FC<IProps> = ({quiz, markedAnswers}) => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const mq = useMq();
	const navigate = useNavigate();
	const {curriculumDispatch} = useContext(CurriculumContext);

	useEffect(() => {
		if(!markedAnswers) return;
		if(markedAnswers.pass){
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "quiz", slug: quiz?.slug as string}, status: true})
		}
	}, [markedAnswers])

	return (
		<Box className={classes.quizResult}>
			<MarkedQuestionsSummary className={classes.markedQuestionsSummary} markedAnswers={markedAnswers}/>
			{!mq.mobile ? <>
				<Typography variant="h1" className={classes.title}>{quiz?.title}</Typography>
				<Typography variant="h4" className={classes.subTitle}>Pass by scoring {markedAnswers.requiredScore}%</Typography>
			</> : <Box sx={{marginBottom: "40px"}}/>}
			{markedAnswers?.markedAnswers?.map((answer, i) => (
				<Box key={`marked-answer-${i}`} className={classes.markedAnswer}>
					<Typography className={classes.label}>Question <span>{i+1}</span> of <span>{markedAnswers.markedAnswers.length}</span></Typography>
					<MarkedQuestion markedAnswer={answer}/>
				</Box>
			))}
			<Button
				onClick={() => markedAnswers.pass ? navigate("/course/" + courseSlug) : navigate("../active")}
				className={classes.nextBtn}
			>
				{markedAnswers.pass ? "Next module" : "Retake"}
			</Button>
		</Box>
	);
}