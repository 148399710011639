import React, {forwardRef, ForwardedRef} from "react";
import {Draggable} from "react-beautiful-dnd";
import {IFlatList} from "@plumeuk/shapeshift-types";
import {Box, BoxProps, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ArrowUpward, Delete, DragIndicator} from "@mui/icons-material";
import {DraftIndicator, ErrorIndicator} from "../../../../../components/admin/indicator";
import {palette} from "../../../../../constants";

export interface ICustomProps {
	module: IFlatList,
	courseSlug: string,
	isDraft: () => boolean,
	isMissing?: boolean,
	index: number,
	indentStepSize?: number,
	level?: number,
	previous?: IFlatList,
	hasChildren?: boolean
	onAccordianChange: (open: boolean) => void,
	onProgressionChange: (value: boolean) => void,
	isCollapsed?: boolean,
	onDelete: () => void,
	unsavedChanges?: boolean,
}

export type IProps = ICustomProps & BoxProps;

interface IStyleProps {
	indentStepSize: number,
	module: IFlatList,
	hasChildren?: boolean,
	isCollapsed?: boolean
}

const useStyles = makeStyles<IStyleProps>()(
	(theme, {module, isCollapsed, indentStepSize}) => ({
		wrapper: {
			transition: "all .3s ease-out",
			marginLeft: (module.level * indentStepSize) + "px",
			maxHeight: isCollapsed ? "0" : "80px",
			padding: isCollapsed ? "0" : "5px",
			opacity: isCollapsed ? 0 : 1
		},
		dragIcon: {
			"& path": {
				fill: theme.palette.text.primary
			}
		},
		module: {
			position: "relative",
			display: "flex",
			borderRadius: "4px",
			border: "1px solid " + palette.grey15,
			gap: "5px",
			"& > *": {
				padding: "20px 0 20px 10px"
			}
		},
		levelControls: {
			display: "flex",
			height: "58px",
			width: "200px",
			padding: "0 0 0 160px",
			gap: "5px",
			flexDirection: "column",
			justifyContent: "center",
			opacity: 0,
			transition: "all ease-out 0.2s",
			"&:hover": {
				opacity: 1
			},
			position: "absolute",
			left: "-200px",
			fontSize: "20px",
			"& > *": {
				cursor: "pointer",
				opacity: 0.5,
				"&:hover": {
					opacity: 1
				}
			},
			"& svg path": {
				fill: theme.palette.text.primary
			},
			"& svg": {
				width: "40px",
				height: "40px"
			}
		},
		moduleContainer: {
			gap: "5px"
		},
		title: {
			flexGrow: 2,
			textTransform: "capitalize",
			paddingLeft: 0,
			"& a": {
				cursor: "pointer",
				color: "inherit",
				textDecoration: "none"
			}
		},
		typeLabel: {
			fill: theme.palette.text.primary,
			fontWeight: 500,
			marginRight: "10px"
		},
		sectionNoChildrenLabel: {
			fill: theme.palette.text.primary,
			fontWeight: 500,
			marginLeft: "20px",
			fontStyle: "italic",
			float: "right",
			fontSize: "12px"
		},
		actionIconContainer: {
			display: "flex",
			"& > *": {
				opacity: 0.3,
				cursor: "pointer",
				transition: "all .3s ease-out",
				transformOrigin: "center",
				textAlign: "center",
				paddingRight: "20px"

			},
			"& > *: hover": {
				opacity: 1
			},
			"& > *: hover svg": {
				transform: "scale(1.3)"
			},
			"& svg": {
				transformOrigin: "center",
				transition: "transform .3s ease-out",
				"& path": {
					fill: theme.palette.text.primary
				}
			}
		},
		accordianIcon: {
			opacity: 1,
			"&:hover svg": {
				transform: `scale(1.3) rotate(${module.collapsedChildren ? 0 : 180}deg)`
			},
			"& svg": {
				transform: `rotate(${module.collapsedChildren ? 0 : 180}deg)`
			}
		},
		sectionNoChildren: {
			transition: "all .3s ease-out",
			overflow: "hidden",
			boxSizing: "border-box",
			color: "grey",
			fontSize: "12px",
			marginLeft: ((module.level) * indentStepSize) + "px",
			height: !module?.collapsedChildren ? "30px" : "0px",
			paddingTop: !module?.collapsedChildren ? "10px" : "0px"
		},
		draftIconContainer: {
			width: "35px",
			marginLeft: "4px",
			display: "inline-block"
		}
	})
);

const CourseCurriculumModule = forwardRef<HTMLDivElement, IProps>(
	(
		{
			indentStepSize, previous, module, isDraft, index: i,
			hasChildren, onAccordianChange, isCollapsed,
			onDelete, isMissing, onProgressionChange, courseSlug,
			unsavedChanges,
			...props
		}: IProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const isSection = module.type === "section";
		const {classes} = useStyles({module, hasChildren, isCollapsed, indentStepSize: indentStepSize ?? 15});

		const getTypeEditLink = (contentType: string, id: number): string =>
			`/admin/content-manager/collectionType/${contentType}/${id}`;

		const getTypeName = (): string => {
			const typeArr = module.type.split(".");
			return typeArr[typeArr.length - 1];
		}

		const getModuleLink = (): string | null => {
			const base = process.env.STRAPI_ADMIN_APP_MODULE_REDIRECT;
			if(!base){
				// eslint-disable-next-line no-console
				console.error("Failed to copy link, MODULE_REDIRECT env not set")
				return null;
			}
			return base
				.replace("[COURSE_SLUG]", courseSlug)
				.replace("[MODULE_TYPE]", module.type.split(".").pop() ?? "")
				.replace("[MODULE_SLUG]", module.slug)
		}

		const handleEditLink = (e: React.MouseEvent<HTMLElement>, url: string): void => {
			e.preventDefault();
			if (!unsavedChanges || confirm("Are you sure you want to leave this page? All your modifications will be lost")) {
				window.location.href = url;
			}
		}
		const canLevelUp = previous && previous.level >= module.level;
		const canLevelDown = module.level > 0;

		//currently unsupported
		if(isSection)
			return <></>

		return (
			<Draggable draggableId={module.dragId} key={module.dragId} index={i}>
				{provided => (
					<section {...provided.draggableProps} ref={provided.innerRef}>
						<Box className={classes.wrapper} {...props} ref={ref} >
							<Box className={classes.module}>
								<div {...provided.dragHandleProps}><DragIndicator className={classes.dragIcon} /></div>
								{isMissing
									? <ErrorIndicator isError={true} tooltipDescription="Failed to match module! Please remove and re-import if necessary."/>
									: <DraftIndicator isDraft={isDraft()} />
								}
								<Typography className={classes.title}>
									<span className={classes.typeLabel}>

										{getTypeName()}:
									</span>
									{!isSection && <a aria-label="Edit module" href="#" onClick={(e) => handleEditLink(e, module.moduleId ? getTypeEditLink(module.type, module.moduleId) : "")}>{module.title}</a>}
									{isSection && module.title}
								</Typography>
								<Box className={classes.actionIconContainer}>
									{(hasChildren || isSection) &&
										<Box
											className={classes.accordianIcon}
											onClick={() => onAccordianChange(module?.collapsedChildren ?? false)}
											data-test-id={module.dragId + "-curriculum-module-accordian-btn"}
										>
											<ArrowUpward />
										</Box>
									}
									<Box>
										<Delete data-test-id={module.dragId + "-curriculum-module-trash"} onClick={() => onDelete()} />
									</Box>
								</Box>
							</Box>
						</Box>
						{isSection && !hasChildren &&
							<Box className={classes.sectionNoChildren}>
								No modules assigned!
							</Box>
						}
					</section>
				)}
			</Draggable>
		)
	});

CourseCurriculumModule.displayName = "CourseCurriculumModule";

export default CourseCurriculumModule;