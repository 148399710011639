import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconSearch = createSvgIcon(
	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_935_11084)">
			<path d="M9.107 16.7677C13.3379 16.7677 16.7677 13.3379 16.7677 9.107C16.7677 4.8761 13.3379 1.44629 9.107 1.44629C4.8761 1.44629 1.44629 4.8761 1.44629 9.107C1.44629 13.3379 4.8761 16.7677 9.107 16.7677Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M19.5537 19.5532L14.6787 14.6782" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_935_11084">
				<rect width="21" height="21" fill="white"/>
			</clipPath>
		</defs>
	</svg>,
	"Search",
	"0 0 21 21"
);