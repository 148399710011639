import React, {forwardRef, ForwardedRef} from "react";
import {Draggable} from "react-beautiful-dnd";
import {IFlatList, IQuizBuilderQuestion} from "@plumeuk/shapeshift-types";
import {Box, BoxProps, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Delete, DragHandle} from "@mui/icons-material";
import {ErrorIndicator} from "../../../../../components/admin/indicator";
import {QuestionMap} from "../../../../../types/admin";

export interface ICustomProps {
	question: IQuizBuilderQuestion,
	questionMap: QuestionMap,
	index: number,
	previous?: IFlatList,
	onDelete: () => void
}

export type IProps = ICustomProps & BoxProps;

const useStyles = makeStyles()(
	(theme) => ({
		wrapper: {
			transition: "all .3s ease-out",
			maxHeight: "80px",
			padding: "5px",
			opacity: 1
		},
		dragIcon: {
			"& path": {
				fill: theme.palette.grey[600]
			}
		},
		module: {
			position: "relative",
			display: "flex",
			borderRadius: "4px",
			border: "1px solid " + theme.palette.grey[500],
			gap: "5px",
			"& > *": {
				padding: "20px 0 20px 10px"
			}
		},
		title: {
			width: "80%",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			flexGrow: 2,
			textTransform: "capitalize",
			paddingLeft: 0,
			"& a": {
				whiteSpace: "nowrap",
				cursor: "pointer",
				color: "inherit",
				textDecoration: "none"
			}
		},
		typeLabel: {
			color: theme.palette.grey[300],
			fontWeight: 500,
			marginRight: "10px"
		},
		actionIconContainer: {
			display: "flex",
			"& > *": {
				opacity: 0.3,
				cursor: "pointer",
				transition: "all .3s ease-out",
				transformOrigin: "center",
				textAlign: "center",
				paddingRight: "20px"

			},
			"& > *: hover": {
				opacity: 1
			},
			"& > *: hover svg": {
				transform: "scale(1.3)"
			},
			"& svg": {
				transformOrigin: "center",
				transition: "transform .3s ease-out",
				"& path": {
					fill: theme.palette.grey[300]
				}
			}
		},
		draftIconContainer: {
			width: "35px",
			marginLeft: "4px",
			display: "inline-block"
		}
	})
);

const QuizBuilderQuestion = forwardRef<HTMLDivElement, IProps>(
	(
		{
			question, index: i,
			onDelete, questionMap, ...props
		}: IProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {

		const {classes} = useStyles();
		const isMissing = !!(question.id && !questionMap?.[question.__type ?? ""]?.[question.id])

		const getEditLink = (contentType: string, id: number): string =>
			`/admin/quizzes/question/${contentType.split(".").pop()}/${id}`;

		const getTypeName = (): string =>
			question.__type
				?.split(".")?.pop()
				?.replaceAll("-", " ")?.replace("question", "") ?? "";

		return (
			<Draggable draggableId={question.dragId} key={question.dragId} index={i}>
				{provided => (
					<section {...provided.draggableProps} ref={provided.innerRef}>
						<Box className={classes.wrapper} {...props} ref={ref} >
							<Box className={classes.module}>
								<div {...provided.dragHandleProps}><DragHandle className={classes.dragIcon} /></div>
								{isMissing
									&& <ErrorIndicator isError={true} tooltipDescription="Failed to match question! Please remove and re-import if necessary."/>
								}
								<Typography className={classes.title}>
									<span className={classes.typeLabel}>

										{getTypeName()}:
									</span>
									{!isMissing && <a href={question.id ? getEditLink(question.__type, question.id) : ""}>{questionMap[question.__type][question.id].title}</a>}
								</Typography>
								<Box className={classes.actionIconContainer}>
									<Box>
										<Delete data-test-id={question.dragId + "-quiz-builder-question-trash"} onClick={() => onDelete()} />
									</Box>
								</Box>
							</Box>
						</Box>
					</section>
				)}
			</Draggable>
		)
	});

QuizBuilderQuestion.displayName = "QuizBuilderQuestion";

export default QuizBuilderQuestion;
