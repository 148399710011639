import {MarkQuizActionProvider, QuizQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {QuizQuestionContainer} from "../QuizPage/QuizQuestions/QuizQuestion/QuizQuestionContainer";
import {IQuizAnswer, IQuizQuestion} from "@plumeuk/shapeshift-types";
import {LinearProgress} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center"
	},
	centerTileContainer: {
		width: "100%",
		maxWidth: "740px",
		margin: "auto",
		boxSizing: "border-box"
	},
	centerTile: {
		position: "relative",
		borderRadius: "10px",
		boxSizing: "border-box",
		background: "#fff",
		width: "100%",
		padding: "70px",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 25px 40px 25px"
		}
	},
	title: {
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "20px",
			"& h2": {
				fontSize: "30px"
			},
			"& h5": {
				fontSize: "20px"
			}
		}
	},
	continueBtn: {
		marginTop: "30px",
		width: "127px",
		height: "52px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	content: {
	},
	contentImage: {
		maxWidth: "100%"
	},
	flashIcon: {
		marginLeft: "-30px",
		backgroundColor: theme.palette.secondary.main,
		color:  theme.palette.secondary.main,
		strokeWidth: "2px",
		stroke: "#fff",
		fontSize: "30px",
		padding: "15px",
		borderRadius: "18px",
		position: "absolute",
		top: "-30px"
	}
}));

interface IProps {
	courseSlug?: string,
	quizSlug?: string,
	markAnswersActionOverride?: (answers: IQuizAnswer[]) => true,
	quizQuestionDataOverride?: IQuizQuestion[]
}


export const MiniQuizzerQuestions: FC<IProps> = (props) => {
	const {classes} = useStyles();
	const params = useParams();
	const courseSlug = props.courseSlug ?? params.courseSlug;
	const quizSlug = props.quizSlug ?? params.moduleSlug;

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<MarkQuizActionProvider>
				{({markAnswers, response, apiResult: markAnswersApiResult}) =>
					<QuizQuestionsDataProvider courseSlug={courseSlug} quizSlug={quizSlug} data={props.quizQuestionDataOverride}>
						{({quizQuestions, apiResult}) => <>
							{apiResult.isLoading && <LinearProgress />}
							{!markAnswersApiResult.isLoading && !apiResult.isLoading && !apiResult.isError && <QuizQuestionContainer
								questions={quizQuestions}
								onSubmit={(answers) => props.markAnswersActionOverride ? props.markAnswersActionOverride?.(answers) : markAnswers(courseSlug as string, quizSlug as string, answers)}
								submitResponseResultId={response}
							/>}
							{markAnswersApiResult.isLoading && <LinearProgress />}
						</>
						}
					</QuizQuestionsDataProvider>
				}
			</MarkQuizActionProvider>
		</CenteredContainer>
	);
}