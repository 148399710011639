import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {IBooleanQuestionEntity, IQuestionEntity, corePaths, IBooleanQuestionEntityPayload} from "@plumeuk/shapeshift-types";
import {useParams} from "react-router-dom";
import {AdminEntityPageContainer, AdminInputText, AdminInputSwitch} from "@plumeuk/shapeshift-common/admin";


interface IProps {
	onSave?: (e: Pick<IQuestionEntity, "id" | "__type" | "title">) => void
}

interface IStyleProps {
	saveRequired?: boolean
}

const useStyles = makeStyles()((theme) => ({
	adminQuizQuestionBooleanPage: {

	}
}));

const template: IBooleanQuestionEntityPayload = {
	title: "", answer: false, hint: ""
}

export const AdminQuizBooleanQuestionPage: FC<IProps> = ({onSave}) => {
	const {classes} = useStyles();
	const {questionId} = useParams();

	const IQuestionEntityToIQuestionEntityPayload = (e: IBooleanQuestionEntity): IBooleanQuestionEntityPayload => {
		const {title, answer, content, id, updatedBy, createdBy, createdAt, updatedAt, hint} = e
		const payload = {
			title, answer, content, id, updatedBy, createdBy, createdAt, updatedAt, hint
		}
		return payload;
	}

	return (
		<Box className={classes.adminQuizQuestionBooleanPage}>
			<AdminEntityPageContainer<IBooleanQuestionEntity, "questionBoolean">
				entityToPayload={IQuestionEntityToIQuestionEntityPayload}
				entityId={questionId ? parseInt(questionId) : undefined}
				template={template}
				type="questionBoolean"
				onSaveResponse={e => (!e.isLoading && e.data) && onSave?.({...e.data, __type: corePaths["questionBoolean"]})}
			>
				{({formData, setFormData, locale}) => <>
					<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Title"}/>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, hint: e.target.value}))} value={formData.hint} label={"hint"}/>
					{locale === "en" && <AdminInputSwitch onChange={e => setFormData(prev => ({...prev, answer: e.target.checked}))} checked={formData.answer} label={"Answer"}/>}
				</>}
			</AdminEntityPageContainer>
		</Box>
	);
}