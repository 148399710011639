import {FC, useState} from "react";
import {IMultipleChoiceQuestionEntity, IQuestionEntity, ISingleChoiceQuestionEntity, corePaths} from "@plumeuk/shapeshift-types";
import {Box, BoxProps} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import QuestionTypeSelect from "./QuestionTypeSelect";
import {IBooleanQuestionEntity} from "@plumeuk/shapeshift-types/entities";
import AdminStringList from "../../../AdminStringList/AdminStringList";
import {AdminEntityPageContainer, AdminInputText, AdminInputSwitch, AdminFieldContainer} from "@plumeuk/shapeshift-common/admin";

export interface ICustomProps {
	onSubmit: (e: Pick<IQuestionEntity, "id" | "__type" | "title">) => void
}

export type IProps = ICustomProps & Omit<BoxProps, "onSubmit">

const useStyles = makeStyles()(
	(_theme) => ({
		courseCurriculumNewModuleForm: {
			borderRadius: "10px",
			position: "relative"
		},
		loading: {
			position: "absolute",
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			textAlign: "center"
		},
		createBtn: {
			position: "absolute",
			right: "50px",
			top: "100px"
		}
	})
);

const QuizBuilderNewQuestionForm: FC<IProps> = ({onSubmit, ...boxProps}) => {
	const {classes} = useStyles();
	const [questionType, setQuestionType] = useState<string>();

	return (
		<Box className={classes.courseCurriculumNewModuleForm} {...boxProps}>
			<QuestionTypeSelect
				autoSelectFirst
				filter={e => e === corePaths.questionBoolean || e === corePaths.questionSingleChoice || e === corePaths.questionMultipleChoice}
				value={questionType}
				onChange={e => setQuestionType(e)}
			/>
			<Box padding={2}></Box>
			{questionType === corePaths.questionBoolean && <Box>
				<AdminEntityPageContainer<IBooleanQuestionEntity, "questionBoolean">
					entityToPayload={() => ({})}
					template={{title: "", hint: "", answer: false}}
					clearOnSave
					type="questionBoolean"
					onSaveResponse={e => (!e.isLoading && e.data) && onSubmit({...e.data, __type: corePaths["questionBoolean"]})}
				>
					{({formData, setFormData}) => <>
						{JSON.stringify(formData)}
						<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Title"}/>
						<AdminInputText onChange={e => setFormData(prev => ({...prev, hint: e.target.value}))} value={formData.hint} label={"hint"}/>
						<AdminInputSwitch leftLabel="False" rightLabel="True" onChange={e => setFormData(prev => ({...prev, answer: e.target.checked}))} checked={formData.answer} label={"Answer"}/>
					</>}
				</AdminEntityPageContainer>
			</Box>}
			{questionType === corePaths.questionSingleChoice && <Box>
				<AdminEntityPageContainer<ISingleChoiceQuestionEntity, "questionSingleChoice">
					entityToPayload={() => ({})}
					template={{title: "", answer: "", options: [], hint: ""}}
					clearOnSave
					type="questionSingleChoice"
					onSaveResponse={e => (!e.isLoading && e.data) && onSubmit({...e.data, __type: corePaths["questionSingleChoice"]})}
				>
					{({formData, setFormData}) => <>
						<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Title"}/>
						<AdminInputText onChange={e => setFormData(prev => ({...prev, hint: e.target.value}))} value={formData.hint} label={"Hint"}/>
						<AdminFieldContainer required label="Options"><AdminStringList defaultMessage="" allowDuplicates={false} value={formData.options ?? []} onChange={e => setFormData(prev => ({...prev, options: e}))}/></AdminFieldContainer>
						<AdminInputText required onChange={e => setFormData(prev => ({...prev, answer: e.target.value}))} value={formData.answer} label={"Correct Answer"}/>
					</>}
				</AdminEntityPageContainer>
			</Box>}
			{questionType === corePaths.questionMultipleChoice && <Box>
				<AdminEntityPageContainer<IMultipleChoiceQuestionEntity, "questionMultipleChoice">
					entityToPayload={() => ({})}
					template={{title: "", answers: [], options: [], hint: ""}}
					clearOnSave
					type="questionMultipleChoice"
					onSaveResponse={e => (!e.isLoading && e.data) && onSubmit({...e.data, __type: corePaths["questionMultipleChoice"]})}
				>
					{({formData, setFormData}) => <>
						<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Title"}/>
						<AdminInputText onChange={e => setFormData(prev => ({...prev, hint: e.target.value}))} value={formData.hint} label={"Hint"}/>
						<AdminFieldContainer required label="All Options"><AdminStringList defaultMessage="" allowDuplicates={false} value={formData.options ?? []} onChange={e => setFormData(prev => ({...prev, options: e}))}/></AdminFieldContainer>
						<AdminFieldContainer required label="Corrrect Answers"><AdminStringList defaultMessage="" allowDuplicates={false} value={formData.answers ?? []} onChange={e => setFormData(prev => ({...prev, answers: e}))}/></AdminFieldContainer>
					</>}
				</AdminEntityPageContainer>
			</Box>}
		</Box>
	)
}

export default QuizBuilderNewQuestionForm;