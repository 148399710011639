import {Box, BoxProps, LinearProgress, Typography} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {ICourseCurriculum} from "@plumeuk/shapeshift-types";
import {palette} from "../../constants";


interface IPropsCustom {
	course?: ICourseCurriculum
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	courseProgressionSummary: {
		marginBottom: "25px",
		overflow: "hidden"
	},
	courseTitle: {
		color: "white",
		marginBottom: "10px",
		fontSize: "16px",
		fontWeight: 600,
		borderBottom: "1px solid " + palette.grey10,
		paddingBottom: "15px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "18px"
		}
	},
	progressContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		color: "white"
	},
	percentLabel: {
		display: "inline-block"
	},
	progressBar: {
		marginRight: "15px",
		marginBottom: "10px",
		display: "inline-block",
		padding: 0,
		border: "none",
		width: "100%"
	}
}));

export const CourseProgressionSummary: FC<IProps> = ({className, course, ...props}) => {
	const {classes, cx} = useStyles();

	if(!course) return <LinearProgress />
	return (
		<Box className={cx(classes.courseProgressionSummary, className)} {...props}>
			<Typography className={classes.courseTitle}>{course.title}</Typography>
			<Box className={classes.progressContainer}>
				<Typography className={classes.percentLabel} variant="body1">{course.percentCompleted}% completed</Typography>
				<LinearProgress variant="determinate" className={classes.progressBar} value={course.percentCompleted}/>
			</Box>
		</Box>
	);
};

export default CourseProgressionSummary;
