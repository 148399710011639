import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {App, URLOpenListenerEvent} from "@capacitor/app";

const AppUrlListener: React.FC<any> = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const handleAppUrlOpen = (event: URLOpenListenerEvent): void => {
			// Example url: https://dev.buildwitt.plume.co.uk/tabs/tab2
			// slug = /tabs/tab2
			const slug = event.url.split(".com").pop();
			if (slug) {
				navigate(slug);
			}
			// If no match, do nothing - let regular routing
			// logic take over
		};

		App.addListener("appUrlOpen", handleAppUrlOpen);

		// Cleanup listener on component unmount
		return () => {
			// App.removeAllListeners();
		};
	}, [navigate]);

	return null;
};

export default AppUrlListener;
