import React, {useCallback, useEffect, useRef, useState} from "react";
import {useApi} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import {Typography, Button, Box, CircularProgress, LinearProgress} from "@mui/material";
import {Modal} from "@plumeuk/shapeshift-common/modal";
import {BITMOVIN_VIDEO_DATA} from "../../../../../types/admin";
import {formatTimeSecondsApprox} from "@plumeuk/shapeshift-common/common";
import {FileDropzone} from "@plumeuk/shapeshift-common/fileDropzone";
import {IDropzoneFile} from "@plumeuk/shapeshift-common/fileDropzone/fileDropzone";
import axios from "axios";

/* eslint-disable no-console */

export interface IProps {
	lessonId?: number,
	value?: BITMOVIN_VIDEO_DATA,
	open?: boolean,
	onClose: (e?: BITMOVIN_VIDEO_DATA) => void,
}

const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024; // 10GB in bytes

const useStyles = makeStyles()(
	(theme) => ({
		videoUploadModal: {},
		container: {
			display: "flex",
			gap: "40px",
			flexDirection: "column"
		},
		body: {},
		header: {
			display: "flex",
			justifyContent: "space-between !important"
		},
		title: {
			flexGrow: 2
		},
		fullScreen: {
			paddingRight: "20px",
			cursor: "pointer",
			"& span": {
				marginLeft: "10px"
			}
		},
		uploadFeedback: {
			display: "flex",
			gap: "15px",
			flexDirection: "column",
			alignItems: "left",
			marginTop: "3px"
		},
		errorMessage: {
			color: theme.palette.error.main
		},
		dropFile: {
			"*": {
				color: theme.palette.common.white
			},
			"[class*='inputContainer']": {
				padding: "15px"

			}
		}
	})
);


const UploadVideoModal: React.FunctionComponent<IProps> = ({
	open,
	lessonId,
	onClose
}) => {
	const {classes} = useStyles();
	const [fileUploadResponse, setFileUploadResponse] = useState<{abortController?: AbortController, isError: boolean, statusCode?: number, data?: BITMOVIN_VIDEO_DATA, isLoading: boolean}>({isError: false, isLoading: false});
	const [fileUploadRequestResponse, fileUploadRequest] = useApi<{uploadUrl: string, assetName: string, containerName: string, mediaType: BITMOVIN_VIDEO_DATA["mediaType"]}>();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [uploadProgress, setUploadProgress] = useState<number | null>(null);
	const [uploadSpeed, setUploadSpeed] = useState<number | null>(null);
	const [estimatedTime, setEstimatedTime] = useState<string | null>(null);
	const [file, setFile] = useState<IDropzoneFile>();
	const [fileSize, setFileSize] = useState<number | null>(null);
	const [bytesUploaded, setBytesUploaded] = useState<number | null>(null);
	const progressTimeoutRef = useRef<number | null>(null);

	useEffect(() => {
		if (fileUploadResponse.isError) {
			setErrorMessage(`Upload failed. Status: ${fileUploadResponse.statusCode}`);
		} else if (fileUploadResponse.data) {
			setErrorMessage(null); // Clear error message on successful upload
		}
	}, [fileUploadResponse]);

	useEffect(() => {
		if(file?.file)
			handleChange(file?.file)
	}, [file])

	const handleChange = useCallback(
		(file: File): void => {
			if (!lessonId) {
				return;
			}

			if (!file) {
				setErrorMessage("No file selected");
				return;
			}

			if (file.size > MAX_FILE_SIZE) {
				setErrorMessage("File size exceeds 10GB limit.");

				return;
			}

			if(fileUploadRequestResponse.isLoading)
				return;

			setErrorMessage(null);
			setUploadProgress(0);
			setBytesUploaded(null)
			setEstimatedTime(null)
			setFileSize(file?.size)

			fileUploadRequest({
				url: "/api/lesson/video/upload/request",
				data: {
					mediaType: "video"
				},
				cache: false
			})
		},
		[lessonId, errorMessage]
	);

	useEffect(() => {
		if(!fileUploadRequestResponse.data || fileUploadRequestResponse.isLoading || !lessonId || !file?.file)
			return;

		const controller = new AbortController();
		setFileUploadResponse({isLoading: true, isError: false, abortController: controller, statusCode: undefined, data: undefined})
		axios.put(fileUploadRequestResponse.data.uploadUrl, file.file, {
			signal: controller.signal,
			headers: {
				"Content-Type": file.type, // Set the content type
				"x-ms-blob-type": "BlockBlob" // Required header for blob uploads
			},
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
				);
				setUploadProgress(percentCompleted);
				setEstimatedTime(formatTimeSecondsApprox(progressEvent.estimated ?? 0))
				setUploadSpeed((progressEvent.rate ?? 0))
				setBytesUploaded((progressEvent.loaded ?? 0))
				setFileSize((progressEvent.total ?? 0))

				// Reset progress timeout on progress update
				if (progressTimeoutRef.current) {
					clearTimeout(progressTimeoutRef.current);
				}
				if(percentCompleted !== 100)
					progressTimeoutRef.current = window.setTimeout(() => {
						handleProgressTimeout();
					}, 10000); // 10 seconds
			}
		}).then(e => {
			setFileUploadResponse({isError: false, isLoading: false, data: {
				blobName: fileUploadRequestResponse.data?.assetName ?? "",
				inputContainerName: fileUploadRequestResponse.data?.containerName ?? "",
				assetName: fileUploadRequestResponse.data?.assetName ?? "",
				mediaType: fileUploadRequestResponse.data?.mediaType ?? "video",
				status: "PENDING"
			}})
		});
	}, [fileUploadRequestResponse])

	const handleProgressTimeout = (): void => {
		if(errorMessage)
			return;

		setErrorMessage(prev => prev ? prev : "Upload seems to be stalled. Please try again.");
		setUploadProgress(null);
		setEstimatedTime(null)
		setUploadSpeed(null)
	};

	const handleClose = (): void => {
		setErrorMessage(null);
		setUploadProgress(null);
		setUploadSpeed(null);
		setEstimatedTime(null)
		onClose(fileUploadResponse.data);
	};

	useEffect(() => {
		if(errorMessage)
			setFile(undefined)
	}, [errorMessage])

	if (!open || !lessonId) return null;

	return (
		<Modal
			open={true}
			className={classes.videoUploadModal}
			onClose={handleClose}
			footerButtons={[
				<Button key={1} onClick={handleClose}>
					Cancel
				</Button>,
				(fileUploadResponse.data && !fileUploadResponse.isLoading) ? (
					<Button key={2} onClick={handleClose}>
						Attach file
					</Button>
				) : <></>
			]}
		>
			<Box className={classes.container}>
				<Box className={classes.header}>
					<Typography variant="h3" className={classes.title} id="title">
						Upload Video
					</Typography>
				</Box>
				<Box className={classes.body}>
					{(!file || !fileUploadResponse.isLoading) && <FileDropzone className={classes.dropFile} value={file ? [file]: []} onChange={e => setFile(e?.[e.length - 1])}/>}
					{fileUploadResponse.isLoading && <Typography sx={t => ({cursor: "pointer", padding: "0 0 15px 0", color: t.palette.primary.main})} onClick={() => {
						fileUploadResponse.abortController?.abort?.();
						setFileUploadResponse({isLoading: false, isError: false})
						setErrorMessage("Cancelled Upload")
					}}>
						Cancel Upload
					</Typography>}
					{(!errorMessage) && uploadProgress !== null && uploadProgress !== 100 && (
						<Box className={classes.uploadFeedback}>
							<Typography>Uploading: {uploadProgress}%</Typography>
							<LinearProgress sx={{width: "100%"}} variant="determinate" value={uploadProgress} />
							{uploadSpeed !== null && (
								<Typography>Speed: {(uploadSpeed / 1024 / 1024).toFixed(2)} MB/s</Typography>
							)}
							{estimatedTime && (
								<Typography>Estimated Time: {estimatedTime}</Typography>
							)}
						</Box>
					)}
					{fileUploadResponse.isLoading && uploadProgress === 100 && (
						<Box className={classes.uploadFeedback}>
							<Typography>Uploaded and processing, please wait...</Typography>
							<CircularProgress />
						</Box>
					)}
					{(fileSize && bytesUploaded) && (<Box sx={{paddingTop: "15px"}}>
						<Typography>Uploaded: {(bytesUploaded / 1024 / 1024).toFixed(2)}/ {(fileSize / 1024 / 1024).toFixed(2)} MB</Typography>
					</Box>)}
					{(errorMessage || fileUploadResponse.isError) && (
						<Typography className={classes.errorMessage}>
							{errorMessage || "Upload failed."}
						</Typography>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default UploadVideoModal;