import {FC} from "react";
import {PageBase} from "../../components/pageBase/pageBase";
import {CourseLibraryBanner} from "./CourseLibraryBanner";
import {makeStyles} from "tss-react/mui";
import LibraryContent from "./LibraryContent";
import {Box} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		[theme.breakpoints.up("lg")]:{
			"& [class*='menuIcon']": {
				display: "none"
			}
		},
		[theme.breakpoints.down("sm")]: {
		}
	},
	container: {
		[theme.breakpoints.up("sm")]:{
			padding: "0 0px"
		}
	},
	library: {
		paddingBottom: "70px",
		maxWidth: "1420px",
		margin: "auto"
	}
}));


export const CourseLibraryPage: FC = () => {
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} contentWidth="100%" disableSideBar>
			<Box className={classes.container}>
				<CourseLibraryBanner/>
				<LibraryContent
					className={classes.library}
				/>
			</Box>
		</PageBase>
	);
}