import {Box, Button, Typography} from "@mui/material";
import {useApi} from "@plumeuk/shapeshift-identity";
import {FC, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {ICourseLibraryBannerResponse} from "../../types/courseLibraryBanner";
import {Link} from "react-router-dom";
import {getAllFontStyles} from "../../templates/defaultTheme";

interface IStyleProps {
	loaded?: boolean,
	backgroundUrl?: string,
	desktopContentWidthPx?: number,
	bannerImageMinimumHeightPx?: number,
	mobileBannerImageMinimumHeightPx?: number,
}

const useStyles = makeStyles<IStyleProps>()((theme, {loaded, backgroundUrl, desktopContentWidthPx, mobileBannerImageMinimumHeightPx, bannerImageMinimumHeightPx}) => ({
	courseLibraryBanner: {
		display: "flex",
		flexDirection: "column",
		color: theme.palette.common.white,
		height: bannerImageMinimumHeightPx ?? "420px",
		background: backgroundUrl && `url('${backgroundUrl}') no-repeat`,
		backgroundSize: "cover",
		backgroundPosition: "center center",
		[theme.breakpoints.down("sm")]: {
			height: (mobileBannerImageMinimumHeightPx && loaded) ? `${mobileBannerImageMinimumHeightPx}px` : "150px"
		}
	},
	contentContainer: {
		maxWidth: desktopContentWidthPx,
		width: "100%",
		textAlign: "center",
		margin: "auto",
		[theme.breakpoints.down("sm")]: {
			padding: "0px"
		},
		[theme.breakpoints.up("sm")]: {
			padding: "50px"
		}
	},
	title: {
		fontSize: "82px",
		fontWeight: 700,
		marginTop: "-80px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "42px",
			marginTop: 0
		}
	},
	bannerContent: {
		...getAllFontStyles(theme)
	},
	btn: {
		marginTop: "20px"
	},
	courseLibraryBannerimg:{
		objectFit: "cover"
	}
}));

export const CourseLibraryBanner: FC = () => {
	const [{data: apiData}] = useApi<ICourseLibraryBannerResponse>("/api/course-library-banner?populate=backgroundImage");
	const bannerData = apiData?.data.attributes;
	const [backgroundUrl, setImageUrl] = useState<string>();
	const [loaded, setLoaded] = useState(false);

	const {classes} = useStyles({
		backgroundUrl: backgroundUrl,
		desktopContentWidthPx: bannerData?.desktopContentWidthPx,
		bannerImageMinimumHeightPx: bannerData?.bannerImageMinimumHeightPx,
		mobileBannerImageMinimumHeightPx: bannerData?.mobileBannerImageMinimumHeightPx,
		loaded
	});


	useEffect(() => {
		const image = new Image();
		const url = bannerData?.backgroundImage?.data?.attributes?.formats?.large?.url ?? bannerData?.backgroundImage?.data?.attributes?.url;
		if(url){
			image.src = url;
		}
		else setLoaded(true)

		image.onload = () => {
			if(image.src){
				setImageUrl(image.src);
				setLoaded(true)
			}
		};
	}, [bannerData]);

	return (
		<Box className={classes.courseLibraryBanner}>
			<Box className={classes.contentContainer}>
				<Typography className={classes.title}>{bannerData?.title}</Typography>
				<WYSIWYG className={classes.bannerContent}>
					{bannerData?.content ?? ""}
				</WYSIWYG>
				{bannerData?.link &&
					<Button
						component={Link}
						to={bannerData.link}
						className={classes.btn}
					>
						Read More
					</Button>}
			</Box>
		</Box>
	);
}