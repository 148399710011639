import {makeStyles} from "tss-react/mui";
import {IModuleScheduled} from "@plumeuk/shapeshift-types";
import {Box, BoxProps, Typography} from "@mui/material";
import {TrainingFeedModule} from "../../components/trainingFeed/TrainingFeedModule";
import {TrainingFeedModuleSkeleton} from "./TrainingFeedModuleSkeleton";
import {TrainingFeedModuleSkeletonSlim} from "./TrainingFeedModuleSkeletonSlim";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {palette} from "../../constants";
import {TrainingFeedModuleSlim} from "../../components/trainingFeed/trainingFeedModuleSlim";

interface IProps extends BoxProps {
	day: Date //> expects day to have no time
	isLoading?: boolean,
	slim?: boolean,
	data?: IModuleScheduled[],
}

interface IStyleProps {
	slim?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {slim}) => ({
	scheduledDayModules: {
		width: "100%",
		display: "flex",
		gap: "20px",
		flexWrap: "wrap",
		boxSizing: "border-box",
		paddingBottom: 0
	},
	nothingScheduledContainer: {
		minHeight: slim ? "94px": "476px",
		display: "flex",
		flexDirection: slim ? "row" : "column",
		justifyContent: "center",
		padding: slim ? "20px" : "50px",
		textAlign: "center",
		boxSizing: "border-box",
		"svg": {
			margin: "10px auto",
			width: slim ? "40px" : "100px",
			height: slim ? "40px" : "100px",
			"path": {
				fill: palette.greySolid
			}
		},
		"& h5": slim ? {
			padding: "18px",
			fontSize: "15px",
			color: palette.grey70
		} : {}
	}
}));

export const ScheduledDayModules: React.FC<IProps> = ({day, slim, isLoading, className, data, ...props}) => {
	day.setHours(0,0,0,0)
	const startDate = day;
	const endDate = new Date(day);
	endDate.setDate(endDate.getDate() + 1);
	const {classes, cx} = useStyles({slim});


	return (
		<Box className={cx(classes.scheduledDayModules, className)} {...props}>
			{(isLoading)
				? (slim ? [1] : [1]).map(e => (slim
					? <TrainingFeedModuleSkeletonSlim key={e} />
					: <TrainingFeedModuleSkeleton key={e} />))
				: data?.map(module => slim
					? <TrainingFeedModuleSlim
						key={module.course.slug + "_" + module.type + "_" + module.id}
						module={module}
					/>
					:<TrainingFeedModule
						key={module.course.slug + "_" + module.type + "_" + module.id}
						module={module}
					/>
				)}
			{!(isLoading) && data?.length === 0 &&
				<Box className={classes.nothingScheduledContainer}><EventBusyIcon /><Typography variant="h5">Nothing scheduled</Typography></Box>
			}
		</Box>
	)
}