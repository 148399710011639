import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {ISingleChoiceQuestionEntity, IQuestionEntity, corePaths, ISingleChoiceQuestionEntityPayload} from "@plumeuk/shapeshift-types";
import {useParams} from "react-router-dom";
import AdminStringList from "../../../AdminStringList/AdminStringList";
import {AdminEntityPageContainer, AdminInputText, AdminFieldContainer} from "@plumeuk/shapeshift-common/admin";

interface IProps {
	onSave?: (e: Pick<IQuestionEntity, "id" | "__type" | "title">) => void
}

const useStyles = makeStyles()((theme) => ({
	adminQuizQuestionSingleChoicePage: {

	}
}));

const template: ISingleChoiceQuestionEntityPayload = {
	title: "", answer: "", hint: "", options: []
}

export const AdminQuizSingleChoiceQuestionPage: FC<IProps> = ({onSave}) => {
	const {classes} = useStyles();
	const {questionId} = useParams();

	const IQuestionEntityToIQuestionEntityPayload = (e: ISingleChoiceQuestionEntity): ISingleChoiceQuestionEntityPayload => {
		const {title, answer, options, content, id, updatedBy, createdBy, createdAt, updatedAt, hint} = e
		const payload = {
			title, answer, content, id, options, updatedBy, createdBy, createdAt, updatedAt, hint
		}
		return payload;
	}
	return (
		<Box className={classes.adminQuizQuestionSingleChoicePage}>
			<AdminEntityPageContainer<ISingleChoiceQuestionEntity, "questionSingleChoice">
				entityToPayload={IQuestionEntityToIQuestionEntityPayload}
				entityId={questionId ? parseInt(questionId) : undefined}
				template={template}
				type="questionSingleChoice"
				onSaveResponse={e => (!e.isLoading && e.data) && onSave?.({...e.data, __type: corePaths["questionSingleChoice"]})}
			>
				{({formData, setFormData}) => <>
					<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Title"}/>
					<AdminInputText onChange={e => setFormData(prev => ({...prev, hint: e.target.value}))} value={formData.hint} label={"Hint"}/>
					<AdminFieldContainer required label="Options"><AdminStringList defaultMessage="" allowDuplicates={false} value={formData.options ?? []} onChange={e => setFormData(prev => ({...prev, options: e}))}/></AdminFieldContainer>
					<AdminInputText required onChange={e => setFormData(prev => ({...prev, answer: e.target.value}))} value={formData.answer} label={"Correct Answer"}/>
				</>}
			</AdminEntityPageContainer>
		</Box>
	);
}