import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {AdminUsersList} from "./AdminUsersList";
import {AdminUserPage} from "./AdminUserPage";

const useStyles = makeStyles()((theme) => ({
	adminUsersPage: {

	}

}));

export const AdminUsersPage: FC = () => {
	const {classes} = useStyles();

	return (
		<Box className={classes.adminUsersPage}>
			<Routes>
				<Route index element={<AdminUsersList />}/>
				<Route path=":id" element={<AdminUserPage />} />
			</Routes>
		</Box>
	);
}