import {FC} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import {PageBase} from "../../components/pageBase/pageBase";
import {CurriculumProvider} from "../../contexts/curriculumContext";
import {CourseCertificatePage} from "../CourseCertificatePage/CourseCertificatePage";
import {ModulePage} from "../ModulePage/ModulePage";
import {CourseLandingPage} from "./CourseLandingPage";
import {CourseSideMenuContent} from "./CourseSideMenuContent";
import {MobileTrainingFeedDrawer} from "../../components/pageBase/mobileTrainingFeedDrawer";

export const CoursePage: FC = () => {
	const {courseSlug} = useParams();

	return (
		<CurriculumProvider courseSlug={courseSlug}>
			<PageBase sideBarContent={<CourseSideMenuContent />} contentWidth={"3000px"} disableFooter={true}>
				<Routes>
					<Route index element={<CourseLandingPage />}/>
					<Route path="complete" element={<CourseCertificatePage />} />
					<Route path=":moduleType/:moduleSlug/*" element={<ModulePage/>} />
				</Routes>
				<MobileTrainingFeedDrawer />
			</PageBase>
		</CurriculumProvider>
	);
}