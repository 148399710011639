import {BoxProps} from "@mui/material"
import {FC} from "react"
import {makeStyles} from "tss-react/mui";
import type {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {Link, useMatch} from "react-router-dom";
import {palette} from "../../constants";
import {ModuleSummaryCard} from "../module/ModuleSummaryCard";

interface IProps extends BoxProps {
	module: ICourseCurriculumModule,
	courseSlug: string
}

const useStyles = makeStyles<{pageIsActive: boolean}>()((theme, {pageIsActive}) => ({
	trainingFeedModule: {
		outline: "1px solid " + (pageIsActive ? theme.palette.primary.main : palette.grey05)
	}
}));


export const CourseCurriculumModule:FC<IProps> = ({className, module, courseSlug, ...boxProps}) => {
	const url = "/course/" + courseSlug + "/" + module.type + "/" + module.slug + "/*";
	const pageIsActive = useMatch(url);
	const {classes, cx} = useStyles({pageIsActive: !!pageIsActive});

	return (
		<ModuleSummaryCard module={module} className={cx(classes.trainingFeedModule, className)} {...boxProps}/>
	)
}