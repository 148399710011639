import {makeStyles} from "tss-react/mui";
import {IModuleScheduled} from "@plumeuk/shapeshift-types";
import {Box, BoxProps} from "@mui/material";
import {TrainingFeedModule} from "../../components/trainingFeed/TrainingFeedModule";
import {TrainingFeedModuleSlim} from "../../components/trainingFeed/trainingFeedModuleSlim";

interface IProps extends BoxProps {
	modules?: IModuleScheduled[],
	slim?: boolean
}

interface IStyleProps {
	slim?: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {slim}) => ({
	overdueModules: {
		width: "100%",
		display: "flex",
		gap: "20px",
		flexWrap: "wrap",
		marginBottom: slim ? 0 : "25px"
	}
}));

export const OverdueModules: React.FC<IProps> = ({modules, className, slim, ...props}) => {
	const {classes, cx} = useStyles({slim});
	return (
		<Box className={cx(classes.overdueModules, className)} {...props}>
			{modules?.map(module => slim
				? <TrainingFeedModuleSlim
					key={module.enrollmentId + "_" + module.type + "_" + module.id}
					module={module}
				/>
				: <TrainingFeedModule
					key={module.enrollmentId + "_" + module.type + "_" + module.id}
					module={module}
				/>
			)}
		</Box>
	)
}