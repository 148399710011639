import {QuizQuestionListContainer} from "@plumeuk/shapeshift-common/quiz";
import {IQuizAnswer, IQuizQuestion} from "@plumeuk/shapeshift-types";
import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {palette} from "../../../../../constants";

interface IProps {
	questions?: IQuizQuestion[],
	onSubmit: (answers: IQuizAnswer[]) => void,
	submitResponseResultId?: string
}

const useStyles = makeStyles()((theme) => ({
	quizQuestionContainer: {
		"[class*='dragToOrderQuestion'], [class*='matchingOrderQuestion']": {
			"[class*='questionOption']": {
				padding: 0
			}
		},
		"[class*='title']": {
			fontSize: "24px"
		},
		"[class*='answerCounter']": {
			height: "36px",
			background: palette.grey10,
			boxSizing: "border-box",
			justifyContent: "center",
			display: "flex",
			flexDirection: "column",
			fontWeight: 600,
			fontSize: "14px"
		},
		"[class*='questionCounter']": {
			fontFamily: "Titillium Web,sans-serif",
			color: theme.palette.common.white,
			"& span": {
				color: theme.palette.primary.main
			}
		},
		"[class*='inputContainer']": {
			background: palette.grey05,
			border: palette.grey20 + " 1px solid"
		},
		"[class*='questionOption']": {
			minHeight: "56px",
			boxSizing: "border-box",
			background: "#282828",
			border: palette.grey20 + " 1px solid",
			"[class*='childrenContainer']": {
				padding: "5px 13px"
			},
			"[class*='optionId']": {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				textAlign: "center",
				height: "34px",
				background: palette.grey10,
				fontSize: "14px",
				fontWeight: 600,
				boxSizing: "border-box",
				width: "34px",
				borderRadius: "5px",
				border: palette.grey20 + " 1px solid"
			},
			"&.active": {
				background: palette.grey10,
				borderColor: palette.grey40,
				"[class*='optionId']": {
					background: theme.palette.primary.main,
					color: theme.palette.common.black
				}
			}
		}


	}
}));

export const QuizQuestionContainer: FC<IProps> = ({questions, onSubmit, submitResponseResultId}) => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	useEffect(() => {
		if(submitResponseResultId)
			navigate("../complete/" + submitResponseResultId)
	}, [submitResponseResultId])

	return (
		<QuizQuestionListContainer
			className={classes.quizQuestionContainer}
			questions={questions}
			onSubmit={answers => {onSubmit(answers)}}
		/>
	);
}