import {FC} from "react";
import {QuizStartSummary} from "./QuizStartSummary/QuizStartSummary";
import {QuizQuestions} from "./QuizQuestions/QuizQuestions";
import {Route, Routes} from "react-router-dom";
import {QuizResult} from "./QuizQuestions/QuizResult/QuizResult";
import {IQuiz} from "@plumeuk/shapeshift-types";

interface IProps {
	quiz?: IQuiz
}

export const QuizRouter: FC<IProps> = ({quiz}) => (
	<Routes>
		<Route index element={<QuizStartSummary quiz={quiz}/>} />
		<Route path="active" element={<QuizQuestions quiz={quiz} />} />
		<Route path="complete/:resultId?" element={<QuizResult quiz={quiz}/>} />
	</Routes>
)