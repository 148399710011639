import {Box, BoxProps, LinearProgress} from "@mui/material";
import {FC, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {QuizResultBreakdown} from "./QuizResultBreakdown";
import {MarkedQuizResultDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useParams} from "react-router-dom";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	quizResult: {
		textAlign: "left",
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px"
		}
	},
	markedAnswer: {
		marginBottom: "60px"
	},
	label: {
		color: "#A8A8A8"
	},
	title: {
		margin: "60px 0 20px 0"
	},
	subTitle: {
		marginBottom: "20px",
		color: "#A8A8A8"
	}
}));

interface IProps extends BoxProps {
	quiz?: IQuiz,
	data?: IMarkedQuizAnswers
}

export const QuizResult: FC<IProps> = ({quiz, data, className, ...props}) => {
	const {cx, classes} = useStyles();
	const {courseSlug, resultId, moduleSlug: quizSlug} = useParams();


	return (
		<MarkedQuizResultDataProvider data={data} courseSlug={courseSlug} quizSlug={quizSlug} resultId={resultId}>
			{({markedQuizResult, apiResult}) =>
				<Box className={cx(classes.quizResult, className)} {...props}>
					{apiResult.isLoading && <LinearProgress />}
					{markedQuizResult && <QuizResultBreakdown quiz={quiz} markedAnswers={markedQuizResult} />}
				</Box>
			}
		</MarkedQuizResultDataProvider>
	);
}