import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, TableCell, TextField} from "@mui/material";
import {AdminHeader} from "../../AdminHeader";
import {IAdminUsersResponseEntry} from "../../../../types/admin";
import {Check, Error} from "@mui/icons-material";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {AdminUsersBulkActionAddToTrainingFeedModal} from "./AdminUsersBulkActionAddToTrainingFeedModal/AdminUsersBulkActionAddToTrainingFeedModal";
import {useNavigate} from "react-router-dom";
import {adminTheme} from "../../../../templates/adminTheme";

const useStyles = makeStyles()((_theme) => ({
	adminUsersList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

function convertSecondsToHHMM(seconds: number): string {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);

	// Pad with zeros if necessary
	const paddedHours = String(hours).padStart(2, "0");
	const paddedMinutes = String(minutes).padStart(2, "0");

	return `${paddedHours}:${paddedMinutes}`;
}

const headCells: AdminTableProps<"users", IAdminUsersResponseEntry>["columns"] = ([
	{
		field: "firstname",
		flex: 1,
		headerName: "First Name",
		width: 200,
		type: "string"
	},
	{
		field: "lastname",
		flex: 1,
		headerName: "Last Name",
		width: 200,
		type: "string"
	},
	{
		field: "email",
		flex: 1,
		headerName: "Email",
		type: "string"
	},
	{
		field: "phone",
		flex: 1,
		headerName: "Phone",
		type: "string"
	},
	{
		field: "jobCategory",
		flex: 1,
		headerName: "Job Category",
		type: "string"
	},
	{
		field: "workload",
		flex: 1,
		type: "number",
		headerName: "Workload",
		headerAlign: "left",
		align: "left",
		renderCell: (e) => <TableCell>
			{e.value > 0 && `+${e.value}`}
			{e.value < 0 && <Error />}
			{e.value === 0 && <Check />}
		</TableCell>
	},
	{
		field: "timeSpentOnTraining",
		flex: 1,
		type: "number",
		headerName: "Time in training",
		headerAlign: "left",
		align: "left",
		filterable: false,
		valueGetter: (e) => e.value ? convertSecondsToHHMM(e.value) : "0"
	},
	{
		field: "streak",
		flex: 1,
		type: "number",
		headerName: "Streak",
		headerAlign: "left",
		align: "left",
		renderCell: (e) => {
			let textColor
			switch (true) {
				case e.value === 0:
					textColor = adminTheme.error
					break;
				case e.value > 0 && e.value <= 4:
					textColor = adminTheme.warning
					break;
				case e.value > 4:
					textColor = adminTheme.success
					break;
			}
			return <TableCell><Box color={textColor} component="span">{e.value}</Box></TableCell>
		}
	},
	{
		field: "completedModules",
		flex: 1,
		type: "number",
		headerAlign: "left",
		align: "left",
		headerName: "Completed Lessons"
	},
	{
		field: "overdue",
		flex: 1,
		type: "number",
		headerName: "Overdue Lessons",
		headerAlign: "left",
		align: "left",
		renderCell: (e) => {
			let textColor
			switch (true) {
				case e.value === 0:
					textColor = adminTheme.success
					break;
				case e.value > 0 && e.value <= 4:
					textColor = adminTheme.warning
					break;
				case e.value > 4:
					textColor = adminTheme.error
					break;
			}
			return <TableCell><Box color={textColor} component="span">{e.value}</Box></TableCell>
		}
	},
	{
		field: "createdAt",
		flex: 1,
		type: "date",
		headerName: "Created",
		width: 100,
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	}
]);

export const AdminUsersList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [batchActionModal, setBatchActionModal] = useState<{label: string, open: boolean, selected: number[]}>({label: "", open: false, selected: []});

	return (
		<Box className={classes.adminUsersList}>
			<AdminHeader title="Users" subtitle="Manage users, enroll them into courses and check their capacity" />
			<AdminTable<"users", IAdminUsersResponseEntry>
				url="/api/user"
				onEditAction={e => navigate("/admin/users/" + e.id.toString())}
				columns={headCells}
				type="users"
				bulkActionOptions={[{label: "Add to training feed", onSelect: (label, selected) => setBatchActionModal({label, open: true, selected})}]}
			>
				{(data, reload) =>
					<AdminUsersBulkActionAddToTrainingFeedModal
						open={batchActionModal.open && batchActionModal.label === "Add to training feed"}
						selected={batchActionModal.selected}
						onClose={(r) => {
							setBatchActionModal({open: false, label: "", selected: []});
							if(r)
								reload();
						}}
					/>
				}
			</AdminTable>
		</Box>
	);
}