import {FC, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Breadcrumbs, Button, Chip, Stack, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Tabs, Tab} from "@mui/material";
import {UserTrainingFeedTab} from "./UserPageTabs/UserTrainingFeedTab";
import {UserWatchHistoryTab} from "./UserPageTabs/UserWatchHistoryTab";
import {UserActiveCoursesTab} from "./UserPageTabs/UserActiveCoursesTab";
import {UserFinishedCoursesTab} from "./UserPageTabs/UserFinishedCoursesTab";
import {IUserEntity} from "@plumeuk/shapeshift-types";
import {useApi} from "@plumeuk/shapeshift-identity";
import {UserEnrollmentTab} from "./UserPageTabs/UserEnrollmentTab";
import {palette} from "../../../../constants";

interface IProps {}

const useStyles = makeStyles()((theme) => ({
	adminLessonPage: {
		"a": {
			textDecoration: "none",
			color: theme.palette.grey[700]
		}
	},
	tabs:{
		marginTop: "24px",
		marginBottom: "24px",
		minHeight: "auto"
	},
	tabItem:{
	},
	tabHeader:{
		minHeight: "45px",
		paddingTop: 0,
		paddingBottom: 0,
		fontSize: "14px",
		textTransform: "uppercase",
		letterSpacing: "0.4px",
		"&[class*='Mui-selected']": {
			color: palette.orange
		}
	},
	userName:{
		alignItems: "flex-end"
	}
}));

type tabsType = "training-feed" | "watch-history" | "active-courses" | "finished-courses" | "enrollment"

export const AdminUserPage: FC<IProps> = () => {
	const {id: _userId} = useParams();
	const {classes} = useStyles();
	const [tab, setTab] = useState<tabsType>("training-feed");
	const [user, getUser] = useApi<IUserEntity>()
	const [streak, getStreak] = useApi<number>()
	const navigate = useNavigate();

	const userId = _userId ? parseInt(_userId) : undefined;
	if(!userId)
		navigate("../")

	useEffect(() => {
		getUser(`/api/strapi-plugin-shapeshift-lms/cohort/users/${userId}`)
		getStreak(`/api/user/admin/${userId}/streak`)
	}, [userId])

	let currentTabContent;

	if (userId) {
		switch (true) {
			case tab === "training-feed" :
				currentTabContent = <UserTrainingFeedTab userId={userId} className={classes.tabItem}/>
				break;
			case tab === "watch-history" :
				currentTabContent = <UserWatchHistoryTab userId={userId} className={classes.tabItem}/>
				break;
			case tab === "active-courses" :
				currentTabContent = <UserActiveCoursesTab userId={userId} className={classes.tabItem}/>
				break;
			case tab === "finished-courses" :
				currentTabContent = <UserFinishedCoursesTab userId={userId} className={classes.tabItem}/>
				break;
			case tab === "enrollment" :
				currentTabContent = <UserEnrollmentTab userId={userId} className={classes.tabItem}/>
				break;
		}
	}

	return (
		<Box className={classes.adminLessonPage}>
			<Breadcrumbs separator="/" aria-label="breadcrumb">
				<Link to={"/admin/users"}><span>Employees</span></Link>
				<Link to={"/admin/users/" + userId}><span>{user.data?.firstname} {user.data?.lastname}</span></Link>
			</Breadcrumbs>
			<Box sx={{display: "flex", justifyContent: "space-between"}}>
				<Stack direction="row" className={classes.userName}>
					<Typography variant="h2">{user.data?.firstname} {user.data?.lastname}</Typography>
					{(streak?.data !== undefined) && <Chip color="default" label={streak.data + (streak.data === 1 ? " day" : " days") + " streak"} />}
				</Stack>
				{/* <Link to={process.env.REACT_APP_PROPELAUTH_ACCOUNT_SETTINGS_URI + "/employees/profile/"+user.data?.employeeId}>
					<Button variant="outlined">VIEW USER PROFILE</Button>
				</Link> */}
			</Box>
			<Tabs className={classes.tabs} value={tab} onChange={(_e, v) => setTab(v)}>
				<Tab className={classes.tabHeader} label="Training Feed" value="training-feed"/>
				<Tab className={classes.tabHeader} label="Watch History" value="watch-history"/>
				<Tab className={classes.tabHeader} label="Active Courses" value="active-courses"/>
				<Tab className={classes.tabHeader} label="Finished Courses" value="finished-courses"/>
				<Tab className={classes.tabHeader} label="Enrollment" value="enrollment"/>
			</Tabs>
			{currentTabContent}
		</Box>
	);
}

