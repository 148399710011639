import {FC, useEffect, useState} from "react";
import {AuthProvider as SSIdentityAuthProvider} from "@plumeuk/shapeshift-identity";
import {IUser} from "@plumeuk/shapeshift-types";
import {Box, LinearProgress} from "@mui/material";
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";
import {App} from "@capacitor/app";
import {Preferences} from "@capacitor/preferences";
import {datadogRum} from "@datadog/browser-rum";

// OAuth configuration
const clientId = process.env.REACT_APP_PROPELAUTH_CLIENT_ID;
const androidRedirectUri = process.env.REACT_APP_PROPELAUTH_ANROID_REDIRECT_URI;
const authUrl = process.env.REACT_APP_PROPELAUTH_URL;
const secret = process.env.REACT_APP_PROPELAUTH_SECRET;
const authorizationEndpoint = authUrl + "/propelauth/oauth/authorize";

if(!secret)
	throw new Error("Failed to get REACT_APP_PROPELAUTH_SECRET env")

if(!authUrl)
	throw new Error("Failed to get REACT_APP_PROPELAUTH_URL env")

if(!androidRedirectUri)
	throw new Error("Failed to get REACT_APP_PROPELAUTH_ANROID_REDIRECT_URI env")

if(!clientId)
	throw new Error("Failed to get REACT_APP_PROPELAUTH_CLIENT_ID env")

if(!process.env.REACT_APP_BASE_URL)
	throw new Error("Failed to get REACT_APP_BASE_URL env")

if(!process.env.REACT_APP_API_HOST)
	throw new Error("Failed to get REACT_APP_API_HOST env")

const API_HOST = process.env.REACT_APP_API_HOST;
const getEnv = (): string => {
	if(API_HOST?.includes("local"))
		return "local";
	if(API_HOST?.includes("dev"))
		return "dev";
	if(API_HOST?.includes("staging"))
		return "local";
	return "production"
}

const DATA_DOG_CLIENT_TOKEN = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN;
if(DATA_DOG_CLIENT_TOKEN)
	datadogRum.init({
		applicationId: "970eccf1-6b6f-436a-a18a-b34d48b82098",
		clientToken: DATA_DOG_CLIENT_TOKEN,
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: "us3.datadoghq.com",
		service: "training-v4-web",
		env: getEnv(),
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input"
	});


interface IProps {
	children: JSX.Element
}


const generateRandomState = (length: number): string => {
	const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let state = "";
	for (let i = 0; i < length; i++) {
		state += charset.charAt(Math.floor(Math.random() * charset.length));
	}
	return state;
}

const AuthProvider: FC<IProps> = ({children}) => {
	const [accessToken, setAccessToken] = useState<string>();
	const [refreshToken, setRefreshToken] = useState<string>();
	const [expiresIn, setExpiresIn] = useState<number>();
	const [user, setUser] = useState<IUser & {profileImage: string, organizationId?: string, organizationName?: string, externalId?: string}>();
	const [authenticated, setAuthenticated] = useState(false);
	const [state] = useState("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789")//generateRandomState(16)
	const [platform] = useState(Capacitor.getPlatform());

	const redirect_uri = platform === "web" ? process.env.REACT_APP_BASE_URL : androidRedirectUri;
	if(!redirect_uri)
		throw new Error("Failed to get redirect_uri for exchangeCodeForToken in AuthProvider")

	const getBaseUrl = (): string => {
		const url = process.env.REACT_APP_API_HOST;
		if(!url){
			throw new Error("Please set REACT_APP_API_HOST in .env");
		}
		return url;
	}

	const handleLogout = async (): Promise<void> => {
		const logoutEndpoint = authUrl + "/api/backend/v1/logout";
		if(!refreshToken)
			return;

		const response = await fetch(logoutEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				"refresh_token": refreshToken
			})
		});
		await clearStored();
		loginWithOAuth();
	};

	useEffect(() => {
		if(!user)
			return;

		datadogRum.setGlobalContextProperty("UserName", user.username);
		datadogRum.setGlobalContextProperty("UserId", user.externalId);
		datadogRum.setGlobalContextProperty("UserEmail", user.email);
		datadogRum.setGlobalContextProperty("OrganizationId", user.organizationId);
		datadogRum.setGlobalContextProperty("OrganizationName", user.organizationName);
	}, [user])

	//if not authenticated or token not set
	useEffect(() => {
		const fnc = async (): Promise<void> => {
			const token: {refresh?: string | null, access?: string | null, expiresIn?: string | null, user?: string | null} = {};
			//check storage
			if(platform === "android" || platform === "ios"){
				try {
					token.access = (await Preferences.get({key: "access"})).value;
					token.refresh = (await Preferences.get({key: "refresh"})).value;
					token.expiresIn = (await Preferences.get({key: "expiresIn"})).value;
					token.user = (await Preferences.get({key: "user"})).value;
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error("Error retrieving SecureStoragePlugin token", error);
				}
			}
			else if(platform === "web"){
				try {
					token.access = localStorage.getItem("access");
					token.refresh = localStorage.getItem("refresh");
					token.expiresIn = localStorage.getItem("expiresIn");
					token.user = localStorage.getItem("user");
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error("Error retrieving localStorage token", error);
				}
			}

			if (token.expiresIn && (parseInt(token.expiresIn) ) < new Date().getTime()) {
				loginWithOAuth();
			}

			else if(token.access && token.refresh && token.expiresIn){
				setAccessToken(token.access);
				setRefreshToken(token.refresh)
				setExpiresIn(parseInt(token.expiresIn))
				setAuthenticated(true)
			}

			else {
				loginWithOAuth();
				clearStored()
			}

			if(token.user){
				setUser(JSON.parse(token.user))
			}
			else if(token.access) getUserInfo(token.access)
		}

		if(authenticated)
			return;

		fnc();
	}, [authenticated])

	const clearStored = async (): Promise<void> => {
		if(platform === "android" || platform === "ios") {
			await Preferences.remove({key: "access"});
			await Preferences.remove({key: "refresh"});
			await Preferences.remove({key: "expiresIn"});
			await Preferences.remove({key: "user"});
		}
		else if(platform === "web") {
			localStorage.removeItem("user")
			localStorage.removeItem("access")
			localStorage.removeItem("refresh")
			localStorage.removeItem("expiresIn")
		}
	}

	// Function to start OAuth Flow
	const loginWithOAuth = async (): Promise<void> => {
		//check current url does not contain code 
		if(window?.location?.href?.includes("?code")){
			// Extract authorization code from the URL
			const url = new URL(window.location.href);
			const authorizationCode = url.searchParams.get("code");
			if (authorizationCode) {
				// Exchange authorization code for access token
				await exchangeCodeForToken(authorizationCode);
			}
			return;
		}

		const _redirect_uri = encodeURIComponent(redirect_uri);
		const url = `${authorizationEndpoint}?client_id=${clientId}&response_type=code&redirect_uri=${_redirect_uri}&scope=openid%20profile%20email&state=${state}`;

		if(platform === "android" || platform === "ios"){
			await Browser.open({url});
			// Listen for the deep link
			App.addListener("appUrlOpen", async (data) => {
				// Extract authorization code from the URL
				const url = new URL(data.url);
				const authorizationCode = url.searchParams.get("code");

				if (authorizationCode) {
					// Exchange authorization code for access token
					await exchangeCodeForToken(authorizationCode);
				}
				// Close the browser if needed
				await Browser.close();
			});
		}
		else {
			window.location.href = url
		}
	}

	const refreshAccessToken = async (): Promise<void> => {
		const tokenEndpoint = authUrl + "/propelauth/oauth/token";

		if(!refreshToken)
			return;

		const response = await fetch(tokenEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			body: new URLSearchParams({
				client_id: clientId,
				client_secret: secret,
				grant_type: "refresh_token",
				refresh_token: refreshToken
			})
		});

		if (!response.ok) {
			throw new Error("Failed to refresh access token");
		}

		const data = await response.json();
		const {access_token: newAccessToken, refresh_token: newRefreshToken, expires_in: newExpiresIn} = data;
		const expiryTime = (Date.now() + newExpiresIn * 1000); // Convert seconds to milliseconds

		setAccessToken(newAccessToken)
		setRefreshToken(newRefreshToken)
		setExpiresIn(expiryTime)
	}

	//refresh token every min
	useEffect(() => {
		const checkTokenInterval = setInterval(async () => {
			try {
				await refreshAccessToken();
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error("Error refreshing access token:", error);
				loginWithOAuth();
			}
		}, 60000);

		return () => clearInterval(checkTokenInterval); // Clean up interval on component unmount
	}, [accessToken]);

	const exchangeCodeForToken = async (code: string): Promise<void> => {
		const tokenEndpoint = authUrl + "/propelauth/oauth/token";
		const authString = window.btoa(`${clientId}:${secret}`);
		const response = await fetch(tokenEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Basic ${authString}`
			},
			body: new URLSearchParams({
				state,
				client_id: clientId,
				// client_secret: secret,
				grant_type: "authorization_code",
				code: code,
				redirect_uri: redirect_uri
			})
		});

		const data = await response.json();

		const expiryTime = (Date.now() + data.expires_in * 1000);

		setAccessToken(data.access_token)
		setRefreshToken(data.refresh_token)
		setExpiresIn(expiryTime)
		setAuthenticated(true);
		getUserInfo(data.access_token);
	}

	const getUserInfo = async (accessToken: string): Promise<void> => {
		const userEndpoint = authUrl + "/propelauth/oauth/userinfo";

		const response = await fetch(userEndpoint, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${accessToken}`
			}
		});

		const data = await response.json();
		const orgId = Object.keys(data?.org_id_to_org_info ?? {})?.[0]
		const orgInfo = data?.org_id_to_org_info?.[orgId];
		const orgName = orgInfo["org_name"];

		setUser({
			firstname: data.first_name, lastname: data.last_name,
			blocked: false, confirmed: true, username: data.email,
			email: data.email, createdAt: new Date(data.created_at).toLocaleString(),
			updatedAt: "", provider: "", profileImage: data.picture_url,
			organizationId: orgId, organizationName: orgName, externalId: data.user_id
		} as (IUser & {profileImage: string, organizationId?: string, organizationName?: string, externalId?: string}))
	}

	useEffect(() => {
		const storeTokens = async (accessToken?: string, refreshToken?: string, expiresIn?: number): Promise<void> => {
			if(!(refreshToken && accessToken && expiresIn))
				return;

			if(platform === "android" || platform === "ios"){
				await Preferences.set({key: "access",	value: accessToken});
				await Preferences.set({key: "refresh",	value: refreshToken});
				await Preferences.set({key: "expiresIn", value: expiresIn.toString()});
			}
			else if(platform === "web"){
				localStorage.setItem("access", accessToken);
				localStorage.setItem("refresh", refreshToken);
				localStorage.setItem("expiresIn", expiresIn.toString());
			}
		}

		storeTokens(accessToken, refreshToken, expiresIn)
	}, [accessToken, refreshToken, expiresIn])

	useEffect(() => {
		const storeUser = async (user?: IUser): Promise<void> => {
			if(!(user))
				return;

			if(platform === "android" || platform === "ios"){
				await Preferences.set({key: "user",	value: JSON.stringify(user)});
			}
			else if(platform === "web"){
				localStorage.setItem("user", JSON.stringify(user));
			}
		}

		storeUser(user)
	}, [user])

	// const urlParams = new URLSearchParams(window.location.search);
	// const paramsObj = Array.from(urlParams.keys()).reduce(
	// 	(acc, val) => ({...acc, [val]: urlParams.get(val)}),
	// 	{}
	// );

	if(!authenticated|| !user){
		return <LinearProgress />
		//Kept for debugging 
		//to do: remove
		// return<>
		// 	{/* <Button onClick={() => refreshAuthInfo()}>refresh</Button> */}
		// 	<LinearProgress />
		// 	<Typography>Loading State</Typography>
		// 	{/* <Typography>webLoggedIn : {webLoggedIn ? "TRUE" : "FALSE"}</Typography> */}
		// 	<Typography>authenticated : {authenticated ? "TRUE" : "FALSE"}</Typography>
		// 	<Typography>user: {JSON.stringify(user ?? {})}</Typography>
		// 	<Typography>accessToken: { accessToken}</Typography>
		// 	{/* <Typography>tokens: { JSON.stringify(tokens ?? {})}</Typography> */}
		// 	<Typography>accessToken: { urlParams.get("access_token")}</Typography>
		// 	<Typography>refreshToken: { urlParams.get("refresh_token")}</Typography>
		// 	<Typography>ALL: { JSON.stringify(paramsObj)}</Typography>
		// 	<Typography>MSG: { msg}</Typography>
		// </>

	}

	return (
		<SSIdentityAuthProvider
			contextConfig={{baseApiUrl: getBaseUrl()}}
			jwt={accessToken}
			user={user}
			actionOverrideValidateAuth={(jwt: string | undefined) => !!jwt && authenticated}
			actionOverrideLogout={() => handleLogout()}
		>
			<>
				{children}
			</>
		</SSIdentityAuthProvider>
	)
}

export default AuthProvider;