import {LinearProgress} from "@mui/material";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {FC, useCallback, useContext, useEffect} from "react";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {TrainingfeedContext} from "../../contexts/trainingFeedContext";

//This is the landing page for both /course and /feed
//If course, auto nav to next module in course which is incomplete
//If feed, auto nav to next module in training feed wich is incomplete

export const CourseLandingPage: FC = () => {
	const navigate = useNavigate();
	const {courseSlug} = useParams();
	const {curriculumState} = useContext(CurriculumContext);
	const {trainingfeedState} = useContext(TrainingfeedContext);
	const pageIsFeed = useMatch("/feed/:courseSlug/*");

	useEffect(() => {
		if(pageIsFeed){
			handleTrainingFeedAutoNav();
		}
		else
			handleCourseAutoNav();
	}, [curriculumState]);

	const handleCourseAutoNav = useCallback(() => {
		if(!curriculumState)
			return;

		if(curriculumState.percentCompleted === 100) {
		//redirect handled in curriculum context
			return;
		}
		const nextModule = getModule(curriculumState, undefined, true);
		if(nextModule)
			navigate(`/course/${courseSlug}/${nextModule?.type}/${nextModule?.slug}`, {replace: true});
		else
			alert("Module Not Found - will make page");
	}, [curriculumState])

	const handleTrainingFeedAutoNav = useCallback(() => {
		if(!trainingfeedState)
			return;

		//get module
		for(const day of ["overdue", ...Object.keys(trainingfeedState)]){
			if(trainingfeedState[day] === "loading"){
				//day still loading, wait and re-run on dep change
				return;
			}
			for(const module of trainingfeedState[day]){
				if(typeof module !== "string" && !module.complete){
					navigate(`/feed/${module.course.slug}/${module?.type}/${module?.slug}`, {replace: true});
					return;
				}
			}
		}
	}, [trainingfeedState])

	return (
		<LinearProgress />
	);
}