import {FC} from "react";
import {ILessonEntity, ILessonEntityPayload} from "@plumeuk/shapeshift-types";
import Editor from "../../../../components/admin/WYSIWYG/Editor";
import AdminVideoUpload from "./AdminBitmovinUpload/adminVideoUpload";
import {AdminEntityPageContainer, AdminFieldContainer, AdminInputText} from "@plumeuk/shapeshift-common/admin";
import {cohortLessonValidationDoc} from "../../../../validationDocs/user/cohortLessonValidationDoc";
import {AdminEntityPageContainerProps} from "@plumeuk/shapeshift-common/admin"
import {AdminLessonInlineQuiz} from "./AdminLessonInlineQuiz";
import {useNavigate} from "react-router-dom";

interface IProps extends Partial<AdminEntityPageContainerProps<ILessonEntity, "lesson">> {
	lessonId?: number
}

const template: ILessonEntityPayload = {
	title: "", slug: "", content: ""
}

export const AdminLessonPageContent: FC<IProps> = ({lessonId, ...props}) => {
	const navigate = useNavigate();
	const ILessonEntityToILessonEntityPayload = (e: ILessonEntity): ILessonEntityPayload => {
		const {title, slug, content, id, createdAt, updatedAt, subtitle, quiz} = e
		const videoData = (e as any).videoData;
		const payload = {
			title, slug, content, id, createdAt, updatedAt, subtitle, videoData, quiz: quiz?.id
		}
		return payload;
	}


	return (
		<AdminEntityPageContainer<ILessonEntity, "lesson">
			entityId={lessonId}
			entityToPayload={ILessonEntityToILessonEntityPayload}
			template={template}
			validationDoc={cohortLessonValidationDoc}
			type="lesson"
			{...props}
		>
			{({formData, setFormData, entity}) => <>
				{/* <AdminInputText onChange={e => setFormData(prev => ({...prev, slug: e.target.value}))} value={formData.slug} label={"Slug"}/> */}
				<AdminInputText required onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} label={"Lesson title"}/>
				<AdminInputText onChange={e => setFormData(prev => ({...prev, subtitle: e.target.value}))} value={formData.subtitle} label={"Lesson subtitle"}/>
				<AdminFieldContainer label="Content"><Editor onChange={e => setFormData(prev => ({...prev, content: e.target.value}))} value={formData.content ?? ""} /></AdminFieldContainer>
				<AdminFieldContainer label="Video"><AdminVideoUpload videoThumbnailUrl={entity?.videoThumbnailUrl} videoUrl={entity?.videoUrl} value={(formData as any)?.videoData} entity={entity} initialValue={(entity as any)?.videoData} onChange={e => setFormData(prev => ({...prev, "videoData": e}))}/></AdminFieldContainer>
				<AdminFieldContainer label="Inline Quiz">
					<AdminLessonInlineQuiz
						onDeleteAction={() => setFormData(prev => ({...prev, quiz: null}))}
						onSelectAction={() => navigate("/admin/quizzes/" + formData.quiz)}
						quizId={formData.quiz}
						onChange={(id) => {setFormData(prev => ({...prev, quiz: id}))}}
					/>
				</AdminFieldContainer>
			</>}
		</AdminEntityPageContainer>
	);
}