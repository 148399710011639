
import {makeStyles} from "tss-react/mui";
import {Box, CircularProgress, Typography} from "@mui/material";
import {CoursesDataProvider} from "@plumeuk/shapeshift-common/providers";
import {CourseSummaryCard} from "../../components/course/CourseSummaryCard";
import {palette} from "../../constants";
import {Link} from "react-router-dom";


const useStyles = makeStyles()((theme) => ({
	myCompletedCourses: {
		display: "flex",
		flexDirection: "column",
		gap: "10px",
		padding: "15px",
		border: "1px solid " + palette.grey08,
		borderRadius: "16px",
		background: "#282828"
	},
	centerWrapper: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		flexDirection: "column"
	},
	coursesContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	}
}));


export const MyFinishedCourses: React.FC = () => {
	const {classes} = useStyles();


	return (
		<Box className={classes.myCompletedCourses}>
			<CoursesDataProvider filters={{showComplete: true}}>
				{({courses, apiResult}) => (<>
					{apiResult.isLoading && <div className={classes.centerWrapper}><CircularProgress color="secondary" /></div>}
					{courses?.length === 0 && <Typography variant="body1">No courses completed</Typography>}
					{courses && courses.map((course) => (
						<Link to={`/course/${course.slug}`} key={course.id}>
							<CourseSummaryCard key={course.id} course={{...course, percentCompleted: 100}}/>
						</Link>
					))}
				</>
				)}
			</CoursesDataProvider>
		</Box>
	);
}