import {BoxProps, LinearProgress} from "@mui/material";
import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {IQuiz} from "@plumeuk/shapeshift-types";

interface IProps extends BoxProps {
	quiz?: IQuiz
}

export const QuizStartSummary: FC<IProps> = ({quiz}) => {
	const navigate = useNavigate();

	useEffect(() => {
		if(quiz && quiz.complete)
			navigate("complete");
		else if(quiz)
			navigate("active");
	}, [quiz])

	return <LinearProgress />
}