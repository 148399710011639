
import {makeStyles} from "tss-react/mui";
import {Box, CircularProgress, Typography} from "@mui/material";
import {CoursesDataProvider} from "@plumeuk/shapeshift-common/providers";
import MyCoursesSlider from "../../components/myCoursesSlider/MyCoursesSlider";
import {palette} from "../../constants";


const useStyles = makeStyles()((theme) => ({
	loadingContainer: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		height: "160px",
		flexDirection: "column",
		border: "1px solid " + palette.grey08,
		borderRadius: "16px",
		background: "#282828"
	},
	coursesContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	},
	noCoursesFoundContainer: {
		padding: "15px",
		display: "flex",
		boxSizing: "border-box",
		width: "100%",
		flexDirection: "column",
		border: "1px solid " + palette.grey08,
		borderRadius: "16px",
		background: "#282828"
	}
}));


export const MyCourses: React.FC = () => {
	const {classes} = useStyles();

	return (
		<CoursesDataProvider filters={{showComplete: false}}>
			{({courses, apiResult}) => (<>
				{apiResult.isLoading && <div className={classes.loadingContainer}><CircularProgress color="secondary" /></div>}
				{courses?.length === 0 && <Box className={classes.noCoursesFoundContainer}><Typography >No courses found</Typography></Box>}
				<Box className={classes.coursesContainer}>
					{courses && courses.map((course) => (
						<MyCoursesSlider key={course.id} course={course} />
					))}
				</Box>

			</>
			)}
		</CoursesDataProvider>
	);
}