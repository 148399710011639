import React, {FC} from "react";
import {IFlatList} from "@plumeuk/shapeshift-types";
import {makeStyles} from "tss-react/mui";
import {Box, Button} from "@mui/material";
import {ArrowLeft, ArrowRight, PlusOne, Refresh} from "@mui/icons-material";
import {palette} from "../../../../../../constants";

export interface IProps {
	flatListHistoryStack: IFlatList[][],
	undoRedoI?: number,
	onNewModuleAction: () => void,
	onUndo: () => void,
	onRedo: () => void,
	onReset: () => void
}

const useStyles = makeStyles()(
	(theme) => ({
		curriculumControls: {
			display: "flex",
			zIndex: 1,
			top: 0,
			background: palette.grey15,
			borderRadius: "5px",
			gap: "10px",
			padding: "20px 32px",
			position: "sticky",
			justifyContent: "space-between"
		},
		controls: {
			justifyContent: "right",
			display: "flex",
			gap: "10px",
			"& > div": {
				fontWeight: 600,
				cursor: "pointer",
				background: "white",
				border: "1px solid " + theme.palette.grey[100],
				padding: "7px 10px",
				display: "flex",
				gap: "20px",
				borderRadius: "5px",
				fontSize: "0.75rem",
				"& svg": {
					opacity: 0.5
				},
				"&:hover": {
					background: "transparent"
				}
			},
			"& > button": {
				height: "2rem",
				"& svg": {
					marginRight: "10px"
				}
			}
		}
	})
);

const CurriculumControls: FC<IProps> = (
	{flatListHistoryStack, undoRedoI, onNewModuleAction, onUndo, onRedo, onReset}
) => {
	const enableUndo = flatListHistoryStack.length > 0 && (undoRedoI === undefined || undoRedoI > 0);
	const enableRedo = undoRedoI !== undefined && undoRedoI < (flatListHistoryStack.length - 1);
	const enableReset = flatListHistoryStack.length > 0;
	const {classes} = useStyles();

	return (
		<Box className={classes.curriculumControls}>
			<Box className={classes.controls}>
				<Button data-test-id="curriculum-controls-add-module-btn" onClick={() => onNewModuleAction()} startIcon={<PlusOne />}>Modules</Button>
			</Box>
			<Box className={classes.controls}>
				<Button data-test-id="curriculum-controls-undo-btn" disabled={!enableUndo} onClick={() => enableUndo && onUndo()} startIcon={<ArrowLeft />}>
					Undo
				</Button>
				<Button data-test-id="curriculum-controls-redo-btn" disabled={!enableRedo} onClick={() => enableRedo && onRedo()} startIcon={<ArrowRight />}>
					Redo
				</Button>
				<Button data-test-id="curriculum-controls-reset-btn" disabled={!enableReset} onClick={() => enableReset && onReset()} startIcon={<Refresh />}>
					Reset
				</Button>
			</Box>
		</Box>
	)
}

export default CurriculumControls;