import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FC, useCallback, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {IAdminUserBulkActionAddToTrainingFeedPreviewEntry, IAdminUsersResponseEntry} from "../../../../../types/admin";
import {ArrowRightAlt, Check, Error} from "@mui/icons-material";
import {APIState, useApi} from "@plumeuk/shapeshift-identity";
import {Button, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

interface IProps {
	selected: number[],
	addToTrainingFeedData?: {course: {id: number, label: string}, dueDate: Date},
	onSubmit: (e: APIState<IAdminUserBulkActionAddToTrainingFeedResponse[]>) => void;
}

const useStyles = makeStyles()((theme) => ({
	buttonContainer: {
	},
	contentContainer: {
		flexGrow: 10
	},
	select: {
		//.mui-1ummrh6-MuiInputBase-root-MuiInput-root-MuiSelect-root-select:before
		":before": {
			borderBottom: "none"
		},
		marginLeft: "5px",
		height: "40px",
		"& fieldset": {
			borderColor: theme.palette.common.white
		},
		"& svg path": {
			fill: theme.palette.common.white
		}
	}
}));

type IAdminUserBulkActionAddToTrainingFeedResponse = any

export const AdminUsersBulkActionAddToTrainingFeedConfirmation: FC<IProps> = ({selected, addToTrainingFeedData, onSubmit}) => {
	const {classes} = useStyles();
	const [{data: previewRows, isLoading: previewIsLoading}, getPreviewRows] = useApi<IAdminUserBulkActionAddToTrainingFeedPreviewEntry[]>()
	const [submitResponse, submitApi] = useApi<IAdminUserBulkActionAddToTrainingFeedResponse[]>()
	const [workloadDateRage, setWorkloadDateRange] = useState<"day" | "week" | "bi-week">("bi-week");

	useEffect(() => {
		if(!open || !addToTrainingFeedData?.course)
			return;

		let workloadStart = new Date();
		let workloadEnd = new Date();

		workloadStart.setHours(0,0,0,0)
		workloadStart = new Date(workloadStart.getTime() - workloadStart.getTimezoneOffset() * 60000);

		workloadEnd.setHours(0,0,0,0)
		workloadEnd.setDate(workloadEnd.getDate() + (workloadDateRage === "day" ? 1 : workloadDateRage === "week" ? 7 : 14));
		workloadEnd = new Date(workloadEnd.getTime() - workloadEnd.getTimezoneOffset() * 60000);

		getPreviewRows({
			url: "/api/user/bulk/enroll/preview",
			data: {
				course: encodeURIComponent(JSON.stringify(addToTrainingFeedData.course)),
				dueDate: addToTrainingFeedData?.dueDate,
				users: encodeURIComponent(JSON.stringify(selected)),
				workloadStart,
				workloadEnd
			}
		})
	}, [selected, addToTrainingFeedData, open, workloadDateRage])

	const handleSubmit = useCallback(() => {
		if(!open || !addToTrainingFeedData?.course)
			return;

		submitApi({
			url: "/api/user/bulk/enroll",
			method: "POST",
			data: {
				course: addToTrainingFeedData.course,
				dueDate: addToTrainingFeedData?.dueDate,
				users: selected
			}
		})
	}, [addToTrainingFeedData])

	useEffect(() => {
		if(submitResponse.statusCode === 200)
			onSubmit(submitResponse)
	}, [submitResponse.statusCode])

	return (
		<Box>
			<Typography id="modal-modal-title" variant="h6" component="h2">
				Are you sure you want to Enroll the {selected.length} selected user{(selected.length > 1 ? "s" : "")} onto {addToTrainingFeedData?.course.label}?
			</Typography>
			<Box padding={2}/>
			<Box className={classes.contentContainer}>
				<TableContainer component={Paper}>
					<Table aria-label="enrollment preview">
						<TableHead>
							<TableRow>
								<TableCell>User</TableCell>
								<TableCell>Workload
									{/* to do: remove on confirmation */}
									{/* <Select
											variant="standard"
											className={classes.select}
											labelId="bulk-action-select-label"
											id="bulk-action-select"
											value={workloadDateRage}
											onChange={(e) => setWorkloadDateRange(e.target.value as any)}
										>
											{["day", "week", "bi-week"]?.map((e, i) => (<MenuItem key={i} value={e}>{e}</MenuItem>))}
										</Select> */}
								</TableCell>
								<TableCell align="right">Already Enrolled</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{previewRows?.map((row) => (
								<TableRow
									key={row.user.id}
									sx={{"&:last-child td, &:last-child th": {border: 0}}}
								>
									<TableCell component="th" scope="row">
										{row.user.email}
									</TableCell>
									<TableCell>
										{row.workload.before > 0 && `+${row.workload.before}`}
										{row.workload.before < 0 && <Error />}
										{row.workload.before === 0 && <Check />}
										<ArrowRightAlt/>
										{row.workload.after > 0 && `+${row.workload.after}`}
										{row.workload.after < 0 && <Error />}
										{row.workload.after === 0 && <Check />}
									</TableCell>
									<TableCell align="right">{row.alreadyEnrolled ? <Check /> : "-"}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{previewIsLoading && <LinearProgress />}
			</Box>
			<Box className={classes.buttonContainer}>
				<Button onClick={handleSubmit}>Confirm</Button>
			</Box>
		</Box>
	);
}