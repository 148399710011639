import {QuizDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {QuizRouter} from "./QuizRouter";
import {ModuleNotAvailable} from "@plumeuk/shapeshift-common/module";
import {LinearProgress} from "@mui/material";

const useStyles = makeStyles()((_theme) => ({
	centeredContainer: {
		padding: "20px",
		maxWidth: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center"
	}
}));

export const QuizPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: quizSlug} = useParams();
	return (
		<CenteredContainer className={classes.centeredContainer}>
			<QuizDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
				{({quiz, apiResult}) =>
					(apiResult.isLoading || quiz?.slug !== quizSlug) ? <LinearProgress />
						: apiResult.statusCode === 403
							? <ModuleNotAvailable title="This content will be available soon.">
								{apiResult.errorData?.error.message}.
							</ModuleNotAvailable>
							: <QuizRouter quiz={quiz}/>}
			</QuizDataProvider>
		</CenteredContainer>
	);
}