import {ValidationDoc} from "@plumeuk/use-validate";
import {IQuizEntityPayload} from "@plumeuk/shapeshift-types";

export const cohortQuizValidationDoc: ValidationDoc<IQuizEntityPayload> = {
	title: [{
		errorMessage: "required",
		validate: e => !!e.title
	}],
	scoreRequired: [{
		errorMessage: "required",
		validate: e => !!e.title
	}]
}

