import Box from "@mui/material/Box";
import {FC, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Close} from "@mui/icons-material";
import {palette} from "../../../../../constants";
import {Button, Modal} from "@mui/material";
import {AdminUsersBulkActionAddToTrainingFeedForm} from "./AdminUsersBulkActionAddToTrainingFeedForm";
import {AdminUsersBulkActionAddToTrainingFeedConfirmation} from "./AdminUsersBulkActionAddToTrainingFeedConfirmation";

interface IProps {
	open: boolean,
	onClose: (reload?: boolean) => void,
	selected: number[]
}

const useStyles = makeStyles()((theme) => ({
	adminUsersBulkActionAddToTrainingFeedModal: {
		display: "flex",
		flexDirection: "column",
		padding: "20px",
		position: "fixed",
		minHeight: "50dvh",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 600,
		maxWidth: "calc(100% - 80px)",
		backgroundColor: palette.greySolid
	},
	closeIcon: {
		color: theme.palette.common.white,
		"&:hover": {
			color: palette.greySolid,
			cursor: "pointer"
		}
	},
	closeIconContainer: {
		width: "100%",
		textAlign: "right"
	},
	nextBtn: {
		marginTop: "25px",
		width: "100px"
	}
}));

export const AdminUsersBulkActionAddToTrainingFeedModal: FC<IProps> = ({open, selected, onClose}) => {
	const {classes} = useStyles();
	const [state, setState] = useState<"request" | "confirm">("request");
	const [addToTrainingFeedData, setAddToTrainingFeedData] = useState<{course: {id: number, label: string}, dueDate: Date}>();

	useEffect(() => {
		setAddToTrainingFeedData(undefined)
		setState("request")
	}, [open])

	return (
		<Modal
			open={open}
			onClose={() => onClose()}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box className={classes.adminUsersBulkActionAddToTrainingFeedModal}>
				<Box className={classes.closeIconContainer}>
					<Close className={classes.closeIcon} onClick={() => onClose()}/>
				</Box>
				{state === "request" && <AdminUsersBulkActionAddToTrainingFeedForm selected={selected} onChange={e => setAddToTrainingFeedData(e)}/>}
				{(state === "confirm" && addToTrainingFeedData) && <AdminUsersBulkActionAddToTrainingFeedConfirmation selected={selected} addToTrainingFeedData={addToTrainingFeedData} onSubmit={() => onClose(true)}/>}
				{addToTrainingFeedData?.course && addToTrainingFeedData?.dueDate && state === "request" && <Button className={classes.nextBtn} onClick={() => setState("confirm")}>Next</Button>}
			</Box>
		</Modal>
	);
}