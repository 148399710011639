import {makeStyles} from "tss-react/mui";
import {Box, BoxProps, Typography} from "@mui/material";
import {palette} from "../../constants";

interface IProps extends BoxProps {
	icon?: React.ReactElement,
	children: string
}

const useStyles = makeStyles()((theme) => ({
	badge: {
		height: "fit-content",
		background: palette.grey15,
		padding: "8px 15px 7px 15px",
		borderRadius: "200px",
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
		"& p": {
			padding: 0,
			textWrap: "nowrap",
			fontWeight: 600,
			margin: 0,
			fontSize: "13px"
		}
	},
	innerContainer: {
		display: "flex",
		gap: "4px",
		justifyContent: "space-around"
	}
}));


export const Badge: React.FC<IProps> = ({icon, children, className, ...props}) => {
	const {classes, cx} = useStyles();
	return (
		<Box className={cx(classes.badge, className)} {...props}>
			<Box className={classes.innerContainer}>
				{icon}
				<Typography>{children}</Typography>
			</Box>
		</Box>
	);
}