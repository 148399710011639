import {makeStyles} from "tss-react/mui";
import {Box, BoxProps, Button} from "@mui/material";
import {forwardRef, useCallback, useContext, useEffect, useState} from "react";
import {TrainingfeedContext} from "../../contexts/trainingFeedContext";
import {DayContainer} from "../../pages/TrainingFeedPage/DayContainer";
import {OverdueModules} from "../../pages/TrainingFeedPage/OverdueModules";
import {ScheduledDayModules} from "../../pages/TrainingFeedPage/ScheduledDayModules";
import {palette} from "../../constants";

interface IProps extends BoxProps {
}

const useStyles = makeStyles()((theme) => ({
	trainingFeedSideMenuContent: {
		padding: "15px",
		position: "relative"
	},
	tapToLoadBtn: {
		width: "100%"
	},
	dayContainer: {
		position: "relative",
		"[class*='dayIconContainer'] [class*='dayIcon']": {
			background: palette.drawer
		}
	},
	tapToLoadBtnContainer: {
		width: "100%",
		bottom: "-50px",
		left: 0,
		padding: "100px 35px 35px 55px",
		boxSizing: "border-box",
		position: "absolute",
		background: "linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, " + palette.drawer + " 60%)"

	},
	placeholderModule: {
		width:  "100%",
		borderRadius: "16px",
		background: palette.grey05,
		padding: "12px",
		display: "flex",
		gap: "12px",
		"& > div": {
			background: palette.grey05,
			width: "90px",
			borderRadius: "5px",
			height: "50px"
		},
		"& > div:nth-of-type(2)": {
			background: palette.grey05,
			flexGrow: 2,
			marginTop: "5px",
			borderRadius: "5px",
			height: "15px"
		}
	}
}));

const yesterday = new Date();
yesterday.setUTCDate(yesterday.getUTCDate() - 1);

const tomorrow = new Date();
tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);

const next = new Date();
next.setUTCDate(next.getUTCDate() + 2);

export const TrainingFeedSideMenuContent = forwardRef<HTMLDivElement, IProps>(function TrainingFeedSideMenuContent({className, ...props}, ref) {
	const {classes, cx} = useStyles();
	const {trainingfeedState: curriculumState, trainingfeedDispatch: curriculumDispatch} = useContext(TrainingfeedContext)
	const overDueModules = (typeof curriculumState?.["overdue"] === "string" ? [] : curriculumState?.["overdue"] ) ?? [];

	const [nextDayToLoad, setNextDayToLoad] = useState<Date>(() => {
		const e = new Date();
		e.setUTCDate(e.getUTCDate() + 3);
		return e;
	});

	const handleRequestDay = useCallback(() => {
		curriculumDispatch({type: "getDay", day: nextDayToLoad})
		const newDate = new Date(nextDayToLoad)
		newDate.setUTCDate(newDate.getUTCDate() + 1);
		setNextDayToLoad(newDate)
	}, [nextDayToLoad])

	useEffect(() => {
		if(!curriculumState?.[next.getTime()]){
			curriculumDispatch({type: "getDay", day: next})
		}
	})

	const hasOverdue = (overDueModules?.length ?? 0) > 0 ;
	return (
		<Box ref={ref} className={cx(classes.trainingFeedSideMenuContent, className)} {...props}>
			{hasOverdue &&
				<DayContainer key={"overdue_day_container"} slim modules={overDueModules} className={classes.dayContainer} showMonthLabel day={yesterday}>
					<OverdueModules slim modules={overDueModules}/>
				</DayContainer>
			}
			{Object.keys(curriculumState ?? {}).filter(e => e !== "overdue").map((day, i) => {

				if(day === "overdue")
					return <></>

				const dayDate = new Date(parseInt(day))
				dayDate.setHours(0, 0, 0, 0);

				const modules = curriculumState?.[day];

				return <DayContainer isLoading={modules === "loading"} slim showMonthLabel={!hasOverdue && i === 1} className={classes.dayContainer} key={day} day={dayDate}>
					<ScheduledDayModules
						slim
						isLoading={modules === "loading"}
						data={(typeof modules === "string" ? [] : modules) ?? []}
						day={next}
					/>
				</DayContainer>
			})}

			<DayContainer key={"next_day_container"} slim className={classes.dayContainer} day={nextDayToLoad}>
				<Box className={classes.placeholderModule}>
					<Box></Box>
					<Box></Box>
				</Box>
				<Box className={classes.tapToLoadBtnContainer}>
					<Button onClick={handleRequestDay} className={classes.tapToLoadBtn}>Tap to load</Button>
				</Box>
			</DayContainer>
		</Box>
	);
});