import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {AdminHeader} from "../AdminHeader";

const useStyles = makeStyles()((theme) => ({
	adminSupportPage: {

	}

}));

export const AdminSupportPage: FC = () => {
	const {classes} = useStyles();

	return (
		<Box className={classes.adminSupportPage}>
			<AdminHeader title="Support" subtitle="TO DO"/>
		</Box>
	);
}