import {FC, useEffect} from "react";
import {Box, BoxProps} from "@mui/material";
import {AdminHeader} from "../../../AdminHeader";
import {IAdminUserFinishedCourses} from "../../../../../types/admin";
import {DataGridPremium, GridToolbar, GridColumns, GridFilterItem, GridLinkOperator, GridRowParams, GridSortModel, GridValidRowModel, MuiEvent} from "@mui/x-data-grid-premium";
import {useApi} from "@plumeuk/shapeshift-identity";
interface ICustomProps {
	userId: number
}

export type IProps = Omit<BoxProps, "children"> & ICustomProps;

const headCells: GridColumns<IAdminUserFinishedCourses["data"][0]> = ([
	{
		field: "title",
		flex: 1,
		headerName: "Course",
		width: 200,
		type: "string"
	},
	{
		field: "dueOn",
		flex: 1,
		headerName: "Due on",
		width: 200,
		type: "date"
	},
	{
		field: "completedAt",
		flex: 1,
		headerName: "Completed on",
		width: 200,
		type: "date"
	},
	{
		field: "contentType",
		flex: 1,
		headerName: "Content Type",
		width: 200,
		type: "string"
	}
])

export const UserFinishedCoursesTab:FC<IProps> = ({userId, ...baseProps}) => {
	const [rows, getRows] = useApi<IAdminUserFinishedCourses>()

	useEffect(() => {
		getRows(`/api/user/admin/${userId}/finishedCourses`)
	}, [userId])

	return <Box {...baseProps}>
		<AdminHeader title="User's Finished Courses" />
		<Box>
			<DataGridPremium<IAdminUserFinishedCourses["data"][0]>
				initialState={{
					sorting: {
						sortModel: [{field: "completedAt", sort: "desc"}]
					}
				}}
				aria-label="admin table"
				loading={rows.isLoading}
				pagination
				rowCount={rows?.data?.totalCount ?? 0}
				rows={rows?.data?.data ?? []}
				columns={headCells}
				rowsPerPageOptions={[5, 10, 20, 100]}
				components={{Toolbar: GridToolbar}}
			/>
		</Box>


	</Box>
}
