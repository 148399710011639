import {FC, useContext, useRef} from "react";
import {INotificationSeverity as Severity} from "@plumeuk/shapeshift-identity";
import {NotificationContext} from "@plumeuk/shapeshift-identity";
import {PageBase} from "../../components/pageBase/pageBase";
require("bitmovin-player-ui/dist/css/bitmovinplayer-ui.min.css");

const conf = {
	key: "YOUR KEY HERE",
	ui: false
};
const source = {
	hls: "https://streams.bitmovin.com/cpo43glsrjnnig8jf160/manifest.m3u8"
};

export const SandboxPage: FC = () => {
	const {notify} = useContext(NotificationContext);

	const vContainerRef = useRef<HTMLDivElement>(null)
	const sendNotification = (status: string, severity: Severity, lifeTime: null | number = 5000): void =>
		notify("Test " + status + " notification", "Something has happened", severity, lifeTime)

	const player: any = null;

	// useEffect(() => {
	// 	load();
	// 	return () => {
	// 		unLoad();
	// 	};
	// }, [])


	// const load = async (): Promise<void> => {
	// 	unLoad();
	// 	if(!vContainerRef)
	// 		return;

	// 	if (Player) {
	// 		player = new Player(document.getElementById("player") as any, conf);

	// 		UIFactory.buildDefaultUI(player);
	// 		player.load(source).then(function () {
	// 			console.log("Successfully loaded source");
	// 		}, function () {
	// 			console.log("Error while loading source");
	// 		});
	// 	}
	// }

	const unLoad = async (): Promise<void> => {
	// 	if (MKPlayerSDK) {
	// 		if (player) {
	// 			await player?.unload?.();
	// 			player = null;
	// 		}
	// 	}
	}

	const setPIP = (): void => {

	}
	return (
		<PageBase>
			<div>
				This page is used to test functionality and will be removed prior to release
				<br /><br />
				Timeout (5s)
				{/* <div id="player" ref={vContainerRef} className="mk-player"> */}
			</div>
			{/* <br />
			<div onClick={() => sendNotification("info", Severity.neutral)}>Click to test info notification</div>
			<br />
			<div onClick={() => sendNotification("warning", Severity.warning)}>Click to test warning notification</div>
			<br />
			<div onClick={() => sendNotification("success", Severity.success)}>Click to test success notification</div>
			<br />
			<div onClick={() => sendNotification("error", Severity.error)}>Click to test error notification</div>
			<br/>
			<div onClick={() => {
				sendNotification("info", Severity.neutral);
				sendNotification("warning", Severity.warning);
				sendNotification("success", Severity.success);
				sendNotification("error", Severity.error)
			}}>
					Click to test all notifications (x4)
			</div>
			<br />
			<div onClick={() => notify("This is a short message and it can span multiple lines if required", "Info", Severity.neutral, 15000)}>
					Click to test long body (15s)
			</div>
			<br />
			<div onClick={() => notify(null, "Info No Body", Severity.neutral, 120000)}>
					Click to no body (2min)
			</div>
			<br /><br />
				Permanent
			<br/>
			<div onClick={() => sendNotification("Permanent", Severity.neutral, null)}>Click to test permanent info notification (will not close)</div>
			<br />
			<div onClick={() => sendNotification("Permanent", Severity.warning, null)}>Click to test permanent warning notification (will not close)</div>
			<br />
			<div onClick={() => sendNotification("Permanent", Severity.success, null)}>Click to test permanent success notification (will not close)</div>
			<br />
			<div onClick={() => sendNotification("Permanent", Severity.error, null)}>Click to test permanent error notification (will not close)</div>
			<br />
			<br/>
			<br />
			<div onClick={() => {
				sendNotification("Permanent", Severity.neutral, null);
				sendNotification("Permanent", Severity.warning, null);
				sendNotification("Permanent", Severity.success, null);
				sendNotification("Permanent", Severity.error, null)
			}}>
					Click to test permanent all notifications (x4) (will not close)
			</div>

			<Button onClick={() => setPIP()}>PIP</Button> */}

		</PageBase>
	);

}