import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {palette} from "../../constants";
import {MyProgressPageContainer} from "./MyProgressPageContainer";
import {MyCourses} from "./MyCourses";
import {MyRecentCompletedModules} from "./MyRecentCompletedModules";
import {MyFinishedCourses} from "./MyFinishedCourses";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useState} from "react";
import {PageTitle} from "../../components/pageBase/pageTitle";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		[theme.breakpoints.up("lg")]:{
			"& [class*='menuIcon']": {
				display: "none"
			}
		}
	},
	container: {
		[theme.breakpoints.up("sm")]:{
			padding: "0 20px"
		}
	},
	centeredContainer: {
		display: "grid",
		gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
		justifyContent: "stretch",
		marginLeft: "-10px",
		marginRight: "-10px",
		[theme.breakpoints.down("lg")]: {
			margin: "0 0px"
		}
	},
	containerTitle: {
		fontSize: "21px",
		fontWeight: 600
	},
	containerSubtitle: {
		fontSize: "17px",
		fontWeight: 400

	},
	dataContainer: {},
	tabs: {
		height: "70px",
		minHeight: "70px",
		borderBottom: palette.grey10 + " 1px solid",
		width: "100%",
		padding: "0 15px"
	}
}));


export const MyProgressPage: React.FC = () => {
	const {classes} = useStyles();
	const mq = useMq();
	const [tab, setTab] = useState<"recent" | "active" | "complete">("recent");

	return (
		<PageBase className={classes.pageBase} contentWidth="1260px" disableSideBar>
			<Box className={classes.container}>
				<PageTitle title="My Progress" enableDayStreak/>
				{mq.mobile && <Tabs variant="scrollable" className={classes.tabs} value={tab} onChange={(_e, v) => setTab(v)} >
					<Tab label="Recent Activity" value="recent"/>
					<Tab label="Active Courses" value="active"/>
					<Tab label="Finished Courses" value="complete"/>
				</Tabs>}
				<Box className={classes.centeredContainer}>
					{(!mq.mobile || tab == "recent") && <MyProgressPageContainer title="Recent Activity" subtitle="See what you&apos;ve completed so far">
						<MyRecentCompletedModules />
					</MyProgressPageContainer>}
					{(!mq.mobile || tab == "active") && <MyProgressPageContainer title="Active Courses" subtitle="Jump back into courses you participate in">
						<MyCourses />
					</MyProgressPageContainer>}
					{(!mq.mobile || tab == "complete") && <MyProgressPageContainer title="Finished Courses" subtitle="See what courses you’ve completed so far">
						<MyFinishedCourses />
					</MyProgressPageContainer>}
				</Box>
			</Box>
		</PageBase>
	);
}