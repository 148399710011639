import React, {useState, useEffect, useContext} from "react";
import ModuleSearch from "./moduleSearch";
import CourseCurriculumNewModuleForm from "./courseCurriculumNewModuleForm";
import {IFlatList} from "@plumeuk/shapeshift-types";
import {makeStyles} from "tss-react/mui";
import {ICurriculumItemImport} from "../../../../../types/curriculum";
import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import {Modal} from "@plumeuk/shapeshift-common/modal";
import {palette, toolbarHeight} from "../../../../../constants";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";

export interface IProps {
	currentModules?: IFlatList[],
	typeFilter?: string,
	selectLimit?: number,
	onClose: (modules?: ICurriculumItemImport[]) => void,
}

const useStyles = makeStyles()(
	(_theme) => ({
		newModuleModal: {
			zIndex: 50
		},
		tabs: {
			height: "70px",
			minHeight: "70px",
			borderBottom: palette.grey10 + " 1px solid",
			width: "100%",
			"& button": {
				width: "calc(50% - 8px)"
			}
		},
		container: {
			display: "flex",
			gap: "40px",
			flexDirection: "column"
		},
		body: {},
		selectedCountFooter: {
			color: "grey",
			paddingRight: "15px",
			fontSize: "12px"
		},
		header: {
			display: "flex",
			marginTop: toolbarHeight,
			justifyContent: "space-between !important"
		},
		title: {
			flexGrow: 2
		},
		fullScreen: {
			paddingRight: "20px",
			cursor: "pointer",
			"& span":{
				marginLeft: "10px"
			}
		}
	})
);

interface IFilterMap {
	[type: string]: {[slug: string]: boolean}
}

const NewModuleModal: React.FunctionComponent<IProps> = (props) => {
	const {classes} = useStyles();
	const [selected, setSelected] = useState<ICurriculumItemImport[]>([]);
	const [filterMap, setFilterMap] = useState<IFilterMap>();
	const [tab, setTab] = useState<"existing" | "new">("existing");
	const {notify} = useContext(NotificationContext);

	const handleNewModuleSubmission = (module: ICurriculumItemImport): void => {
		setSelected(prev => {
			if(props.selectLimit && ((prev.length + 1) > props.selectLimit)){
				notify("Only " + props.selectLimit + "can be selected","", INotificationSeverity.error, 5000)
			}

			return ([...prev, module])}
		);
	}

	//Create filter map for rapid lookup
	useEffect(() => {
		if (!props.currentModules)
			return;

		const map: IFilterMap = {};
		props.currentModules.forEach(e => {
			if (!map[e.type]) map[e.type] = {}
			map[e.type][e.slug] = true;
		});

		setFilterMap(map)
	}, [props.currentModules])

	return (
		<Modal defaultFullscreen open={true} className={classes.newModuleModal} onClose={() => props.onClose()} footerButtons={[
			<Button key="cancel" onClick={() => props.onClose()}>
				Cancel
			</Button>,
			selected.length > 0 ? <Typography key="stat" className={classes.selectedCountFooter}>Selected {selected.length} modules</Typography> : <></>,
			<Button key="save" disabled={selected.length === 0} onClick={() => props.onClose(selected?.length > 0 ? selected : undefined)}>Add</Button>

		]} >
			<Box className={classes.container}>
				<Box className={classes.header}>
					<Typography className={classes.title} variant="h2" id="title">
						Add {props.typeFilter?.split(".")?.pop() ?? "Module"}
					</Typography>
				</Box>
				<Box className={classes.body}>

					<Tabs className={classes.tabs} value={tab} onChange={(_e, v) => setTab(v)}>
						<Tab label="Create New" value={"new"}/>
						<Tab label="Import" value={"existing"}/>
					</Tabs>

					<br /><br /><br />

					<ModuleSearch
						style={{display: tab === "existing" ? "block" : "none"}}
						selected={selected}
						typeFilter={props.typeFilter}
						additionalfilter={e => !filterMap || filterMap[e.type]?.[e.slug] === undefined}
						onChange={e => setSelected(e as any)}
					/>

					<CourseCurriculumNewModuleForm
						typeFilter={props.typeFilter}
						style={{display: tab === "new" ? "block" : "none"}}
						onSubmit={e => handleNewModuleSubmission(e)}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default NewModuleModal;
