import {Box, BoxProps} from "@mui/material"
import {FC} from "react"
import {makeStyles} from "tss-react/mui";

type IProps = BoxProps;

const useStyles = makeStyles()((theme) => ({
	moduleSummaryCardSkeleton: {
		borderRadius: "16px",
		textAlign: "left",
		overflow: "hidden",
		boxSizing: "border-box",
		height: "94px",
		width:  "100%",
		background: theme.palette.background.paper,
		padding: "20px",
		flexGrow: 2
	}
}));

export const ModuleSummaryCardSkeleton:FC<IProps> = ({className}) => {
	const {classes, cx} = useStyles();

	return (
		<Box className={cx(classes.moduleSummaryCardSkeleton, className)}>
		</Box>
	)
}