import React, {useState} from "react";
import {ICohortEntity} from "@plumeuk/shapeshift-types";
import {makeStyles} from "tss-react/mui";
import {Box, Button, Typography} from "@mui/material";
import {Modal} from "@plumeuk/shapeshift-common/modal";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";
import {GridLinkOperator} from "@mui/x-data-grid-premium";

export interface IProps {
	currentSelected?: number[],
	onClose: (ids?: number[]) => void,
}

const cohortBaseUrl = "/api/strapi-plugin-shapeshift-lms/cohort";

const useStyles = makeStyles()(
	(_theme) => ({
		newLibraryImportModal: {
		},
		container: {
			display: "flex",
			gap: "40px",
			flexDirection: "column"
		},
		body: {},
		selectedCountFooter: {
			color: "grey",
			paddingRight: "15px",
			fontSize: "12px"
		},
		header: {
			display: "flex",
			justifyContent: "space-between !important"
		},
		title: {
			flexGrow: 2
		},
		fullScreen: {
			paddingRight: "20px",
			cursor: "pointer",
			"& span":{
				marginLeft: "10px"
			}
		}
	})
);

const headCells: AdminTableProps<"cohort", ICohortEntity>["columns"] = ([
	{
		field: "title",
		flex: 1,
		headerName: "Library",
		type: "string"
	}
]);

const NewCohortLibraryImportModal: React.FunctionComponent<IProps> = (props) => {
	const {classes} = useStyles();
	const [selected, setSelected] = useState<number[]>(props.currentSelected ?? []);

	return (
		<Modal width={700} open={true} className={classes.newLibraryImportModal} onClose={() => props.onClose()} footerButtons={[
			<Button key="cancel" onClick={() => props.onClose()}>
				Cancel
			</Button>,
			<Button key="save" onClick={() => props.onClose(selected)}>Add</Button>
		]} >
			<Box className={classes.container}>
				<Box className={classes.header}>
					<Typography className={classes.title} variant="h2" id="title">
						Import Libraries
					</Typography>
				</Box>
				<Box className={classes.body}>
					<AdminTable<"cohort", ICohortEntity>
						url={cohortBaseUrl}
						columns={headCells}
						type="cohort"
						searchDTOverride={{filters: {operator: GridLinkOperator.And, conditions: selected.map(e => ({attribute: "id", operator: "ne", value: e}))}}}
						onSelectionModelChange={e => setSelected(e as number[])}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default NewCohortLibraryImportModal;
