import {Box, BoxProps, LinearProgress, Typography} from "@mui/material";
import {FC, useRef, useState, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {ICourse} from "@plumeuk/shapeshift-types";
import {ExpandMore} from "@mui/icons-material";
import {CourseModulesDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useCourseProgress} from "@plumeuk/shapeshift-common/hooks";
import {palette} from "../../constants";
import {OutlineButton} from "@plumeuk/shapeshift-common/buttons";
import {ModuleSummaryCard} from "../module/ModuleSummaryCard";
import {ModuleSummaryCardSkeleton} from "../module/ModuleSummaryCardSkeleton";
import {Link} from "react-router-dom";

export interface IPropsComponentOverrides {
	icon?: JSX.Element,
	rightAccessory?: JSX.Element
}

interface IPropsCustom {
	course: ICourse;
}

export type IProps = BoxProps & IPropsCustom;

interface IStyleProps {
	isOpen: boolean
}

const useStyles = makeStyles<IStyleProps>()((
	theme,
	{isOpen}
) => ({
	myCoursesSlider: {
		padding: "20px",
		border: "1px solid " + palette.grey08,
		borderRadius: "16px",
		background: "#282828"
	},
	contentContainer: {

	},
	topContainer: {
		zIndex: 10,
		display: "flex",
		gap: "25px",
		flexDirection: "column"
	},
	titleContainer: {
		display: "flex",
		cursor: "pointer",
		gap: "5px",
		borderBottom: "1px solid " + palette.grey10
	},
	progressContainer: {
		padding: "0 0px",
		display: "flex",
		gap: "9px",
		"div:nth-of-type(1)": {
			flexGrow: 2,
			padding: "9px 0 0 0"
		},
		"span": {
			height: "6px"
		}
	},
	resumebtn: {
		width: "100%",
		color: theme.palette.common.white
	},
	progressPercentValue: {
		color: theme.palette.common.white
	},
	modulesParentContainer: {
	},
	moduleContainer: {
		paddingTop: "24px",
		display: "grid",
		gap: "20px"
	},
	icon: {
	},
	expandIcon: {
		fill: theme.palette.primary.main,
		fontSize: "24px",
		[theme.breakpoints.down("sm")]: {
			display: "none"
		},
		margin: "-2px 0 0",
		color: theme.palette.secondary.main,
		transform: `rotate(${isOpen ? "0deg" : "180deg"})`,
		transition: "transform 0.2s linear"
	},
	module: {
	},
	section: {
	},
	courseTitle: {
		paddingBottom: "18px",
		fontSize: "21px",
		fontWeight: 600,
		flexGrow: 2,
		overflow: "hidden",
		textWrap: "nowrap",
		textOverflow: "ellipsis",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center"
		}

	},
	moduleSkeleton: {
		background: theme.palette.grey[200],
		height: "340px"
	}
}));

export const MyCoursesSlider: FC<IProps> = ({
	course, className, ...props
}) => {

	const [isOpen, setIsOpen] = useState(false);
	const {classes, cx} = useStyles({isOpen});
	const accordianIconRef = useRef<SVGSVGElement>(null);
	const {progress, apiResult: progressApiResult, getProgress} = useCourseProgress(course.slug)
	const resumeBtnContainerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if(course)
			getProgress(course.slug)
	}, [course])

	const infoArray = [
		isOpen ? "isOpen" : ""
	]

	const handleOpenAccordian = (e: React.MouseEvent<HTMLDivElement>): void => {
		if(resumeBtnContainerRef.current && !resumeBtnContainerRef.current.contains(e.target as Node))
			setIsOpen(prev => !prev)
	}

	const dataTestId = `my-modules-slider-section-${module.id ?? 0}`
	return (

		<Box
			className={cx(classes.myCoursesSlider, className, classes.section, infoArray)}
			data-test-id={dataTestId}
			{...props}
		>
			<Box data-test-id={`${dataTestId}-content-container`} className={classes.contentContainer}>

				<Box
					className={classes.topContainer}
					data-test-id={`${dataTestId}-expand-section`}
					onClick={handleOpenAccordian}
				>
					<Box className={classes.titleContainer}>
						<Typography variant="h3" className={classes.courseTitle}>{course.title}</Typography>
						<ExpandMore
							data-test-id={`${dataTestId}-expand-more-btn`}
							ref={accordianIconRef}
							className={classes.expandIcon}
						/>
					</Box>
					<Box className={classes.progressContainer}>
						<Box>
							<LinearProgress variant={progressApiResult.isLoading ? "indeterminate" : "determinate"} value={progress ?? 0}/>
						</Box>
						<Box className={classes.progressPercentValue}>
							{(progress !== undefined) && <Typography>{progress}%</Typography>}
						</Box>
					</Box>
					<Box ref={resumeBtnContainerRef} >
						<Link to={"/course/" + course.slug}>
							<OutlineButton className={classes.resumebtn}>Resume</OutlineButton>
						</Link>
					</Box>
				</Box>
			</Box>
			<Box className={classes.modulesParentContainer} style={{height: isOpen ? "auto" : "0px"}}>
				{isOpen && <CourseModulesDataProvider courseSlug={course.slug}>
					{({courseModules, apiResult}) =><Box key={module.id} className={classes.moduleContainer}>
						{(courseModules === undefined || apiResult.isLoading) && ([1,1,1,1])?.map((e: any) => <ModuleSummaryCardSkeleton key={e.id} />)}
						{(courseModules?.map(module => (
							<Link to={`/course/${course.slug}/${module.type}/${module.slug}`} key={`${module.type}__${module.id}`}>
								<ModuleSummaryCard module={module} />
							</Link>
						)))}
						{(courseModules && courseModules?.length === 0) && <Box padding={3} sx={{textAlign: "center"}}><Typography>No modules found</Typography></Box>}
					</Box>}
				</CourseModulesDataProvider>}
			</Box>
		</Box>
	);
};

export default MyCoursesSlider;
