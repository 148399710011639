import {FC, useState, useEffect, useRef, useCallback} from "react";
import VideoUploadControls from "./videoUploadControls";
import {Box, Typography} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";
import UploadVideoModal from "./videoUploadModal";
import {makeStyles} from "tss-react/mui";
import {IEntity} from "@plumeuk/shapeshift-types";
import {deepEqual} from "@plumeuk/shapeshift-common/common";
import {BITMOVIN_VIDEO_DATA} from "../../../../../types/admin";
import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {Player, PlayerAPI, PlayerConfig} from "bitmovin-player/modules/bitmovinplayer-core";
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin";
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer";
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls";
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash";
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr";
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml";
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts";
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4";
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles";
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608";
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill";
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style";
import "bitmovin-player/bitmovinplayer-ui.css";
import {UIFactory} from "bitmovin-player-ui";

export type IProps = {
	value?: BITMOVIN_VIDEO_DATA | null,
	videoUrl?: string,
	videoThumbnailUrl?: string,
	onChange: (e: BITMOVIN_VIDEO_DATA | null) => void,

} & { entity?: IEntity, initialValue?: BITMOVIN_VIDEO_DATA | null };

const useStyles = makeStyles()((theme) => ({
	container: {
	},
	label: {
		fontWeight: 600,
		fontSize: "0.75rem"
	},
	content: {
		padding: "30px"
	},
	assetAttachedLabel: {
		display: "flex",
		gap: "10px",
		"& svg": {
			color: theme.palette.success.main
		}
	},
	videoPlayer: {

	}
}));

const AdminVideoUpload: FC<IProps> = ({onChange, initialValue, entity, value, videoThumbnailUrl, videoUrl}) => {
	const {classes} = useStyles();
	const [uploadVideoModalOpen, setUploadVideoModalOpen] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false);

	const videoRef = useRef<VideoPlayerRef>(null);
	const [player, setPlayer] = useState<PlayerAPI | null>(null);
	(global as any).playerx = player;
	const [videoFinished, setVideoFinished] = useState(false);
	const [videoStarted, setVideoStarted] = useState(false);

	const handleVideoReady = useCallback((videoUrl: string, videoThumbnailUrl?: string) => {
		if(!process.env.REACT_APP_BITMOVIN_PLAYER_KEY)
			throw new Error("REACT_APP_BITMOVIN_PLAYER_KEY required")

		const playerConfig: PlayerConfig = {
			key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY,
			analytics: {
				key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY
			}
		};

		if(!videoRef.current?.bitmovin?.container)
			return;

		const playerSource = {
			hls: videoUrl,
			poster: videoThumbnailUrl
		};

		Player.addModule(EngineBitmovinModule);
		Player.addModule(MseRendererModule);
		Player.addModule(HlsModule);
		Player.addModule(XmlModule);
		Player.addModule(DashModule);
		Player.addModule(AbrModule);
		Player.addModule(ContainerTSModule);
		Player.addModule(ContainerMp4Module);
		Player.addModule(SubtitlesModule);
		Player.addModule(SubtitlesCEA608Module);
		Player.addModule(PolyfillModule);
		Player.addModule(StyleModule);

		const playerInstance = new Player(videoRef.current.bitmovin?.container, playerConfig);
		UIFactory.buildDefaultUI(playerInstance);
		playerInstance.load(playerSource).then(() => {
			setPlayer(playerInstance)
			console.log("Successfully loaded source");
		}, () => {
			console.log("Error while loading source");
		});
	}, [])


	const handleClose = (e?: BITMOVIN_VIDEO_DATA): void => {
		setUploadVideoModalOpen(false)
		if(e){
			onChange(e)
		}
	}

	useEffect(() => {
		setIsUpdate(!deepEqual(initialValue, value))
	}, [initialValue, value])

	if(!entity?.id)
		return <Box padding={3}><Typography>Please save to enable video upload</Typography></Box>

	return (
		<>
			<VideoUploadControls value={value} onUploadVideo={() => setUploadVideoModalOpen(true)} />
			{!isUpdate && value && <Box className={classes.content}>
				<Typography>
					{(value.status === "ENCODING" || value.status === "PENDING") && "The video is currently being processed and will be available for streaming shortly"}
					{(value.status === "ERRORED" || value.status === "CANCELED") && "Something has gone wrong. Please upload a new video to try again."}
					{value.status === "FULFILLED" && "The encoding process is complete and the media is available for streaming!"}
				</Typography>
				<Box>
					<br />
					<Box className={classes.assetAttachedLabel}>
						<CheckCircle /> <Typography>Asset attached</Typography>
					</Box>
				</Box>
			</Box>}
			{(isUpdate && value?.status === "PENDING") && <Box className={classes.content}>
				<Typography>The video file has been uploaded, please save to attach to this lesson</Typography>
			</Box>}
			{!value && <Box padding={"30px"}><Typography>No file attached</Typography></Box>}
			<UploadVideoModal lessonId={entity?.id as number} open={uploadVideoModalOpen} onClose={(e) => handleClose(e)} />
			{(!isUpdate && videoUrl) && <VideoPlayer
				onReady={() => handleVideoReady(videoUrl, videoThumbnailUrl)}
				ref={videoRef}
				moduleVideoType={"bitmovin"}
				className={classes.videoPlayer}
				url={videoUrl}
				controls={true}
			/>}
		</>
	);
}

export default AdminVideoUpload;