import {createContext, useReducer, ReactElement, Reducer, useEffect, Dispatch, FC, useContext, useState} from "react";
import {IModuleScheduled} from "@plumeuk/shapeshift-types";
import {useOverdueModules, useScheduledModules} from "@plumeuk/shapeshift-common/hooks";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {LocaleContext} from "./localeContext";

const today = new Date();
today.setHours(0,0,0,0)

const tomorrow = new Date();
tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
tomorrow.setHours(0,0,0,0)

const next = new Date();
next.setUTCDate(next.getUTCDate() + 2);
next.setHours(0, 0, 0, 0)

const initialState: {trainingfeedState?: TrainingfeedContextState; trainingfeedDispatch: Dispatch<TrainingfeedContextAction>} = {trainingfeedState: undefined, trainingfeedDispatch: () => {}};

const TrainingfeedContext = createContext(initialState);

interface ITrainingFeed {[day: string | "overdue"]: (IModuleScheduled[] | "loading" | "errored")}

type TrainingfeedContextState = ITrainingFeed | undefined

type TrainingfeedContextAction = {
	type: "set";
	payload?: ITrainingFeed;
} | {
	type: "getDay",
	day: Date
} | {
	type: "setDay",
	day: Date,
	modules: IModuleScheduled[]
} | {
	type: "setOverdue",
	modules: IModuleScheduled[]
} | {
	type: "setModuleComplete",
	module: {courseSlug: string, type: string, slug: string},
	status: boolean
} | {
	type: "setDayErrored",
	day: Date
}

interface IProps {
	children: ReactElement
}

const TrainingfeedProvider: FC<IProps> = ({children}) => {
	const [{locale}] = useContext(LocaleContext);

	//By Default get overdue, today, tomorrow
	const {overdueModules, getOverdueModules} = useOverdueModules(locale);
	// const {scheduledModules: todayModules} = useScheduledModules(today, tomorrow);
	const {authenticated} = useContext(AuthContext)

	//initial call to additional is for tomorrow
	const {scheduledModules: additionalModules, getScheduledModules, apiResult: additionalModulesApiResult} = useScheduledModules(today, tomorrow, locale);
	const [dayRequested, setDayRequested] = useState<number>();

	const [trainingFeedState, trainingfeedDispatch] = useReducer<Reducer<TrainingfeedContextState, TrainingfeedContextAction>>((state: TrainingfeedContextState, action: TrainingfeedContextAction) => {
		switch (action.type) {
			case "set": {
				return {...action.payload};
			}
			case "getDay": {
				action.day.setHours(0, 0, 0, 0);
				if(!state)
					state = {};

				state[action.day.getTime()] = "loading"
				return {...state};
			}
			case "setDay": {
				if(!state)
					state = {};
				action.day.setHours(0, 0, 0, 0)
				state[action.day.getTime()] = action.modules
				return {...state};
			}
			case "setOverdue": {
				if(!state)
					state = {};
				state["overdue"] = action.modules
				return {...state};
			}
			case "setModuleComplete": {
				if(!state)
					state = {};

				//get module
				for(const day of Object.keys(state)){
					for(const module of state[day]){
						if(
							typeof module !== "string" &&
							module.course.slug === action.module.courseSlug &&
							module.type === action.module.type &&
							module.slug === action.module.slug)
						{
							module.complete = action.status;
						}
					}
				}
				return {...state};
			}
			case "setDayErrored": {
				if(!state)
					state = {};
				state[action.day.getTime()] = "errored"
				return state;
			}
			default:
				return state;
		}
	}, initialState.trainingfeedState);

	useEffect(() => {
		if(authenticated){
			trainingfeedDispatch({type: "set", payload: {
				"overdue": "loading",
				[today.getTime()]: "loading",
				[tomorrow.getTime()]: "loading",
				[next.getTime()]: "loading"
			}});
			setDayRequested(undefined)
			getOverdueModules(locale)
		}
	}, [authenticated, locale])

	useEffect(() => {
		if(additionalModules){
			const day = dayRequested ? new Date(dayRequested) : today;
			trainingfeedDispatch({type: "setDay", day, modules: additionalModules});
		}
	}, [additionalModules])

	useEffect(() => {
		if(overdueModules){
			trainingfeedDispatch({type: "setOverdue", modules: overdueModules});
		}
	}, [overdueModules])

	useEffect(() => {
		if(additionalModulesApiResult.isLoading)
			return;

		if(additionalModulesApiResult.isError)
			trainingfeedDispatch({type: "setDayErrored", day: dayRequested ? new Date(dayRequested) : today});

		//look for next day
		if(trainingFeedState){

			const nextDay = Object.keys(trainingFeedState ?? {}).sort((a,b) => {
				if (a === "overdue") return -1;
				if (b === "overdue") return 1;
				return Number(a) - Number(b);
			})?.filter(e => e !== "overdue" && trainingFeedState?.[e] === "loading")?.[0]

			if(!nextDay)
				return;

			const dayRequested = new Date(parseInt(nextDay));
			if(!dayRequested)
				return;

			setDayRequested(dayRequested.getTime())
		}
	}, [trainingFeedState, additionalModulesApiResult])

	useEffect(() => {
		if(!dayRequested)
			return;

		const day = new Date(dayRequested);
		const endDate = new Date(dayRequested);
		endDate.setUTCDate(endDate.getUTCDate() + 1);
		getScheduledModules(day, endDate, locale, {throttle: false})
	}, [dayRequested])

	return (
		<TrainingfeedContext.Provider value={{trainingfeedState: trainingFeedState, trainingfeedDispatch: trainingfeedDispatch}}>
			{children}
		</TrainingfeedContext.Provider>
	);
};

export {
	TrainingfeedContext,
	TrainingfeedProvider
};