import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {AdminHeader} from "../../AdminHeader";
import {IAdminUsersCoursesResponseEntry} from "../../../../types/admin";
import {useNavigate} from "react-router-dom";
import {useApi} from "@plumeuk/shapeshift-identity";
import {ILocalizationEntity} from "@plumeuk/shapeshift-types";
import {AdminTable, AdminTableProps} from "@plumeuk/shapeshift-common/admin";

const useStyles = makeStyles()((_theme) => ({
	adminCoursesList: {
		"& th": {
			fontSize: "11px",
			fontWeight: 700,
			textTransform: "uppercase"
		}
	}
}));

const headCells = (locales?: ILocalizationEntity[]): AdminTableProps<"course", IAdminUsersCoursesResponseEntry>["columns"] => ([
	{
		field: "title",
		type: "string",
		flex: 1,
		headerName: "Course",
		width: 200
	},
	{
		field: "tags",
		type: "string",
		flex: 1,
		headerName: "Tags",
		valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["tags"]}) => value?.join(", ")
	},
	{
		field: "moduleCount",
		type: "string",
		flex: 1,
		headerName: "Lessons"
	},
	{
		field: "enrollmentCount",
		type: "string",
		flex: 1,
		headerName: "Enrolled Employees"
	},
	{
		field: "completionCount",
		type: "string",
		flex: 1,
		headerName: "Course Completions"
	},
	{
		field: "contentType",
		type: "string",
		flex: 1,
		headerName: "Content Type"
	},
	{
		field: "languages",
		type: "string",
		flex: 1,
		headerName: "Languages",
		valueGetter: ({value}: {value: IAdminUsersCoursesResponseEntry["languages"]}) => value?.map(e => locales?.find(x => x.code === e)?.name)?.join(", ")
	},
	{
		field: "createdAt",
		type: "string",
		flex: 1,
		headerName: "Created",
		filterable: true,
		width: 100,
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	},
	{
		field: "publishedAt",
		type: "string",
		flex: 1,
		headerName: "Published",
		width: 100,
		filterable: true,
		valueGetter: (e) => e.value ? new Date(e.value).toLocaleDateString() : ""
	}
]);

export const AdminCoursesList: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [locales] = useApi<ILocalizationEntity[]>("/api/settings/i18n");

	return (
		<Box className={classes.adminCoursesList}>
			<AdminHeader
				title="Courses"
				subtitle="Manage courses, enroll them into courses and check their capacity"
				actionButtons={[{label: "Create New", onClick: () => navigate("create")}]}
			/>
			<AdminTable<"course", IAdminUsersCoursesResponseEntry>
				url="/api/strapi-plugin-shapeshift-lms/cohort/course"
				columns={headCells(locales.data)}
				onEditAction={e => navigate(e.id.toString())}
				enableDelete
				type="course"
			/>
		</Box>
	);
}