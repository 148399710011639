import {LinearProgress} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";

export const LoginPage: FC = () => {
	const {authenticated} = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if(authenticated === true) navigate("/training-feed")
	}, [authenticated])

	return <LinearProgress />
}