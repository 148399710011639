import ReactDOM from "react-dom/client";
import "./fonts/Roobert/RoobertFont.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {defaultTheme} from "./templates/defaultTheme";
import {ThemeProvider} from "@mui/material";
import {NotificationProvider} from "@plumeuk/shapeshift-identity";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import AuthProvider from "./AuthProvider";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

export const muiCache = createCache({
	key: "mui",
	prepend: true
});


root.render(
	<NotificationProvider>
		{/* <PropelauthAuthProvider authUrl={getPropelauthURl()}> */}
		<AuthProvider>
			<CacheProvider value={muiCache}>
				<ThemeProvider theme={defaultTheme}>
					<App />
				</ThemeProvider>
			</CacheProvider>
		</AuthProvider>
		{/* </PropelauthAuthProvider> */}
	</NotificationProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
