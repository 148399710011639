import {FC, useContext, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {Autocomplete, Box, Button, TextField, Typography} from "@mui/material";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {ICourseEntity, IPaginatedResponse} from "@plumeuk/shapeshift-types";
import {palette} from "../../../../../constants";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, {Dayjs} from "dayjs";

interface IProps {
	onChange?: (e: {course: {id: number, label: string}, dueDate: Date}) => void,
	selected?: number[]
}

const useStyles = makeStyles()((theme) => ({
	adminUsersBulkActionAddToTrainingFeedForm: {
		"& input": {
			background: palette.midGrey3 + "!important",
			color: "white !important"
		},
		"[class*='MuiFormControl']": {
			background: palette.midGrey3 + "!important",
			color: "white !important",
			padding: 0,
			margin: 0
		}
	},
	autoComplete: {
		"& fieldset": {
			borderColor: theme.palette.common.white
		},
		"& svg path": {
			fill: theme.palette.common.white
		},
		background: palette.midGrey3 + "!important",
		padding: 0,
		margin: 0
	},
	textField: {
		background: palette.midGrey3 + "!important",
		padding: 0,
		margin: 0,
		color: "white"
	},
	datePicker: {
		"& fieldset": {
			borderColor: theme.palette.common.white
		},
		"& svg path": {
			fill: theme.palette.common.white
		}
	}
}));

const tomorrow = new Date();
tomorrow.setHours(0,0,0,0)
tomorrow.setDate(tomorrow.getDate() + 1);

export const AdminUsersBulkActionAddToTrainingFeedForm: FC<IProps> = ({onChange, selected}) => {
	const {classes} = useStyles();
	const [courseSearch, setCourseSearch] = useState<string>("");
	const [{data: courses, isLoading}, getCourses] = useApi<IPaginatedResponse<ICourseEntity[]>>()
	const [value, setValue] = useState<{id: number, label: string} | null>(null);
	const [dueDate, setDateDue] = useState<Dayjs | null>(null);
	const {notify} = useContext(NotificationContext);

	useEffect(() => {
		getCourses({
			url: "/api/strapi-plugin-shapeshift-lms/cohort/course",
			data: {page: 1, pageSize: 10, searchTerm: courseSearch ?? "", excludeDraft: true, extendedAccess: true}
		})
	}, [courseSearch])

	useEffect(() => {
		if(value && dueDate && onChange){
			onChange({course: value, dueDate: dayjs(dueDate).toDate()})
		}
	}, [value, dueDate])

	const handleDueDateChange = (dueDate: Dayjs): void => {
		if(dueDate < dayjs(tomorrow)){
			notify("Please choose a date which is in the future", "", INotificationSeverity.warning, 10000)
			setDateDue(dayjs(tomorrow))
		}
		else setDateDue(dueDate)
	}

	return (
		<Box className={classes.adminUsersBulkActionAddToTrainingFeedForm}>
			<Typography id="modal-modal-title" variant="h6" component="h2">
				Enroll {selected?.length ?? 0} user{selected?.length === 1 ? "": "s"} onto course.
			</Typography>
			<Box padding={2}/>

			<Autocomplete
				className={classes.autoComplete}
				onChange={(_event: any, newValue: {id: number, label: string} | null) => {
					setValue(newValue);
				}}
				disablePortal
				id="combo-box-demo"
				onInputChange={(_event, newInputValue) => {
					setCourseSearch(newInputValue);
				}}
				inputValue={courseSearch}
				options={courses?.data?.map(e => ({label: e.title, id: e.id})) ?? []}
				sx={{width: 300}}
				renderInput={(params) => <TextField {...params} label="Course" />}
			/>
			<Box padding={"10px"}></Box>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					className={classes.datePicker}
					label="Date Due"
					sx={{width: 300}}
					value={dueDate}
					onChange={(newValue) => newValue && handleDueDateChange(newValue)}
				/>
			</LocalizationProvider>
		</Box>
	);
}